import React from 'react';
import { cloneDeep} from 'lodash';

// components
import GenericConfirm from '../../dialogues/GenericConfirm';

// mui
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import BatterySaverIcon from '@mui/icons-material/BatterySaver';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';

// utils
import assignTestToGroup from '../../../utils/functions/assignTestToGroup';
import reSortGroupedTesting from '../../../utils/functions/reSortGroupedTesting';
import './testingForm.css';

const TestingSelected = ({ refItem, userInput, setUserInput, data, formMode }) => {

  const [openAlert, setOpenAlert] = React.useState(false)

  const handleRemove = (test) => {        
    const newInput = cloneDeep(userInput)
    const newSubItem = cloneDeep(refItem)     

    if (newInput.samples?.length > 0) {
      // remove from ref sample and set data here
      newSubItem.activeTests = newSubItem.activeTests.filter(t => t.LIMS_ID !== test.LIMS_ID);
      const index = newInput.samples.findIndex(s => s.id === refItem.id)                    
      newInput.samples.splice(index, 1, newSubItem)    

    } else {
      // materials orders
      // if you remove a parent, reset the children of that parent to consolidate
      if (test.consolidateTarget === 'parent') {

        // kill parent test
        newSubItem.activeTests = newSubItem.activeTests.filter(at => at.LIMS_ID !== test.LIMS_ID)

        // iterate through children and re-process each
        const childTests = newSubItem.activeTests?.filter(at => !at.individual).filter(at => at.consolidateTarget === test.LIMS_ID)
        
        // temporarily set testing without those children
        newSubItem.activeTests = newSubItem.activeTests.filter(at => !childTests.find(ct => ct.LIMS_ID === at.LIMS_ID))
        
        // push each child test into test array, resetting which parent they belong to
        childTests?.forEach((childTest, j) => {
          
          // figure out where this test will be grouped
          const processedTest = assignTestToGroup(childTest, newSubItem, data)

          // here we jam the test back in the same spot where the old parent was, keeps things relatively consistent on the UI
          const origalIndex = refItem.activeTests.findIndex(at => at.LIMS_ID === test.LIMS_ID)
          
          newSubItem.activeTests.splice(origalIndex + j, 0, processedTest)
        })

      } else {
        // simple remove children from array, no need to complicate further
        newSubItem.activeTests = newSubItem.activeTests.filter(at => at.LIMS_ID !== test.LIMS_ID)
      }
      
      newInput.materialOrders = [newSubItem]
    }

    setUserInput(newInput)
  
  }

  const handleMove = (test, dir) => {
    // get parent ID
    const workingTest = cloneDeep(test)
    const newSubItem = cloneDeep(refItem)
    const parent = workingTest.consolidateTarget === 'parent' ? workingTest : newSubItem.activeTests.find(at => at.LIMS_ID === workingTest.consolidateTarget)

    const parentIndex = newSubItem.activeTests.findIndex(at => at.LIMS_ID === parent.LIMS_ID)
    
    if (dir === 'down') {
      // slice off evertything above the current group
      newSubItem.activeTests = newSubItem.activeTests.slice(parentIndex + 1, newSubItem.activeTests.length)
      // filter for everythign that isnt in the group
      newSubItem.activeTests = newSubItem.activeTests.filter(at => at.consolidateTarget !== parent.LIMS_ID).filter(at => at.LIMS_ID !== parent.LIMS_ID)
    } else {
      if (parentIndex === 0) {
        return
      }
      newSubItem.activeTests = newSubItem.activeTests.slice(0, parentIndex).reverse()
      newSubItem.activeTests = newSubItem.activeTests.filter(at => at.consolidateTarget !== parent.LIMS_ID).filter(at => at.LIMS_ID !== parent.LIMS_ID)
    }

    const processedTest = assignTestToGroup(workingTest, newSubItem, data)
    const trueSubItem = cloneDeep(refItem)
    
    const index = trueSubItem.activeTests.findIndex(at => at.LIMS_ID === test.LIMS_ID)
    trueSubItem.activeTests.splice(index, 1, processedTest)

    // if we moved a parent, update its previous children
    if (test.consolidateTarget === 'parent') {
      // iterate through children and re-process each
      const childTests = trueSubItem.activeTests?.filter(at => !at.individual).filter(at => at.consolidateTarget === test.LIMS_ID)
              
      // push each child test into test array, resetting which parent they belong to
      childTests?.forEach((childTest, j) => {
        childTest.consolidateTarget = j === 0 ? 'parent' : childTests[0].LIMS_ID
      })
    }

    trueSubItem.activeTests = reSortGroupedTesting(trueSubItem.activeTests, data)
    const newInput = cloneDeep(userInput)
    newInput.materialOrders = [trueSubItem]
    setUserInput(newInput)

  }

  const getSpectraList = (test) => {
    const fullTest = data.testing.spectras.filter(t => t.LIMS_ID === test.LIMS_ID).map(t => {
      return t.SCOPE_NAME !== null ? t.SCOPE_NAME : t.ANALYTE
    }).sort();
    return [...new Set(fullTest)].join(' • ')
  }

  const individualTesting = () => {
    const uniques = refItem?.activeTests?.filter(at => at.individual);
    const modeled = []
    uniques?.forEach(at => {
      const findGroup = modeled.find(g => g.individualName === at.individualName)
      if (findGroup) {
        findGroup.activeTests.push(at)
      } else {
        modeled.push({
          individualName: at.individualName,
          activeTests: [at]
        })
      }
    })

    return modeled;
  }

  const handleRemoveGroup = (group) => {
            
    const newSubItem = cloneDeep(refItem) 

    // remove from ref sample and set data here
    newSubItem.activeTests = newSubItem.activeTests.filter(t => t.individualName !== group);      
    const newInput = cloneDeep(userInput)      
  
    if (newInput.samples) {
      const index = newInput.samples.findIndex(s => s.id === refItem.id)                    
      newInput.samples.splice(index, 1, newSubItem)    
    } else {
        // materials orders
        newInput.materialOrders = [newSubItem]
    }
  
    setUserInput(newInput)

  }

   
  const checkCanMoveDown = (test) => {
    
    // get index
    const index = refItem.activeTests.findIndex(at => at.LIMS_ID === test.LIMS_ID)
    if (index === refItem.activeTests.length - 1 && test.consolidateTarget === 'parent') {
      // don't break off it its a parent AND its the last item on the list
      return true
    }        

    // check if it'll have a different parent
    
    const altRefItem = cloneDeep(refItem)
    const parentIndex = refItem.activeTests.findIndex(at => at.LIMS_ID === (test.consolidateTarget === 'parent' ? test.LIMS_ID : test.consolidateTarget))
    altRefItem.activeTests = altRefItem.activeTests.slice(parentIndex + 1, altRefItem.activeTests.length)
    altRefItem.activeTests = altRefItem.activeTests.filter(at => at.consolidateTarget !== parent.LIMS_ID).filter(at => at.LIMS_ID !== parent.LIMS_ID)
    
    const processedTest = assignTestToGroup(test, altRefItem, data)
    
    if (test.consolidateTarget === processedTest.consolidateTarget) {    
      // if the test's parent wouldn't change then don't change
      return true
    }

    return false
  }

  const checkCanMoveUp = (test) => {
    
    // get index
    const index = refItem.activeTests.findIndex(at => at.LIMS_ID === test.LIMS_ID)
    if (index < 1) {
      // the top list parent will not be able to break off upwards
      return true
    }        

    const refList = refItem.activeTests.slice(0, index)
    const parentList = refList.filter(t => t.consolidateTarget === 'parent')

    if (parentList.length === 1 && parentList[0].LIMS_ID === test.consolidateTarget) {
      // if the test is a child of a parent in the top row it can't break off upwards
      return true
    }

    const processedTest = assignTestToGroup(test, refItem, data)

    if (test.consolidateTarget === processedTest.consolidateTarget) {    
      // if the test's parent wouldn't change then don't change
      return true
    }

    const targetIndex = refItem.activeTests.findIndex(t => t.LIMS_ID === processedTest.consolidateTarget)    
    if (index < targetIndex) {
      // dont push UP if the "target" is below in the array
      return true
    }

    return false
  }

  const primaryList = formMode !== 'samples' ? refItem.activeTests?.filter(at => !at.individual).filter(at => at.consolidateTarget === 'parent') : refItem.activeTests

  return (
    <Paper elevation={3} sx={{m: '15px', p: '15px', textAlign: 'left', backgroundColor: "rgba(0, 0, 0, 0)", boxShadow: '0px !important'}} >
      
      <GenericConfirm open={openAlert} setOpen={setOpenAlert} submitEntry={handleRemoveGroup} />
      {formMode === 'samples' ? 
      <Typography variant="h6">Selected Tests</Typography>
      : 
      <>
      <Typography variant="h5" className="formHeaderContainer">
        <BatterySaverIcon />
        Bottles Specifications
      </Typography>
      </>
      }
      
      

    <TableContainer component={Paper} className="selected-table-container">
    <Table sx={{ minWidth: "90%" }} size="small" aria-label="a dense table" className="selected-table">
      {formMode !== 'samples' ? 
        <TableHead>
          <TableRow className="testing-header">
            <TableCell align="center" style={{ fontWeight: 'bold', color: 'white' }}>Quantity</TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold', color: 'white' }}>Bottle(s)</TableCell>
            <TableCell></TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold', color: 'white' }}>Analysis</TableCell>
            <TableCell align="center" style={{ fontWeight: 'bold', color: 'white' }}></TableCell>
          </TableRow>
        </TableHead>
      : null}
      
      <TableBody>
      {primaryList?.filter(at => !at.individual).map((t, i) => {
          const test = data.testing[t.type + 's']?.find(tt => tt.LIMS_ID === t.LIMS_ID);
          const details = t.type === 'spectra' ? getSpectraList(test) : false;

          const bottlesList = data.testing[t.type + 's']?.filter(tt => tt.LIMS_ID === test.LIMS_ID).map(tt => tt.BOTTLE_ID).filter(Boolean)
          const bottlesUnique = [...new Set(bottlesList)]

          // for materials, we need to show the list of tests that are children to this
          const childTests = refItem.activeTests?.filter(at => !at.individual).filter(at => at.consolidateTarget === t.LIMS_ID)

          return (
            <TableRow
              key={"selected-test-"+t.LIMS_ID+'-'+i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className="testing-row"
            >
              {formMode !== 'samples' ? 
                <>
                <TableCell align="center">
                  <ul style={{listStyleType: 'none', padding: '0'}}>
                    {
                      bottlesUnique.map(bot => {
                        return <li key={bot}>{t?.count}</li>
                      })
                    }
                  </ul>                    
                </TableCell>
                  <TableCell align="center" style={{ width: '150px'}}>
                    <ul style={{listStyleType: 'none', padding: '0'}}>
                      {
                        bottlesUnique.map(bot => {
                          const bottle = data.references?.bottles?.find(b => b.BOTTLE_LIMS_ID === bot)
                          return <li key={bot}>{bottle.BOTTLE_NAME}</li>
                        })
                      }
                    </ul>
                  </TableCell>
                  <TableCell colSpan={4}>

                    <TableContainer>
                      <Table >
                        <TableRow>
                          <TableCell style={{width: '80px'}} align="center">{test?.PRODUCTCODE}</TableCell>
                          <TableCell style={{width: '180px'}} align="center">{details || test?.DISPLAYNAME}</TableCell>      
                                
                          <TableCell align="center">

                            <Stack direction="row" spacing={1}>
                              
                              <Tooltip title="Remove Test">
                                <IconButton aria-label="delete" color="primary" onClick={() => {handleRemove(t)}}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>

                              {!checkCanMoveUp(t) ? 
                                <Tooltip title="Adjust Containers">
                                  <IconButton aria-label="delete" onClick={() => {handleMove(t, 'up')}}>
                                    <VerticalAlignTopIcon />
                                  </IconButton>
                                </Tooltip>
                              : null }

                              {!checkCanMoveDown(t) ? 
                                <Tooltip title="Adjust Containers">
                                  <IconButton aria-label="delete" onClick={() => {handleMove(t, 'down')}}>
                                    <VerticalAlignBottomIcon />
                                  </IconButton>
                                </Tooltip>
                              : null }

                            </Stack>

                          </TableCell>
                        </TableRow>

                        {childTests.map(ct => {
                          const childTest = data.testing[ct.type + 's']?.find(tt => tt.LIMS_ID === ct.LIMS_ID);
                          const childDetails = ct.type === 'spectra' ? getSpectraList(childTest) : false;
                          return (
                            <TableRow key={'child-'+ct.LIMS_ID}>
                              <TableCell style={{width: '80px'}} align="center">{childTest?.PRODUCTCODE}</TableCell>
                              <TableCell style={{width: '180px'}} align="center">{childDetails || childTest?.DISPLAYNAME}</TableCell>
                              <TableCell align="center">

                                <Stack direction="row" spacing={1}>
                              
                                  <Tooltip title="Remove Test">
                                    <IconButton aria-label="delete" color="primary" onClick={() => {handleRemove(ct)}}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                  
                                {!checkCanMoveUp(ct) ? 
                                  <Tooltip title="Move test into row above.">
                                    <IconButton aria-label="delete" onClick={() => {handleMove(t, 'up')}}>
                                      <VerticalAlignTopIcon />
                                    </IconButton>
                                  </Tooltip>
                                : null}

                                {!checkCanMoveDown(ct) ? 
                                  <Tooltip title="Adjust Containers">
                                    <IconButton aria-label="delete" onClick={() => {handleMove(t, 'down')}}>
                                      <VerticalAlignBottomIcon />
                                    </IconButton>
                                  </Tooltip>
                                : null }

                                </Stack>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </Table>
                    </TableContainer>
                  </TableCell>
                </>
              : 
                <>
                  <TableCell align="center">{test?.PRODUCTCODE}</TableCell>
                  <TableCell align="center">{details || test?.DISPLAYNAME}</TableCell>      

                  <TableCell align="center">
                    {!data.activeSamplingEvent ?
                    
                      <Button
                        startIcon={<ClearIcon />}
                        onClick={() => {handleRemove(test)}}
                      >  
                        Remove
                      </Button>

                    : null}      
                  </TableCell>
                </>
              }     
            </TableRow>
          )
        })}

        {individualTesting().map(t => {

          return (
            <TableRow
              key={"selected-ind-"+t.LIMS_ID}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center">{t.individualName}</TableCell>
              <TableCell align="left">
              {t.activeTests.map((at, i) => {
                const test = data.testing[at.type + 's']?.find(tt => tt.LIMS_ID === at.LIMS_ID);
                // return <span key={at.LIMS_ID}>{test.DISPLAYNAME}{i === t.activeTests.length - 1 ? "" : ", "}</span>
                return <Chip key={at.LIMS_ID + i} variant="outlined" label={test.DISPLAYNAME} onDelete={() => handleRemove(test)} />
              })}
                
              </TableCell>                 
              <TableCell align="center">
                <Button
                  startIcon={<ClearIcon />}
                  onClick={() => {           
                    setOpenAlert({
                      header: 'Are you sure you want to remove this group?',
                      body: 'This entire list of individually selected tests will be removed from this sample. Continue?',
                      target: t.individualName
                    })                    
                  }}
                >  
                  Remove All {t.individualName}
                </Button>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </TableContainer>


    </Paper>
  );
}

export default TestingSelected;
