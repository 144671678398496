import * as React from 'react';

// mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

// utils
import "../../utils/styles/cocStyle.css"
import dateHandlers from '../../utils/functions/dateHandlers';

const ReceiptSignatures = ({ userInput, samplingMode }) => {
  const signatures = userInput.signatures?.filter(s => s.type === 'receive').sort(function(a,b){return new Date(a.dateTime) - new Date(b.dateTime)});
  const initialSig = signatures ? signatures[0] : false
  const additionalSignatures = signatures?.length > 1 ? signatures.slice(1, signatures.length) : false

  return (
    <TableContainer>
      {initialSig ?
      <Table>

        <TableHead>
          <TableRow>
              <td className="signatureCellHeader">{samplingMode ? 'Sampled' : 'Received' } By</td>            
              <td className="signatureCellHeader">Date</td>
              <td className="signatureCellHeader">Time</td>      
              {!samplingMode ? <td className="signatureCellHeader">Received Temp., °C</td> : null}
              {!samplingMode ? <td className="signatureCellHeader">Evidence of Cooling?</td> : null}
          </TableRow>
        </TableHead>

        <TableBody>    
          
          {initialSig ? 
            <TableRow>
              <td className="labUseCell">{initialSig.name}</td>
              <td className="labUseCell">{dateHandlers.standardDate(initialSig.dateTime)}</td>
              <td className="labUseCell">{dateHandlers.standardTime(initialSig.dateTime)}</td>
              {!samplingMode ? <td className="labUseCell">{initialSig.tempurature?.toString() === '0.00' || initialSig.tempurature === 0  || !initialSig.tempurature ? 'NA' : initialSig.tempurature}</td> : null}
              {!samplingMode ? <td className="labUseCell">{initialSig.cooling === '1' || initialSig.cooling === 'Yes' ? 'Yes' : 'No'}</td> : null}
            </TableRow>
          : null }
          
          {samplingMode && additionalSignatures? 
            <TableRow>
              <td className="signatureCellHeader">Received By</td>            
              <td className="signatureCellHeader">Date</td>
              <td className="signatureCellHeader">Time</td>      
              <td className="signatureCellHeader">Received Temp., °C</td>
              <td className="signatureCellHeader">Evidence of Cooling?</td>
            </TableRow>    
          : null }

          {additionalSignatures ? 
            additionalSignatures.map((s, i) => {
              return (
                <TableRow key={'sig-add-'+i}>
                  <td className="labUseCell">{s.name}</td>
                  <td className="labUseCell">{dateHandlers.standardDate(s.dateTime)}</td>
                  <td className="labUseCell">{dateHandlers.standardTime(s.dateTime)}</td>
                  <td className="labUseCell">{s.tempurature?.toString() === '0.00' || s.tempurature === 0  || !s.tempurature ? 'NA' : s.tempurature}</td>
                  <td className="labUseCell">{s.cooling === '1' || s.cooling === 'Yes' ? 'Yes' : 'No'}</td>
                </TableRow>
              )
            })
          : null}
        </TableBody>
        

      </Table>
       : null}
    </TableContainer>     
    
  );
}

export default ReceiptSignatures;
