import React from 'react';
import { cloneDeep } from 'lodash';
import validator from 'validator';

// components
import FormSelector from './FormSelector';
import UserSelect from './FormParameters/UserSelect';
import SubItems from './FormParameters/SubItems';
import Required from './FormParameters/Required';
import Dependency from './FormParameters/Dependency';
import MirrorKey from './FormParameters/MirrorKey';
import GenericTextSubmit from '../dialogues/GenericTextSubmit';
import GenericConfirm from '../dialogues/GenericConfirm';
import GenericSelect from '../dialogues/GenericSelect';
import GroupAdvancedSettings from './GroupAdvancedSettings';
import AdminStandard from './FormParameters/AdminStandard.js';
import VisibleOnTable from './VisibleOnTable.js';

// mui
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:'#EEEEEE',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  
// Need this to be in backend eventually, maybe
const validateOptions = [
    {type: 'isEmail', label: 'Email'},
    {type: 'isNumeric', label: 'Numeric'},
    {type: 'isMobilePhone', label: 'Phone Number'},
]

const FieldsEditor = ({ data, setData, saveData, currentForm, selected, setSelected, groupSel, setGroupSel }) => {

    const [newItem, setNewItem] = React.useState(false)
    const [deleteItem, setDeleteItem] = React.useState(false)
    const [moveItem, setMoveItem] = React.useState(false)

    const parentSubSection = currentForm?.sub_sections?.find(ss => ss?.inputs?.find(input => input.input_key === selected.input_key))

    const updateValue = (e, key, altVal, action) => {
        const newSelected = cloneDeep(selected)

        if (action === 'selectChange') {
            newSelected[key] = e.target.value
        } else if (key === "type" && e.target.value === "select") {
            newSelected[key] = e.target.value
            newSelected.options = []
        } else if (key === "type" && (e.target.value === "input" || e.target.value === "datePicker" || e.target.value === "timePicker")) {
            newSelected[key] = e.target.value
            delete newSelected.options
        } else if (action === 'delete') {
            delete newSelected[key]
        } else if (key === 'validate') {
            newSelected[key] = validateOptions.find(v => v.label === e.target.value) || false        
        } else if (key === 'adminStandard') {
            newSelected[key] = altVal    
        } else {
            newSelected[key] = altVal || e.target.value            
        }

        setSelected(newSelected)

    }

    const handleChangeDir = (dir) => {
        
        const newSelected = cloneDeep(selected)
        const newData = cloneDeep(data)
        const newForm = newData.forms.sections.find(s => {
            if (currentForm.samplingEventKey) {
              return s.samplingEventKey === currentForm.samplingEventKey
            } else {
              return s.type === currentForm.type
            }
          })
        const newParent = newForm.sub_sections.find(ss => ss.inputs.find(input => input.input_key === newSelected.input_key))
        
        const currentIndex = newParent.inputs.findIndex(item => item.input_key === newSelected.input_key)
        const desiredIndex = currentIndex + dir

        newParent.inputs = newParent.inputs.filter(item => item.input_key !== newSelected.input_key)
        newParent.inputs.splice(desiredIndex, 0, newSelected);
        

        newParent.inputs = newParent.inputs.map((input, i) => {
            input.order = i + 1
            return input
        })

        newForm.sub_sections = newForm.sub_sections.map(ss => {
            return ss.sectionKey === newParent.sectionKey ? newParent : ss
        })

        newData.forms.sections = newData.forms.sections.map(form => {
            if (newForm.samplingEventKey) {
                return form.samplingEventKey === newForm.samplingEventKey ? newForm : form
            } else {
                return form.type === newForm.type ? newForm : form
            }
        })

        setSelected(newSelected)
        setData(newData)

    }

    const handleSave = () => {
        if (selected.md < 1 || selected.md > 12) {selected.md = 12}
        if (selected.xs < 1 || selected.xs > 12) {selected.xs = 12}
        saveData(selected)
        setSelected("")
    }

    const detectChange = () => {
        const current = JSON.stringify(selected)
        const saved = JSON.stringify(parentSubSection?.inputs?.find(input => input.input_key === selected.input_key))
        return current === saved
    }

    const undoChanges = () => {
        setSelected(parentSubSection?.inputs?.find(input => input.input_key === selected.input_key))
    }

    const handleNewKey = () => {
        setNewItem({
            header: 'Set Key for New Form Item',
            body: 'A discreet key is required for form items and is how the value is mapped into LIMS.',
            helperText: <>This key must be in a specific format called &quot;camel case&quot;, e.g., camelCase, where a multi-word phrase is concatonated and each word AFTER the first word is capitolized and the entire string&apos;s first character is always lowercase. Below are more examples:
            <ul>
              <li>Client Name → clientName</li>
              <li>Client Name From Some Time In The Past → clientNameFromSomeTimeInThePast</li>
              <li>Something Simple → somethingSimple</li>
              <li>A Very Long String → aVeryLongString</li>
            </ul>
            When saving below the application will check for duplicate keys and alert you there. If there are any other issues please contact the application administrator for more assistance.</>
        })
    }

    const handleSaveNew = (value) => {        

        if (value.indexOf(' ') > -1) {
            return 'Spaces are not allowed in key name.'
        }

        if (!validator.isAlphanumeric(value)) {
            return 'No special characters: letters and numbers only.'
        }

        const clientForm = data.forms?.sections?.find(f => f.type === 'client')
        const sampleForm = data.forms?.sections?.find(f => f.type === 'sample')
        const concated = [...clientForm.sub_sections, ...sampleForm.sub_sections]
        const allKeys = concated.map(ss => {
            const keys = []
            ss.inputs.forEach(input => {
                keys.push(input.input_key)
            })
            return keys
        }).flat()
        
        if (allKeys.indexOf(value) > -1) {
            return 'Duplicate Key: please use a different name for this entry.'
        }
        const newData = cloneDeep(data)
        const newCurrentForm = newData.forms.sections.find(s => {
            if (currentForm.samplingEventKey) {
              return s.samplingEventKey === currentForm.samplingEventKey
            } else {
              return s.type === currentForm.type
            }
        })
        
        newCurrentForm.sub_sections.forEach(ss => {
            ss.inputs = ss.inputs.map(input => {
                if (input.input_key === selected.input_key) {      
                    input = cloneDeep(selected)              
                    input.input_key = value
                }
                return input
            })
        })

        setSelected("")
        setData(newData)

        return true
    }

    const handleDeleteItem = () => {
        setDeleteItem({
            header: 'Are you sure you want to delete this item?',
            body: 'Proceeding you will delete this item and any settings applied here will be lost. Continue?',
            helperText: 'You can undo this by clicking "Undo All Changes" button on this page\'s main header.'
        })
    }

    const handleSaveDeleteItem = () => {        

        const newData = cloneDeep(data)
        const newForm = cloneDeep(currentForm)
        const newParent = cloneDeep(parentSubSection)

        const currIndex = newParent.inputs.findIndex(ci => ci.input_key === selected.input_key)

        newParent.inputs.splice(currIndex, 1);

        newParent.inputs = newParent.inputs.map((input, i) => {
            input.order = i + 1
            return input
        })

        newForm.sub_sections = newForm.sub_sections.map(ss => {
            return ss.sectionKey === newParent.sectionKey ? newParent : ss
        })

        newData.forms.sections = newData.forms.sections.map(form => {
            if (newForm.samplingEventKey) {
                return form.samplingEventKey === newForm.samplingEventKey ? newForm : form
            } else {
                return form.type === newForm.type ? newForm : form
            }
        })


        setSelected(false)
        setData(newData)

        return true
    }

    const handleMoveGroup = () => {
        const options = currentForm.sub_sections.map(ss => ss.sectionKey)
        setMoveItem({
            header: 'Are you sure you want to move this item?',
            body: '',
            initOption: parentSubSection?.sectionKey,
            options: options,
            helperText: 'You can undo this by clicking "Undo All Changes" button on this page\'s main header.'
        })
    }

    const setMoveGroup = (val) => {
        const newData = cloneDeep(data)
        const newForm = cloneDeep(currentForm)
        const newParent = cloneDeep(parentSubSection)
        const targetParent = newForm.sub_sections.find(ss => ss.sectionKey === val)
        
        const currIndex = newParent.inputs.findIndex(ci => ci.input_key === selected.input_key)

        newParent.inputs.splice(currIndex, 1);

        newParent.inputs = newParent.inputs.map((input, i) => {
            input.order = i + 1
            return input
        })
        targetParent.inputs.push(selected)
        targetParent.inputs = targetParent.inputs.map((input, i) => {
            input.order = i + 1
            return input
        })

        newForm.sub_sections = newForm.sub_sections.map(ss => {
            return ss.sectionKey === newParent.sectionKey ? newParent : ss
        })

        newData.forms.sections = newData.forms.sections.map(form => {
            if (newForm.samplingEventKey) {
                return form.samplingEventKey === newForm.samplingEventKey ? newForm : form
            } else {
                return form.type === newForm.type ? newForm : form
            }
        })


        setSelected(false)
        setData(newData)
    }

    const samplingEvents = data.forms?.sections?.filter(s => s.type === 'samplingEvent')

    const updateSamplingEventVisibility = (value, samplingEventKey) => {
        const newSelected = cloneDeep(selected)
        const visible = newSelected.visibleOnSamplingEvents?.find(vos => vos.samplingEvent === samplingEventKey)
        if (!visible) {
            newSelected.visibleOnSamplingEvents = [...newSelected.visibleOnSamplingEvents, { samplingEvent: samplingEventKey, value: value }]
        } else {
            visible.value = value
        }
        setSelected(newSelected)
    }

    return (
        <div >

            {/* dialogs */}
            <GenericTextSubmit open={newItem} setOpen={setNewItem} submitEntry={handleSaveNew} />
            <GenericConfirm open={deleteItem} setOpen={setDeleteItem} submitEntry={handleSaveDeleteItem} />
            <GenericSelect open={moveItem} setOpen={setMoveItem} submitEntry={setMoveGroup} />

            {/* Editor */}
            <Grid container spacing={2}>
            {/* Selector */}

                <Grid item xs={4} >                    
                    <FormSelector data={data} setData={setData} setSelected={setSelected} selected={selected} currentForm={currentForm} groupSel={groupSel} setGroupSel={setGroupSel} />                
                </Grid>

                {groupSel ? 
                    <Grid item xs={8} >                    
                        <GroupAdvancedSettings data={data} setData={setData} setSelected={setSelected} selected={selected} currentForm={currentForm} groupSel={groupSel} setGroupSel={setGroupSel} />  
                    </Grid>                
                : null}

                {selected ? 
                    <Grid item xs={8}>
                        
                        <Box sx={{ width: '100%' }}>
                            <Stack spacing={2}>
                            
                            {/* Item */}
                            <Item>
                                <Grid container>
                                    <Grid item xs={12}>
                                    {selected.input_key !== "newInput" ? 
                                    <>                                
                                        <Typography variant="h5">Item Key: {selected.input_key}</Typography>
                                        <Typography variant="h5">Item Type: {selected.type}</Typography>
                                    </>
                                    : 
                                    <>  
                                        <Typography variant="h5">New Item Options</Typography>
                                        <br />
                                        <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Form Item Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            style={{width: "100%"}}
                                            id="demo-simple-select"
                                            value={selected.type || 'input'}
                                            label="Form Item Type"
                                            onChange={(e) => {
                                                updateValue(e, "type");
                                            }}
                                        >
                                        <MenuItem value={'input'}>Text Input</MenuItem>
                                            <MenuItem value={'textArea'}>Text Area</MenuItem>
                                            <MenuItem value={'select'}>Select / List</MenuItem>
                                            <MenuItem value={'checkBoxes'}>Checkboxes</MenuItem>
                                            <MenuItem value={'radioGroup'}>Radio Group</MenuItem>
                                            <MenuItem value={'datePicker'}>Date Input</MenuItem>
                                            <MenuItem value={'timePicker'}>Time Input</MenuItem>
                                            
                                            
                                        </Select>
                                        <FormHelperText id="component-helper-text">
                                            For now, only Input and Select (list) is available here.
                                        </FormHelperText>
                                        </FormControl>
                                        <br />
                                    </>
                                    }
                                    <ButtonGroup color="success" >
                                        <Button disabled={selected.order <= 1} onClick={() => handleChangeDir(-1)}>Move Up</Button>
                                        <Button disabled={parentSubSection?.inputs?.length <= selected.order} onClick={() => handleChangeDir(1)}>Move Down</Button>
                                        <Button variant="contained" onClick={handleDeleteItem} >Delete Item</Button>
                                        <Button disabled={detectChange()} variant="contained" onClick={undoChanges} >Undo Changes</Button>
                                        <Button disabled={selected.input_key === "newInput" ? false : detectChange()} variant="contained" onClick={selected.input_key === "newInput" ? handleNewKey : handleSave} endIcon={<SaveIcon />}>Save Work</Button>                                    
                                    </ButtonGroup>
                                    < br />
                                    < br />
                                    <ButtonGroup color="success" >
                                        <Button disabled={selected.input_key === "newInput"} variant="contained" onClick={handleMoveGroup} color="success">Move To Different Group</Button>
                                        <Button disabled={selected.input_key === "newInput"} variant="contained" onClick={() => setSelected(false)} color="error">Close Without Saving</Button>
                                    </ButtonGroup>
                                    
                                    </Grid>
                                    {currentForm.type !== 'samplingEvent' && currentForm.type !== 'materialOrder' ? 
                                        <Grid xs={12}>
                                            <VisibleOnTable selected={selected} samplingEvents={samplingEvents} updateValue={updateValue} updateSamplingEventVisibility={updateSamplingEventVisibility} />
                                        </Grid>
                                    : null}

                                </Grid>
                            </Item>
                            
                            <Typography variant="h5">REQUIRED FIELDS</Typography>
                            {/* Label */}
                            <Item>           
                                <Typography variant="h5" style={{float: "Left"}}>Form Item Label</Typography>
                                <FormControl variant="standard">
                                <Input 
                                    value={selected.label || false}
                                    onChange={(e) => {
                                        updateValue(e, "label");
                                    }}
                                    style={{width: "100%"}}
                                />
                                        <FormHelperText id="component-helper-text">
                                            Input Label is the label of the form item, e.g., &quot;Company Name&quot; or &quot;Contact Phone&quot;. A simple description of what that form entry is asking for.
                                        </FormHelperText>
                                </FormControl>
                            </Item>
                            
                            {/* SELECT TYPE - For SELECTS only */}
                            {selected.type === 'select' || selected.type === 'selectAutoComplete' ?                                                        
                                <Item>
                                    <Typography variant="h5"  style={{float: "Left"}}>Select Type</Typography>
                                    <Typography variant="p"  style={{float: "Left"}}>This means what sort of drop-down this will be. A normal &quot;select&quot; will be a drop down, simple. Autocomplete allows users to type and filter and select which is ideal for long lists like &quot;matrix&quot;.</Typography>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="select"
                                            name="radio-buttons-group"
                                            value={selected.type}
                                            onChange={(e) => {
                                                updateValue(e, "type", false, 'selectChange');
                                            }}
                                        >
                                            <FormControlLabel value="select" control={<Radio />} label="Standard Select" />
                                            <FormControlLabel value="selectAutoComplete" control={<Radio />} label="Auto-Complete Select" />
                                        </RadioGroup>
                                    </FormControl>
                                </Item>                                    
                            : null}
                            {/* SELECT TYPE - For SELECTS only */}
                            {selected.type === 'select' || selected.type === 'selectAutoComplete' ?                                                        
                                <Item>
                                    <Typography variant="h5"  style={{float: "Left"}}>Multiple Select?</Typography>
                                    <Typography variant="p"  style={{float: "Left"}}>Will you allow users to make multiple selections on this?</Typography>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue={false}
                                            name="radio-buttons-group"
                                            value={selected.isMultiple}
                                            onChange={(e) => {
                                                updateValue(e, "isMultiple");
                                            }}
                                        >
                                            <FormControlLabel value={false} control={<Radio />} label="Single Select (default)" />
                                            <FormControlLabel value={true} control={<Radio />} label="Multiple Select" />
                                        </RadioGroup>
                                    </FormControl>
                                </Item>                                    
                            : null}
                            {/* Options - For SELECTS only, disable on obj */}
                            {selected.type === 'select' || selected.type === 'selectAutoComplete' || selected.type === 'radioGroup' || selected.type === 'checkBoxes' ?                                                        
                                <Item>
                                    <UserSelect selected={selected} currentForm={currentForm} data={data} setData={setData} updateValue={updateValue} handleSave={handleSave} />
                                </Item>                                    
                            : null}
                            
                            {/* medium screen view val */}
                            <Item>
                                <Typography variant="h5"  style={{float: "Left"}}>Item Width on PC Display</Typography>
                                <FormControl variant="standard">
                                <Input 
                                    value={selected.md}
                                    onChange={(e) => {
                                        updateValue(e, "md");
                                    }}
                                    style={{width: "100%"}}
                                />
                                        <FormHelperText id="component-helper-text">
                                            What proportion of the item&apos;s row this item should consume written in terms of fractions of 12. For example, to take the full length, set this to 12. To take half, set it to 6, and so on. This value is specifically setting for the form&apos;s view on non-mobile displays (PC, tablets, laptops, etc)
                                        </FormHelperText>
                                </FormControl>
                            </Item>

                            {/* mobile screen view val */}
                            <Item>    
                                <Typography variant="h5"  style={{float: "Left"}}>Item Width on Mobile Display</Typography>
                                <FormControl variant="standard">
                                <Input 
                                    value={selected.xs}
                                    onChange={(e) => {
                                        updateValue(e, "xs");
                                    }}
                                    style={{width: "100%"}}
                                />
                                        <FormHelperText id="component-helper-text">
                                        What proportion of the item&apos;s row this item should consume written in terms of fractions of 12. For example, to take the full length, set this to 12. To take half, set it to 6, and so on. This value is specifically setting for the form&apos;s view on mobile displays (iphone, android, et al)
                                        </FormHelperText>
                                </FormControl>
                            </Item>


                            <Typography variant="h5">OPTIONAL FIELDS</Typography>

                            {/* Required Entry */}
                            <Item>
                                <Required selected={selected} data={data} setData={setData} updateValue={updateValue} handleSave={handleSave} currentForm={currentForm} />
                            </Item>
                             {selected.type === 'datePicker' ? 
                                <Item>
                                    <Typography variant="h5"  style={{float: "Left"}}>Disable Future Dates/Times</Typography>
                                    <br /><br />
                                    <FormControlLabel
                                        label="Disallow users to select future dates or times?"
                                        control={
                                            <Checkbox
                                                checked={selected.disableFuture}
                                                onChange={() => {
                                                    if (selected.disableFuture) {
                                                        updateValue(false, 'disableFuture', false, 'delete')
                                                    } else {
                                                        updateValue(false, 'disableFuture', true)            
                                                    }
                                                }}
                                            />
                                        }
                                    />
                                </Item>
                            : null}
                            {/* Admin Standard -- If this is going to be viewed on all receipt forms */}
                            {parentSubSection?.sectionKey === 'adminReceive' ?
                                <Item>
                                    <AdminStandard selected={selected} updateValue={updateValue} />
                                </Item>                            
                            : null}

                            {/* Validate */}
                            {selected.type === 'input' ? 
                                <Item>
                                    <Typography variant="h5"  style={{float: "Left"}}>Validation</Typography>
                                    <br /><br />
                                    <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Validate Entry</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        style={{width: "100%"}}
                                        id="demo-simple-select"
                                        defaultValue={false}
                                        value={selected.validation?.label || false}
                                        label="Validate Entry"
                                        onChange={(e) => {
                                            updateValue(e, "validation");
                                        }}
                                    >
                                        <MenuItem value={false}>None</MenuItem>
                                        <MenuItem value={'Email'}>Email</MenuItem>
                                        <MenuItem value={'Number'}>Numeric</MenuItem>
                                        <MenuItem value={'Phone Number'}>Phone Number</MenuItem>
                                    </Select>
                                    <FormHelperText id="component-helper-text">
                                        Validation is the act of checking an entry to ensure it matches with a specific format. For example, Email selected will only allow entries that match the general email structure to be submitted, such as &quot;example@domain.com&quot;.
                                    </FormHelperText>
                                    </FormControl>

                                </Item>                       
                            : null}
                            {/* SubItems */}      
                            {selected.type === 'input' ?                 
                                <Item>
                                    <SubItems selected={selected} data={data} setData={setData} updateValue={updateValue} handleSave={handleSave} currentForm={currentForm} />
                                </Item>
                            : null}

                            {/* Mirror Key */}
                            <Item>
                                <MirrorKey selected={selected} data={data} setData={setData} updateValue={updateValue} handleSave={handleSave} currentForm={currentForm} />
                            </Item>

                
                            {/* Dependency - */}
                            <Item>
                                <Dependency selected={selected} data={data} setData={setData} updateValue={updateValue} handleSave={handleSave} currentForm={currentForm} />
                            </Item>

                            <Item>
                                <p>Developer Notes:</p>
                                <p>For new keys, ensure the key is submitted to LIMS IT for mapping for importing purposes IF the key is required information for LIMS Lab Order</p>                                   

                            </Item>

                                            

                        {/* TODOs */}        

                            </Stack>
                        </Box>
                        
                    </Grid>
                : null}


            </Grid>        
        
        </div>
    );

}

export default FieldsEditor;
