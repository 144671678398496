import * as React from 'react';
import ReactQuill from 'react-quill';
import cloneDeep from 'lodash/cloneDeep';

// mui
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Divider from '@mui/material/Divider';
import { LinearProgress, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

// utils
import FormAPI from '../../utils/API/api-form';

import 'react-quill/dist/quill.snow.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
}));

const AddendumSubmissionComments = ({ userData, setUserData }) => {

    const [value, setValue] = React.useState('');
    const [name, setName] = React.useState('');
    const [laboratoryOrder, setLaboratoryOrder] = React.useState('');
    const [success, setSuccess] = React.useState(false)
    const [saving, setSaving] = React.useState(false)
    const [error, setError] = React.useState(false)

    const handleSave = async () => {
      
      setSaving(true)
      setError(false)
      setSuccess(false)

      try {
        const submission = {
          clientID: userData.submissionID,
          text: value,
          dateTime: new Date(),
          user: name,
          limsLaboratoryOrder: laboratoryOrder === '' ? null : laboratoryOrder
        }
        
        const newUserData = cloneDeep(userData)
        if (!newUserData.comments) {
          newUserData.comments = []
        }
        newUserData.comments = [...newUserData.comments, submission]

        const res = await FormAPI.saveClientSubmission({
          data: {
            userJSON: newUserData
          }
        })

        if (res.status === 200) {
          // do something
          setUserData(res.data)
          setSaving(false)
          setValue('')
          setLaboratoryOrder('')
          setName('')
          setSuccess(true)
        } else {
          setSaving(false)
          setError('Could not save comment. Try again. Try refreshing the page, then contact IT, if the issue persists!')
        }
       
        
      } catch (err) {
        console.log(err)
        setSaving(false)
        setError('Could not save comment!')
      }

      
      setSaving(false)
      
    }

    return (
      <div style={{ backgroundColor: 'lightGrey', padding: '10px', borderRadius: '5px'}}>
        <div style={{ width: '100%', position: 'relative' }}>        
          <Typography align="center" variant='h6' style={{width: '100%'}}>Chain of Custody Comments</Typography>
          <Typography align="center" variant='p'>Comments will appear on the COC below signatures, visible to clients.</Typography>
        </div>

        <Divider variant="middle" style={{ margin: '15px' }} />

        {userData.imported ? 
          <Typography align="center" variant='p'>Comments added AFTER import MUST include Laboratory Order Number and COC MUST BE RE-ATTACHED to DMS Image Preview!</Typography>
        : null }

        <div style={{marginTop: '15px'}}>

          {userData.signatures?.length > 0 ?
              <Stack spacing={1}>

                <Item>
                  <ReactQuill modules={
                    {
                      toolbar: [['bold', 'italic', 'underline']]
                    }
                  } className='ql-editor' theme="snow" value={value} onChange={setValue} disabled={true} />
                </Item>
                <Item>

                  {userData.imported ? 
                    <FormControl style={{ width: '100%'}}>
                      <InputLabel htmlFor="component-outlined">Laboratory Order</InputLabel>
                      <OutlinedInput
                        fullWidth
                        value={laboratoryOrder}  
                        onChange={(e) => setLaboratoryOrder(e.target.value)}
                      />
                    </FormControl>
                  : null }
                  
                <FormControl style={{ width: '100%'}}>
                  <InputLabel htmlFor="component-outlined">Name</InputLabel>
                  <OutlinedInput
                    fullWidth
                    value={name}  
                    onChange={(e) => setName(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleSave}
                          color='success'
                          edge="end"
                          disabled={name === '' || value === '' || saving || (userData.imported ? laboratoryOrder === '' : false)}
                        >
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  </FormControl>
                </Item>
                <Item>
                  {error ? <span style={{color: 'red'}}>Something went wrong saving, try again, else contact devs!</span> : null}
                  {saving ? <LinearProgress /> : false}
                  {success && !saving ? 
                    <Typography>Successfully Saved Comment!</Typography>
                  : null}
                </Item>

              </Stack>
            : 
              <ul>

                {userData.signatures?.length < 1 ? <li style={{color: 'red'}}>No comments before client submission.</li> : null }
              
              </ul>
            }
        </div>
      </div>
  );
}

export default AddendumSubmissionComments;
