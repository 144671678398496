import React from 'react';

// components
import RelinquishmentSignatures from './RelinquishmentSignatures';
import ReceiptSignatures from './ReceiptSignatures';

// mui
import Grid from '@mui/material/Grid';

// utils

const SignaturesBody = ({ data, userInput, setUserData, setMode, setGlobalLoading, samplingMode }) => {

  return (
    <Grid style={{ marginTop: "25px", width: "100%" }} container spacing={0}>
        <Grid xs={5}>
            <RelinquishmentSignatures userInput={userInput} setUserData={setUserData} samplingMode={samplingMode} />
        </Grid>

        <Grid xs={7} style={{paddingLeft: '5px'}}>
            <ReceiptSignatures userInput={userInput} samplingMode={samplingMode} /> 
        </Grid>
    </Grid>
    
  );
}

export default SignaturesBody;
