import * as React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

// utils
import FormAPI from '../../utils/API/api-form';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SaveSubmission = ({ data, open, setOpen, userInput, setUserData, formMode, mirrored, mode, samplingMode }) => {

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const finalSubmit = async () => {

    setLoading(true)

    try {
        const savingData = cloneDeep(userInput)
        savingData.labCode = 'cfl'
        savingData.status = formMode === 'form' ? "saved-form" : "saved"
        savingData.formMode = formMode || 'review'

        // below, only works for client-level "use as above"     
        if (mirrored) {
          const clientData = data.forms.sections.find(f => f.type === 'client')  
          Object.entries(mirrored).forEach(([key]) => {            
            const mirroredSection = clientData.sub_sections.find(ss => ss.sectionKey === key)
            mirroredSection?.inputs?.forEach(input => {
              if (input.mirrorKey && savingData[input.mirrorKey] && mirrored[key]) {             
                savingData[input.input_key] = savingData[input.mirrorKey]
              }
            })
          })
        }

        let prefix = mode === 'materials' ? 'SCR' : false
        if (samplingMode) {
          const samplingForm = data.activeSamplingEvent || {}
          prefix =samplingForm.submissionPrefix || prefix
        }

        const res = await FormAPI.saveClientSubmission({data: {
            userJSON: savingData, prefix: prefix
        }})

        if (res.status === 200) {
            const currentURL = window.location.href
            const urlArr = currentURL.split('/')        

            setError(false)

            // on Canvas if the URL changes, which it will on any save, it'll reset the samples prop on userdata. Pushing "tempalte" prevents this. We only want this key on here when it hits from form
            if (formMode === 'form') {
              setUserData({...res.data, template: true})
            } else {
              setUserData(res.data)
            }
            
            window.history.pushState('page2', 'Title', `/${urlArr[3]}/${res.data.submissionID}/`)
            setLoading(false)
            setOpen(false)
            // send email

            await FormAPI.submitClientDataEmail({ data: res.data, type: mode })

        } else {
            setLoading(false)
            setError("saveErr")
        }

    } catch (err) {
        console.log(err)
        setLoading(false)
        setError("saveErr")
    }

    
}

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        style={{maxWidth: "100vw"}}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Save Your Progress?"}</DialogTitle>
        
          {loading ? 
            <LinearProgress />
          : 
            <>
              {error ? 
                <Alert severity="error" style={{ margin: "15px" }}>There was an error saving your request. Please click below to try again. If the error persists please contact the laboratory right away for further assistance. We apologize for the inconvenience.</Alert>
              : 
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Once saved you will receive an email containing a link back to this page to continue with your submission.
                  </DialogContentText>
                  <Alert severity="error" style={{ margin: "15px" }}>Your work will be saved for further revisions but <b>not submitted</b>!</Alert>
                  {!userInput.companyName || !userInput.clientEmail ? 
                  <Alert severity="error" style={{ margin: "15px" }}>A <b>company name and contact email</b> must be specified on the form before saving. Thank you!</Alert>
                  : null}
                </DialogContent>
              }
            </>
          
          }         
       
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
            {error ? 
              <Button onClick={finalSubmit}>Try Again</Button>
            :
              <Button onClick={finalSubmit} disabled={!userInput.companyName || !userInput.clientEmail}>Save</Button>
            }
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default SaveSubmission;
