import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import cloneDeep from 'lodash/cloneDeep';

// components
import InputObjRender from './InputObjRender';

// mui
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// styles
import '../../utils/styles/styleSheet.css';
import headerIcons from './headerIcons';
import samplingEventTools from '../../utils/functions/samplingEventMode';

const ClientDataForm = ({ data, userInput, setUserInput, setPage, checkRequirements, disabled, setDisabled, mode, samplingMode }) => {

    const clientData = data.forms.sections.find(f => f.type === 'client');

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1
    });
    
    useEffect(() => {
        if (inView) {
            setPage(0)
        }

    }, [inView])

    const handleInput = (e, cat, alt) => {
        
        const newInput = cloneDeep(userInput);

        if (alt) {
            newInput[cat] = alt
        } else if (Array.isArray(e)) {
            newInput[cat] = e
        } else {
            newInput[cat] = e.target.value
        }

        setUserInput(newInput)

    }
    
    const toggleDisabled = (key) => {
        const newDisabled = cloneDeep(disabled)
        newDisabled[key] = !newDisabled[key]

        const newInput = cloneDeep(userInput);
        
        const mirroredSection = clientData.sub_sections?.find(ss => ss.sectionKey === key)
        mirroredSection.inputs?.forEach(inputs => {
            if (newDisabled[key]) {
                // fill with mirrored values
                newInput[inputs.input_key] = newInput[inputs.mirrorKey] ? newInput[inputs.mirrorKey] : ''
            } else {
                delete newInput[inputs.input_key]
            }
        })

        
        setUserInput(newInput)
        setDisabled(newDisabled)        
    }
    
    // remove subsections if ALL of the items are "hide" on the current view
    // parse here instead of line 82
    const useSections = clientData.sub_sections.map(ss => {
        const showCheck = ss.inputs.map(item => item.viewOnMaterials)
        if (mode !== 'samples') {
            return showCheck.indexOf('show') > -1 || showCheck.indexOf('only') > -1 ? ss : false
        } 
        return ss
    }).filter(ss => ss)

    return (
    <Paper elevation={2} className="formCanvas" ref={ref} >

    <Grid container spacing={1} >                

    {/* Current Possible Failure here: Should probably have definable sorting */}

    {Object.entries(useSections)
        .map(([key, value], i) => {
            if (key) {
                //ugh
                // why is this here, someone figure it out!
                // oh it was because of lint, haha
            }
            let render = true

            // checks requirements. All requirements must be met.
            if (value.requirements) {      
                value.requirements.forEach(v => {
                    render = userInput[v.key] === v.value                    
                })                          
                
            }

            // header icon
            const useIcon = headerIcons.find(i => i.key === value.icon) 
            
            const filteredInputs = samplingEventTools.filteredInputs(value.inputs, data.activeSamplingEvent || false, mode)

            return (
                <React.Fragment key={value.sectionKey + i}>
                
                    {filteredInputs.length > 0 ? 
                        <>

                            {value.header && render ?
                            <>
                            <Grid item xs={12} md={12} className="formHeaderContainer">{useIcon ? useIcon.component : null}<Typography component='h5' variant='h5'>{value.header}</Typography></Grid>
                            
                            {/* Mirrorable? */}
                            {value.useAsAbove ? 
                                <Grid item xs={12} md={12} style={{textAlign: 'left'}}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox onChange={() => toggleDisabled(value.sectionKey)} checked={!!disabled[value.sectionKey]} />} label="Use Same Details as Above" />            
                                    </FormGroup>
                                </Grid>
                            : null}
                        
                            <InputObjRender 
                                data={data}
                                checkRequirements={checkRequirements} 
                                sourceDataObj={value} 
                                objKey={value.sectionKey} 
                                userInput={userInput} 
                                disabled={disabled} 
                                handleInput={handleInput} 
                                setUserInput={setUserInput}
                                mode={mode}
                                filteredInputs={filteredInputs}
                            />        
                            
                            </>

                            : null}

                        </>                    
                    : null}

                </React.Fragment>
            )
        })
    }       
        
    </Grid>

        
        
        
        
        </Paper>
    );
}

export default ClientDataForm;
