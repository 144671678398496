import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';

// components
import FieldsEditor from './FieldsEditor';
import WarnAction from '../dialogues/WarnAction';
import TestEditor from './FormTestEditor/TestEditor.js';
import GenericSelect from '../dialogues/GenericSelect';
import MaterialOrdersFullfillmentForm from './MaterialOrdersFullfillmentForm.js';
import SamplingEventFullfillmentForm from './SamplingEventFullfillmentForm.js';

// mui
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { Alert, Divider, Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Link from "@mui/material/Link";

// utils
import FormAPI from '../../utils/API/api-form';
import TopClientEditor from './TopClientEditor.js';

// refs
const commitMessage = "Ready to commit changes? Clicking YES below will save your version as the LIVE version of the form for clients. The previous version will be saved and can be retrieved later and reinstated if necessary."

// todos
// need to pull old versions based on new db
// need to allow flopping between sections without losing that data

const FormManager = () => {

    const [loading, setLoading] = React.useState(false)
    const [permission] = React.useState(true)
    const [menu, setMenu] = React.useState(false)
    const [data, setData] = React.useState(false)
    const [dataNew, setDataNew] = React.useState(false)
    const [subForm, setSubForm] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [commit, setCommit] = React.useState(false)
    const [selected, setSelected] = React.useState(false)
    const [groupSel, setGroupSel] = React.useState(false)
    const [history] = React.useState(false)
    const [historyOpen, setHistoryOpen] = React.useState(false)
    
    const currentForm = dataNew.forms?.sections?.find(f => f.type === subForm)

    useEffect(() => {
        if (permission) {
            try {
                FormAPI.getServiceLines({
                    labCode: "cfl"
                })
                .then(data => {
                    setMenu(data.data)
                })
                .catch((err) => {
                    console.log(err)
                    setError("Couldn't verify password. Database or back-end failure. Refresh and try again. Contact development team if it persists.")
                }) 
            } catch (err) {
                console.log(err)                
            }
        }
    }, [permission])

    const loadData = async (serviceLine) => {
        setData(false)
        setDataNew(false)
        setSubForm(false)
        setSelected(false)
        setGroupSel(false)

        try {  
            setLoading("Loading " + serviceLine + "...")
            const resForm = await FormAPI.getForms({labCode: 'cfl', serviceLine: serviceLine})
            const testingNew = await FormAPI.ecocTesting({labCode: '1301'})
            const referencesNew = await FormAPI.ecocReferences({labCode: '1301'})

            if (resForm.status === 200 && testingNew.status === 200 && referencesNew.status === 200) {
                setData({
                    forms: resForm.data,
                    testingNew: testingNew.data,
                    references: referencesNew.data,
                    serviceLine: serviceLine,
                    type: 'formData'
                })
                
                setDataNew({
                    forms: resForm.data,
                    testingNew: testingNew.data,
                    references: referencesNew.data,
                    serviceLine: serviceLine,
                    type: 'formData'
                })
                
                setLoading(false)
                setError('')
                setSubForm('client')

            } else {
                setError("failed to load data")
                setLoading(false)
            }
            
        } catch (err) {
            console.log(err)
            setError("Failed to load form data. If you got this far it must be a bug in the data or the database. Try again and/or contact development team.")
            setLoading(false)
        }
    }

    const saveData = (selection) => {
        // this only saves the UI version, not the actual form which is live to the site

        const clone = cloneDeep(selection)

        const newData = cloneDeep(dataNew)
        const newCurrentForm = newData.forms.sections.find(s => s.type === currentForm.type)
        newCurrentForm.sub_sections.forEach(ss => {
            ss.inputs = ss.inputs.map(input => {
                return input.input_key === clone.input_key ? clone : input
            })
        })
        setDataNew(newData)

    }

    const commitData = async () => {
        // OK this sends the current to the backend, the previous still exists. 
        // both this and the client site load the most recent iteration for a given labCode + serviceLine.
        try {          
            setLoading("Saving Form...")
            const saveResponse = await FormAPI.putFormUpdates({
                labCode: "cfl",
                serviceLine: dataNew.serviceLine,
                data: dataNew.forms
            })

            setLoading(false)
            if (saveResponse.status === 200) {
                const updatedData = cloneDeep(data)
                updatedData.forms = saveResponse.data
                setData(updatedData)
                setDataNew(updatedData)
                setLoading(false)
            } else {
                // this means error... SAD!
                setLoading(false)
                setError("failed to archive")
            }


        } catch (err) {
            // this means error... SAD!
            // in truth, this is probably "couldnt reach API" error, versus "API failed to do the thing"
            console.log(err)
            setLoading(false)
            setError("failed to archive")
        }
        
    }

    const detectChange = () => {
        const oldDataString = JSON.stringify(data?.forms)
        const newDataString = JSON.stringify(dataNew?.forms)
        return oldDataString === newDataString
    }

    const undoAllChanges = () => {
        setDataNew(data)
    }

    const toggleSubForm = (val) => {
        
        const action = () => {
            setSubForm(val)
            setSelected(false)
            undoAllChanges()
            setGroupSel(false)
        }

        if (detectChange()) {
            action()
        } else {
            setCommit({
                message: "Warning: If you change what Form you are editing you will lose all work. Are you sure you want to continue?. If you wish to save, Cancel, then click Commit Changes.",
                action: action
            })
        }
        
    }

    const handleGetHistory = async () => {
        // totally redo this if you do it
        // setLoading(true)
        // setError(false)

        // try {
            // only need these items here, only gets back formIDs and createdAts and basics like that
        //     const response = await FormAPI.getFormsHistory({
        //         labCode: 'cfl',
        //         serviceLine: data.serviceLine
        //     })
            
        //     setHistory(response.data)
        //     setLoading(false)
        //     setHistoryOpen(true)

        //     const popUpOptions = response.data.map(h => `${h.submissionID} (${h.created})`)
        //     setHistoryOpen({
        //       header: 'Version Selection',
        //       body: 'Please select an item to view.',
        //       helperText: 'Click CANCEL to go back. Your selection will be further editable. You will only be viewing this item, to save, use Version Save options.',
        //       options: popUpOptions,
        //       initOption: popUpOptions[0]
        //     })

        // } catch (err) {
        //     setError("Failed to load history")
        //     console.log(err)
        //     setLoading(false)
        // }
    }
    
    const historySelection = async () => {
        // same here, redo if necessary
        // var fileName = (val.split(" "))[0]
        // setLoading(true)
        // setError(false)

        // try {
            // get formID from the list provided by the history query
        //     const response = await FormAPI.getForms({
        //     formID: formID
        //     })

        //     const newVersion = cloneDeep(dataNew)
        //     const formID = (fileName.split("_"))[0]
        //     if (data.serviceLine === 'testing') {
        //         newVersion.testing[formID] = response.data
        //     } else {
        //         newVersion[formID] = response.data
        //     }
            
        //     setDataNew(newVersion)
        //     setLoading(false)


        // } catch (err) {
        //     setError("Failed to load history")
        //     console.log(err)
        //     setLoading(false)
        // }
    }

    return (
        <div style={{marginBottom: '100px'}}>    

        {error ? <p>{error}</p> : null}
        {commit ? <WarnAction open={commit} setOpen={setCommit} /> : null}
        {history ? <GenericSelect open={historyOpen} setOpen={setHistoryOpen} submitEntry={historySelection} /> : null}

        <Typography variant="h3">Form Manager Header</Typography>
        {loading ?
            <p>{loading}</p>
        : 
        <div>                    
        <div style={{width: '400px', float: 'right', marginRight: '200px'}}>
            <Button
                variant="contained"
                color="success"
                onClick={async () => {
                    setError(false)
                    setLoading("Running LIMS queries...")
                try {
                    const response = await FormAPI.LIMSParamMet({labCode: '1301'})
                    setLoading(false)
                } catch (err) {
                    setLoading(false) 
                    setError('Could not query LIMS. Check with developers on this one')
                }
                
                }}
            >
                Run LIMS DB Query Now.
            </Button>
            
            <Alert>Only use this after updating LIMS and you need that data in ECOC immediately. Otherwise it will auto-query at midnight.</Alert>
        </div>      
            {menu ? 
                <Paper elevation={3} style={{width: "95%", margin: "auto", textAlign: "left", padding: "15px", backgroundColor: "#5cbbe7"}}> 
                    <Typography variant="h5">Form Manager Menu</Typography>
                    
                    <div>
                        <Typography variant="h6" style={{float: "left", marginRight: "15px"}}>Service Lines</Typography>
                        <ButtonGroup size="small" aria-label="small button group">
                            {menu.map((item, i) => {
                                return <Button disabled={!detectChange()} key={"menu-item" + i} onClick={() => loadData(item.serviceLine)} style={{backgroundColor: data?.serviceLine === item.serviceLine ? "#00FFAA" : "#EEEEEE"}}>{item.serviceLine}</Button>;
                            })}
                        </ButtonGroup>  
                        <p style={{fontWeight: 'bold'}}>Commit or undo changes to change service lines</p>
                        {menu.indexOf(data.serviceLine) > -1 ? 
                            <Button 
                                style={{float: "right"}} 
                                color="success"
                                variant='contained'           
                                component={Link}
                                href={`${window.location.href
                                .replace("admin/", "")
                                .replace("admin", "")}${data.serviceLine}/`}
                                target="_blank"
                            >
                                Open Service Line Page in New Tab
                            </Button>
                        : null}
                        
                    </div>
                        <br />
                    <div>
                        <Typography variant="h6" style={{float: "left", marginRight: "15px"}}>Other Items</Typography>
                        Coming Soon: Edit Sampling Event Data, Email, Service Line Settings
                    </div>
                        
                    <br />
                    
                    {dataNew.serviceLine ? 
                    <>
                    <Divider />
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <div>
                            <Typography variant="h6" style={{marginRight: "15px"}}>Form Selection</Typography>
                            <ButtonGroup size="small" aria-label="small button group"> 
                                <Button onClick={() => {toggleSubForm('client')}} style={{backgroundColor: subForm === 'client' ? "#00FFAA" : "#EEEEEE"}} >Client Information</Button>
                                <Button onClick={() => {toggleSubForm('sample')}} style={{backgroundColor: subForm === 'sample' ? "#00FFAA" : "#EEEEEE"}} >Sample Information</Button>
                                <Button onClick={() => {toggleSubForm('admin-receive')}} style={{backgroundColor: subForm === 'admin-receive' ? "#00FFAA" : "#EEEEEE"}} >Admin Receipt Form</Button>
                                <Button onClick={() => {toggleSubForm('testing')}} style={{backgroundColor: subForm === 'testing' ? "#00FFAA" : "#EEEEEE"}} >Testing</Button>
                                <Button onClick={() => {toggleSubForm('materialOrder')}} style={{backgroundColor: subForm === 'materialOrder' ? "#00FFAA" : "#EEEEEE"}} >Material Order Fullfillment Forms</Button>
                                <Button onClick={() => {toggleSubForm('samplingEvent')}} style={{backgroundColor: subForm === 'samplingEvent' ? "#00FFAA" : "#EEEEEE"}} >Sampling Events</Button>
                            </ButtonGroup>
                            </div>
                        
                            <br />

                            <div>
                                <Typography variant="h6" style={{marginRight: "15px"}}>Version Control</Typography>
                                <ButtonGroup size="small" aria-label="small button group"> 
                                    <Button disabled={true} onClick={handleGetHistory}>Change to Archived Form Version (back soon)</Button>
                                </ButtonGroup>
                            </div>

                            
                            <div>
                                <Typography variant="h6" style={{marginRight: "15px"}}>Version Save</Typography>
                                <ButtonGroup size="small" aria-label="small button group"> 
                                    <Button variant="contained" disabled={detectChange() || !!selected} onClick={() => setCommit({
                                        message: commitMessage,
                                        action: commitData
                                    })}>Commit Changes</Button>
                                    <Button variant="contained" disabled={detectChange() || !!selected} onClick={undoAllChanges}>Undo All Changes</Button>
                                </ButtonGroup>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={6}>                            
                                    <TopClientEditor data={dataNew} setData={setDataNew} />
                        </Grid>


                    </Grid>

                    
                    
                    <br />

                    </>
                    : null}
                    
                    
                    
                    
                </Paper>                        
            : "Loading"}

            {dataNew?.serviceLine ? 
                <>
                    <Paper elevation={3} style={{width: "95%", margin: "auto", marginTop: "15px", textAlign: "left", padding: "15px", backgroundColor: "#76c7fa"}}>
                        {subForm === "testing" ?
                            <TestEditor data={dataNew} setData={setDataNew} />
                        : null}
                        {subForm === "materialOrder" ?
                            <MaterialOrdersFullfillmentForm data={dataNew} setData={setDataNew} />
                        : null}
                        {subForm === "samplingEvent" ?
                            <SamplingEventFullfillmentForm data={dataNew} setData={setDataNew} />
                        : null}
                        {subForm === 'client' || subForm === 'sample' || subForm === 'admin-receive' ? 
                            <FieldsEditor groupSel={groupSel} setGroupSel={setGroupSel} data={dataNew} setData={setDataNew} saveData={saveData} currentForm={currentForm} selected={selected} setSelected={setSelected} parentChanged={detectChange()} />
                        : null}
                    </Paper>
                </>                            
            : null}

        </div>
        }
        
        </div>
    );

}

export default FormManager;
