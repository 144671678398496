import * as React from 'react';

// mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// utils
import dateHandlers from '../../utils/functions/dateHandlers';

const AdminComments = ({ userInput }) => {

    const labOrder = userInput.comments?.find(c => c.limsLaboratoryOrder)
  return (
    
    <TableContainer style={{marginTop: "25px", width: "100%"}}>
        <Table>
            <TableHead>
                <TableRow className="cocRow">
                    <td align="center" className="cocCellComment"><b>ID</b></td>
                    {labOrder ? 
                        <td align="center" className="cocCellComment" style={{ whiteSpace: 'nowrap'}}><b>Laboratory Order</b></td>
                    : null}
                    <td align="center" className="cocCellComment" style={{width: '70em'}}><b>Admin Submission Comment(s)</b></td>
                    <td align="center" className="cocCellComment" ><b>Name</b></td>
                    <td align="center" className="cocCellComment" style={{ whiteSpace: 'nowrap'}}><b>Date, Time</b></td>
                </TableRow>
            </TableHead>
            <TableBody style={{ width: '100%'}}>
                {userInput.comments.map((comment, i) => {
                    return (
                        <TableRow className="cocRow" key={'comment-'+i}>                        
                            <td className="cocCellComment" align="center">{i + 1}</td>
                            {labOrder ? 
                                <td align="center" className="cocCellComment" style={{ whiteSpace: 'nowrap'}} >{comment.limsLaboratoryOrder || 'Pre-Import'}</td>
                            : null}
                            <td className="cocCellComment" align="left"><div dangerouslySetInnerHTML={{__html: comment.text}} /></td>
                            <td className="cocCellComment" align="center">{comment.user}</td>
                            <td className="cocCellComment" align="center" style={{ whiteSpace: 'nowrap'}}>{dateHandlers.standardDate(comment.dateTime)} {dateHandlers.standardTime(comment.dateTime)}</td>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </TableContainer>


  );
}

export default AdminComments;


