import React from 'react';

// mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

// utils
import "../../utils/styles/cocStyle.css"
import dateHandlers from '../../utils/functions/dateHandlers';

const RelinquishmentSignatures = ({ data, userInput, setUserData, setMode, setGlobalLoading, samplingMode }) => {

  const signatures = userInput.signatures?.filter(s => s.type === 'relinquish').sort(function(a,b){return new Date(a.dateTime) - new Date(b.dateTime)});

  const initialSignature = signatures?.length > 0 ? signatures[0] : false
  const allOtherSignatures = signatures?.slice(1) || false

  return (
        
    <TableContainer>

        {initialSignature ? 
          <Table>
              <TableHead>
                <TableRow>
                  <td className="signatureCellHeader">{ samplingMode ? 'Submitted' : 'Relinquished' } By</td>
                  <td className="signatureCellHeader">Date</td>
                  <td className="signatureCellHeader">Time</td>    
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <td className="signatureCell"><span>{initialSignature?.name}</span></td>
                  <td className="signatureCell">{initialSignature?.dateTime ? dateHandlers.standardDate(initialSignature?.dateTime) : ""}</td>
                  <td className="signatureCell">{initialSignature?.dateTime? dateHandlers.standardTime(initialSignature?.dateTime) : ""}</td>
                  {allOtherSignatures.length && !samplingMode ? 
                  <td className="signatureCellHeader">Temp., °C</td> 
                  : null}
                </TableRow> 

                {samplingMode && allOtherSignatures?.length > 0 ? 
                  <TableRow>
                    <td className="signatureCellHeader">Relinquished By</td>
                    <td className="signatureCellHeader">Date</td>
                    <td className="signatureCellHeader">Time</td>  
                    <td className="signatureCellHeader">Temp., °C</td>    
                  </TableRow>
                : null } 
                {allOtherSignatures ? allOtherSignatures.map((s, i) => {
                  return (
                    <TableRow key={'sig-add-'+i}>
                      <td className="signatureCell">
                      <span>{s.name}</span>
                      </td>
                      <td className="signatureCell">{dateHandlers.standardDate(s.dateTime)}</td>
                      <td className="signatureCell">{dateHandlers.standardTime(s.dateTime)}</td>
                      <td className="signatureCell">{s.tempurature === null || s.tempurature === undefined || s.tempurature.toString() === '0.00' || s.tempurature === 0  ? 'NA' : s.tempurature}</td>
                    </TableRow>
                  )
                }) 
                : null}
                
              </TableBody>
      </Table>
        : null }

    </TableContainer>     
    
  );
}

export default RelinquishmentSignatures;
