import React from 'react';

// mui
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const AdminMainHeader = () => {

    return (
        <TableHead>
            <TableRow>
                <TableCell style={{fontWeight: "bold", fontSize: ".8rem"}} align="left">
                    Submission ID                
                </TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">Tools</TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">Notes</TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">TAT</TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">Saved/Submission Date</TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">Last Modified</TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">Service Line</TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">Relinq. Type</TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">Status</TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">Details</TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">Samples</TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">Signed</TableCell>
                <TableCell style={{fontWeight: "bold", fontSize: ".9rem", padding: '0px'}} align="center">Received</TableCell>
            </TableRow>
        </TableHead>        
    )

}

export default AdminMainHeader;
