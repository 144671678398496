const modeDetector = (checkParams, userInput, currentSamplingMode) => {
    checkParams = checkParams.filter(p => p.userInputs.length > 1).filter(s => new Date(s.createdAt) < userInput.createdAt ? new Date(userInput.createdAt) : new Date())
    if (!userInput) {
        return
    }
    if (currentSamplingMode) {
        // currently in sampling mode, checking if we should leave it
        let clientMatch = false
        const currentParam = checkParams.find(ui => ui.modeName === currentSamplingMode)
        if (!currentParam) {
            return
        }
        const alignmentCheck = currentParam.userInputs.filter(cp => userInput[cp.key]?.trim() === cp.value?.trim())
        if (currentParam.userInputs.filter(ui => ui.type === 'client').length === alignmentCheck.length) { clientMatch = true }

        let sampleMatch = false
        userInput.samples.forEach(s => {
            const alignmentCheck = currentParam.userInputs.filter(ui => ui.type === 'sample').filter(cp => s[cp.key]?.trim() === cp.value?.trim())
            if (currentParam.userInputs.filter(ui => ui.type === 'sample').length === alignmentCheck.length) { sampleMatch = true }
        })

        if (clientMatch && sampleMatch) {
            return currentParam 
        } else {
            return false
        }

    } else {
        // not in sampling mode, checking if we should enter it
        let foundParam = false
        checkParams.forEach(p => {
            if (!userInput?.samples) {
                return false
            }
            let clientMatch = false
            const clientInputs = p.userInputs.filter(ui => ui.type === 'client')
            const alignmentCheck = clientInputs.filter(cp => userInput[cp.key]?.trim() === cp.value?.trim())
            if (clientInputs.length === alignmentCheck.length) { clientMatch = true }

            const sampleInputs = p.userInputs.filter(ui => ui.type === 'sample')
            
            let sampleMatch = false
            userInput.samples.forEach(s => {
                const alignmentCheck = sampleInputs.filter(cp => s[cp.key]?.trim() === cp.value?.trim())
                if (sampleInputs.length === alignmentCheck.length) { sampleMatch = true }
            })

            if (clientMatch && sampleMatch) {
                foundParam = p
            }
        })

        return foundParam
    }
    
}

export default modeDetector;
