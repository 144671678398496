import React from 'react';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';

// components
import COCPrint from '../dialogues/COCPrint';
import DocumentControlSampling from './SamplingEventInputs/DocumentControlSampling.js';

// mui
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Typography } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import ScienceIcon from '@mui/icons-material/Science';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import GetAppIcon from '@mui/icons-material/GetApp';

// controls for lab use hide completely from user
// create /admin/orderid page (same as sampling)

// printing only shows up POST relinquishment

const FileDownload = require('js-file-download');

const DocumentControl = ({ handleNewPage, page, setPage, maxPages, userInput, setUserData, data, labUseOpen, setLabUseOpen, createSamplingDuplicates, setSamplingEventMode, samplingEventMode, samplingEventInputs, setSamplingEventInputs, SEOR, setSEOR, samplingMode }) => {

  const [openPrint, setOpenPrint] = React.useState(false)

  const handleFirstPageButtonClick = () => {
    setPage(0);
    handleNewPage(0)
  };

  const handleBackButtonClick = () => {
    setPage(page - 1);
    handleNewPage(page - 1)
  };

  const handleNextButtonClick = () => {
    setPage(page + 1);
    handleNewPage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    setPage(maxPages - 1);
    handleNewPage(maxPages - 1);
  };

  const handlePrint = async () => {
    setOpenPrint(true)
  }

  const handleDownload = () => {
    const working = cloneDeep(userInput)

    // remove all instances of "tab" char from all strings. Can patch in more replaces if they come up
    Object.entries(working).forEach(([key, value]) => {
      if (typeof value === 'string') {
        // tab
        working[key] = value.replace(/\t/g, "")
        if (typeof working[key] === 'string' && working[key]?.indexOf(":isArray:") > -1) {
          working[key].split(':isArray:').filter(item => item !== '').join(', ')
        }
      }
      if (key === 'samples') {
        value.forEach(sample => {
          Object.entries(sample).forEach(([Skey, Svalue]) => {
            if (typeof Svalue === 'string') {
              // tab
              sample[Skey] = Svalue.replace(/\t/g, "")
            }            

            if (sample[Skey].toString()?.indexOf(":isArray:") > -1) {
              sample[Skey] = Svalue.split(':isArray:').filter(item => item !== '')
              if (Skey.toLowerCase().indexOf('threshold') > -1) {
                sample[Skey] = sample[Skey].map(th => {
                  return data.references?.thresholds?.find(t => t.LIMS_ID === th)?.DISPLAYNAME
                })                  
              } 

              sample[Skey] = sample[Skey].join(', ')
              
            }
          })
        })
      }
      if (key === 'signatures') {
        value.forEach(sample => {
          Object.entries(sample).forEach(([Skey, Svalue]) => {
            if (typeof Svalue === 'string') {
              // tab
              sample[Skey] = Svalue.replace(/\t/g, "")
            }
            if (sample[Skey].toString()?.indexOf(":isArray:") > -1) {
              sample[Skey] = Svalue.split(':isArray').filter(item => item !== '').join(', ')
            }
          })
        })
      }
    })

    // matrix db ID to display name
    working.samples.forEach(s => {
      s.matrix = data.references.materials?.find(item => item.LIMS_ID === s.matrix).DISPLAYNAME
      if (s.collectionTime) {
        s.collectionTime = dayjs(s.collectionTime).format('HH:mm')
      }
      if (s.collectionDate) {
        s.collectionDate = dayjs(s.collectionDate).format('MM/DD/YYYY')
      }
    })

    // force string on temp, might be redundant, but just in case to prevent LIMS from freaking out
    working.signatures.forEach(s => {
      if (s.tempurature || s.tempurature === 0) {
        s.tempurature = s.tempurature?.toString()
      }
    })

    FileDownload(JSON.stringify(working), userInput.submissionID + '.json')
  }

  const isSampling = () => {
    
    const samplingEventCheck = userInput?.submissionType?.split(',')?.[1]

    if (samplingEventCheck) {
      const samplingForm = data.forms.sections.filter(s => s.triggerInputs?.length > 0).filter(s => new Date(s.createdAt) < new Date(userInput.createdAt)).find(s => s.samplingEventName === samplingEventCheck || s.samplingEventKey === samplingEventCheck)
      if (samplingForm) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  
const disablePreSampling = isSampling() && !userInput.signatures?.find(s => s.staff === 'sampler' && s.type === 'receive')


  return (
    <>
      {/* Dialogue */}
      <COCPrint open={openPrint} setOpen={setOpenPrint} userInput={userInput} maxPages={maxPages} data={data} samplingMode={samplingMode} />


      <Grid container spacing={0}>

        <Grid item md={6} xs={12}>

          <Typography variant="span" style={{ color: "white", fontWeight: "bold", fontSize: "0.75em" }}>Page: {page + 1} of {maxPages}</Typography>
          <IconButton
            style={{ color: page === 0 ? "lightgrey" : "white" }}
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            <FirstPageIcon />
          </IconButton>

          <IconButton
            style={{ color: page === 0 ? "lightgrey" : "white" }}
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="next page"
          >
            <KeyboardArrowLeft />
          </IconButton>

          <IconButton
            style={{ color: page === maxPages - 1 ? "lightgrey" : "white" }}
            onClick={handleNextButtonClick}
            disabled={page === maxPages - 1}
            aria-label="previous page"
          >
            <KeyboardArrowRight />
          </IconButton>

          <IconButton
            style={{ color: page === maxPages - 1 ? "lightgrey" : "white" }}
            onClick={handleLastPageButtonClick}
            disabled={page === maxPages - 1}
            aria-label="last page"
          >
            <LastPageIcon />
          </IconButton>


        </Grid>



        <Grid item md={6} xs={12} >



          {/* Sampling Related here */}
          {window.location.href.indexOf("sampling") > -1 && window.location.href.indexOf("admin") > -1 ?
            <DocumentControlSampling
              setSamplingEventMode={setSamplingEventMode} samplingEventMode={samplingEventMode}
              handleDownload={handleDownload}
              userInput={userInput} setUserData={setUserData}
              createSamplingDuplicates={createSamplingDuplicates}
              samplingEventInputs={samplingEventInputs} setSamplingEventInputs={setSamplingEventInputs}
            />
            : null}

          {window.location.href.indexOf("admin") > -1 ?
            <>
              {userInput.final && !disablePreSampling ?
                <Tooltip title="Lab Use - Receive & Relinquish">
                  <IconButton
                    style={{ color: "white" }}
                    aria-label="print"
                    onClick={() => setLabUseOpen(!labUseOpen)}
                  >
                    <ScienceIcon />
                  </IconButton>
                </Tooltip>
              : null}

              <Tooltip title="Download LIMS Import File - JSON">

                <IconButton
                  style={{ color: "white" }}
                  aria-label="print"
                  onClick={handleDownload}
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>

            </>
            : null}

            {userInput.status !== 'saved' && !!userInput.status ?
            
              <Tooltip title="Print - Print to PDF">
              <IconButton
                style={{ color: "white" }}
                aria-label="print"
                onClick={handlePrint}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
            : null

            }
          {/* Post-sampling-submission toggler */}
          {
            window.location.href.toLowerCase().indexOf("cannabis-sampling") > -1
              &&
              userInput.status === "client-signed"
              ?
              <>
                {
                  SEOR
                    ?
                    <Button sx={{ color: "white" }} onClick={() => setSEOR(!SEOR)} >Show Chain of Custody</Button>
                    :
                    <Button sx={{ color: "white" }} onClick={() => setSEOR(!SEOR)} >Show Sample Submission Summary</Button>
                }
              </>

              : null}


        </Grid>







      </Grid>

    </>
  );
}

export default DocumentControl;
