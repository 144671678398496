import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// components
import MDLDisplay from '../../dialogues/MDLDisplay';
import TestIncrementor from './TestIncrementor';

// mui
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ArticleIcon from '@mui/icons-material/Article';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Alert, Typography } from '@mui/material';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

// utils
import assignTestToGroup from '../../../utils/functions/assignTestToGroup';
import reSortGroupedTesting from '../../../utils/functions/reSortGroupedTesting';

const TestingForm = ({ userInput, setUserInput, refSample, data, group, renderData, renderClientData, formMode, searchParam }) => {

    const activeItem = refSample || userInput.materialOrders?.[0]
    const groupRef = renderData?.testing_groups?.find(g => g.group === group)

    // for mat orders, filter out list

    const disregardTests = () => {
        if (data.activeSamplingEvent) {
          return data.activeSamplingEvent.testingHide || []
        } else if (formMode !== 'samples') {
          // materials ordering
          const materialOrderForm = data.forms.sections.find(s => s.type === "materialOrder")
          return materialOrderForm?.disregardTests || []
        } else {
          return []
        }
      }

    const groupTests = groupRef?.group === 'clientTesting' ? groupRef.tests.filter(t => renderClientData.find(rcd => rcd.LIMS_TEST_ID === t.LIMS_ID)) : groupRef?.tests

    // use this with "undo"
    // const [removedTests, setRemovedTests] = React.useState([])
    const [mdlOpen, setMdlOpen] = React.useState(false)

    // refactor the method below into several, as they can be useful elsehwere
    const toggleTest = (test) => {
        const newSubItem = cloneDeep(refSample || userInput.materialOrders?.[0]) 
        // create testing arr if none exists
        if (!newSubItem.activeTests) {            
            newSubItem.activeTests = []
        }

        // bottles ref
        const bottlesList = data.testing[test.type + 's']?.filter(t => t.LIMS_ID === test.LIMS_ID).map(t => t.BOTTLE_ID).filter(Boolean)
        const bottlesUnique = [...new Set(bottlesList)]

        // Add if it exists
        const existsCheck = newSubItem.activeTests.find(t => t.LIMS_ID === test.LIMS_ID)
        const removedList = []
        if (!existsCheck) {
            // get full list of selected
            const fullTest = data.testing[test.type + 's']?.filter(t => t.LIMS_ID === test.LIMS_ID).map(t => t.PM_LIMS_ID);
            newSubItem.activeTests.forEach(tt => {
                // compare PM_LIMS_ID, remove them
                
                const checkList = data.testing[tt.type + 's']?.filter(t => t.LIMS_ID === tt.LIMS_ID).map(t => t.PM_LIMS_ID);
                const filteredArray = checkList.filter(value => fullTest.includes(value));
                if (filteredArray.length > 0) {
                    newSubItem.activeTests = newSubItem.activeTests.filter(t => t.LIMS_ID !== tt.LIMS_ID)
                    removedList.push(tt)
                }
            })

            // insert bottle here
            const existingBottles = newSubItem.activeBottles || []
            const newBottleObjects = data.references.bottles.filter(b => bottlesUnique.indexOf(b.BOTTLE_LIMS_ID) > -1).filter(b => !existingBottles.find(eb => eb.BOTTLE_LIMS_ID === b.BOTTLE_LIMS_ID))

            newSubItem.activeBottles = [...existingBottles, ...newBottleObjects]
            // testing selected must have validity for comparing against current if this selection is used as template
            const validity = data.testing[test.type + 's']?.find(t => t.LIMS_ID === test.LIMS_ID)
            test.validityID = validity.VALIDITYID            
            test.validityDate = validity.VALIDITYDATE
            
            const clientTest = data.references[test.type + 'Clients']?.find(psc => psc.LIMS_TEST_ID === test.LIMS_ID)
            if (clientTest) {
                test.CLIENT_PASSKEY = clientTest.CLIENT_PASSKEY
            }
            newSubItem.activeTests.push(test)

        } else {
            // remove the test otherwise
            newSubItem.activeTests = newSubItem.activeTests.filter(t => t.LIMS_ID !== test.LIMS_ID)

            // remove bottles here if there are bottles      
            if (newSubItem.activeBottles) {
                newSubItem.activeBottles = newSubItem.activeBottles?.filter(ab => bottlesUnique.indexOf(ab.BOTTLE_LIMS_ID) < 0)
            }
            
        }

        // put removed into state to allow for undo
        // setRemovedTests(removedList)

        // // update full input
        const newInput = cloneDeep(userInput)

        if (refSample) {
            const index = newInput.samples.findIndex(s => s.id === refSample.id)                    
            newInput.samples.splice(index, 1, newSubItem)
        } else {
            // materials orders
            newInput.materialOrders = [newSubItem]
        }
        
        setUserInput(newInput)
    }

    const toggleTestIndividual = (list) => {
        const direction = Array.isArray(list)
                
        if (direction) {
            // adding test
            const newTest = list?.filter(li => !refSample.activeTests?.find(at => at.LIMS_ID === li))[0] || list[0]
            const test = data.testing['parameterMethods']?.find(t => t.LIMS_ID === newTest);
            toggleTest({
                LIMS_ID: test.LIMS_ID,
                LIMS_NUMBER: test.LIMS_NUMBER,
                type: 'parameterMethod',
                individual: true,
                individualName: groupRef.individualAnalytesName || (groupRef.group.charAt(0).toUpperCase() + " Additional Choices")
            })
        } else {
            // removing test
            const removeTest = data.testing['parameterMethods']?.find(t => t.LIMS_ID === list);
            toggleTest(removeTest)
        }
        
    }

    // remove non-active items from this list
    const invididualAnalyteOptions = groupRef?.individualAnalytes?.filter(a => {
        const analyteRef = data?.testing?.parameterMethods?.find(ana => ana.LIMS_ID === a)   
        // currently, active is a string with value 1, not an integer. Other tables use other items, like n and j (for nein and ja), something to keep in mind.   
        let searchCheck = true
        if (searchParam) {
            searchCheck = analyteRef?.DISPLAYNAME?.toLowerCase().includes(searchParam.toLowerCase()) 
        }  
        return analyteRef?.ACTIVE === '1' && searchCheck
    })

    const handleIncrementTest = (test, inc) => {
        const newSubItem = cloneDeep(refSample || userInput.materialOrders[0]) 
        
        // create testing arr if none exists
        if (!newSubItem.activeTests) {            
            newSubItem.activeTests = []
        }

        // bottles ref
        const bottlesList = data.testing[test.type + 's']?.filter(t => t.LIMS_ID === test.LIMS_ID).map(t => t.BOTTLE_ID).filter(Boolean)
        const bottlesUnique = [...new Set(bottlesList)]

        // Add if it exists
        const existsCheck = newSubItem.activeTests.find(t => t.LIMS_ID === test.LIMS_ID)
        if (!existsCheck) {
            
            // assign selected test to a group
            const processedTest = assignTestToGroup(test, newSubItem, data)
            
            // insert bottles here
            const existingBottles = newSubItem.activeBottles || []
            const newBottleObjects = data.references.bottles.filter(b => bottlesUnique.indexOf(b.BOTTLE_LIMS_ID) > -1).filter(b => !existingBottles.find(eb => eb.BOTTLE_LIMS_ID === b.BOTTLE_LIMS_ID))
            newSubItem.activeBottles = [...existingBottles, ...newBottleObjects]

            // testing selected must have validity for comparing against current if this selection is used as template
            const validity = data.testing[processedTest.type + 's']?.find(t => t.LIMS_ID === processedTest.LIMS_ID)
            processedTest.validityID = validity.VALIDITYID            
            processedTest.validityDate = validity.VALIDITYDATE

            const clientTest = data.references[processedTest.type + 'Clients']?.find(psc => psc.LIMS_TEST_ID === processedTest.LIMS_ID)
            if (clientTest) {
                processedTest.CLIENT_PASSKEY = clientTest.CLIENT_PASSKEY
            }
            
            // use inc if this is a parent, otherwise copy the parent's count
            
            processedTest.count = processedTest.consolidateTarget === 'parent' ? inc : newSubItem.activeTests.find(at => at.LIMS_ID === processedTest.consolidateTarget).count

            newSubItem.activeTests.push(processedTest)

        } else {
            // add to count
            const existingTest = newSubItem.activeTests.find(at => at.LIMS_ID === test.LIMS_ID)
            existingTest.count = existingTest.count + inc

            // apply count to ENTIRE GROUP
            const parent = existingTest.consolidateTarget === 'parent' ? existingTest : newSubItem.activeTests.find(at => at.LIMS_ID === existingTest.consolidateTarget)
            const children = newSubItem.activeTests.filter(at => at.consolidateTarget === parent.LIMS_ID)
            parent.count = existingTest.count
            children.forEach(g => {
                g.count = existingTest.count
            })
            
            // treat the group the same
            newSubItem.activeTests.forEach(at => {
                // remove if we hit 0
                if (at.count < 1) {
                    newSubItem.activeTests = newSubItem.activeTests.filter(t => t.LIMS_ID !== at.LIMS_ID)

                    // remove bottles here if there are bottles      
                    if (newSubItem.activeBottles) {
                        newSubItem.activeBottles = newSubItem.activeBottles?.filter(ab => bottlesUnique.indexOf(ab.BOTTLE_LIMS_ID) < 0)
                    }
                }
            })
        }
        
        // sort groups
        newSubItem.activeTests = reSortGroupedTesting(newSubItem.activeTests, data)       

        // update full input
        const newInput = cloneDeep(userInput)

        if (refSample) {
            const index = newInput.samples.findIndex(s => s.id === refSample.id)                    
            newInput.samples.splice(index, 1, newSubItem)
        } else {
            // materials orders
            newInput.materialOrders = [newSubItem]
        }
        
        setUserInput(newInput)
        
    }

    return (        
        <Grid item xs={12} md={12} style={{marginTop: "15px"}}>
            {mdlOpen ? <MDLDisplay open={mdlOpen} setOpen={setMdlOpen} data={data} /> : null}
            {refSample.other ? <Alert severity="error">Your previous submission contained a selection named &quot;Other&quot;. This is no longer an available option. Please re-review the options and utilize &quot;additional testing&quot; therein to find the specific analytes you requested and update your sample comment. If you are unable to locate the desired testing simply leave your comment as-is. <span style={{fontWeight: 'bold'}}>This comment will not appear on any orders templated from this submission.</span></Alert> : null}
            <Grid container spacing={0}>
                <Grid item xs={12} md={12} lg={formMode === 'samples' ? 6 : 12}>
                <List dense={true}>
                    {groupTests.filter(t => !t.column).filter(t => {
                        let display = true
                        const test = data.testing[t.type + 's']?.find(tt => tt.LIMS_ID === t.LIMS_ID);
                        const active = test?.ACTIVE === 'n' || test?.ACTIVE === '0'
                        const foundMats = data?.references[t.type + 'Materials']?.filter(m => m[t.type.toUpperCase() + '_LIMS_ID'] === t.LIMS_ID)
                        const foundMat = foundMats?.find(m => m.MATERIAL_LIMS_ID === refSample.matrix)
                        const fullTest = data.testing[t.type + 's']?.filter(tt => tt.LIMS_ID === t.LIMS_ID && tt.BOTTLE_ID);

                        if (formMode !== 'samples' && fullTest?.length < 1) {
                            // in TestingBody you may one day need to recreate the filter as it renders groups if the entire group lacks bottles
                            display = false
                        }

                        if (foundMats?.length && !foundMat) {
                            display = false
                        }
                        if (!test || active) {
                            display = false
                        }                        
                        if (disregardTests().includes(t.LIMS_ID)) {
                            display = false
                        }

                        const filterCheck = test.DETAILS?.toLowerCase().includes(searchParam?.toLowerCase()) || test.DISPLAYNAME?.toLowerCase().includes(searchParam?.toLowerCase()) || test.PRODUCTCODE?.toLowerCase().includes(searchParam?.toLowerCase()) || test.METHODN?.toLowerCase().includes(searchParam?.toLowerCase()) || test.METHODREPORT?.toLowerCase().includes(searchParam?.toLowerCase())
                        if (!filterCheck) {
                            display = false
                        }

                        return display
                    }).map((o, i) => {
                        const test = data.testing[o.type + 's']?.find(t => t.LIMS_ID === o.LIMS_ID);
                        const selectedCheck = activeItem?.activeTests?.find(t => t.LIMS_ID === test?.LIMS_ID) || false
                        // may need to split up this spectra list thing if spectra has details to share
                        const details = test?.DETAILS || null

                        return (                         
                            <ListItem key={"testListItem"+o.LIMS_ID+i} style={{ height: formMode === 'samples' ? 'auto' : '80px'}}>
                                {formMode === 'samples' ? 
                                    <Checkbox 
                                        onClick={()  => toggleTest(o)} 
                                        checked={!!selectedCheck} 
                                        disabled={data.activeSamplingEvent ? data.activeSamplingEvent.disableFieldTest(test.LIMS_ID) : false}
                                    />
                                : null}
                                {formMode !== 'samples' ?
                                    <TestIncrementor handleIncrementTest={handleIncrementTest} test={o} selectedCheck={selectedCheck} />
                                : null}
                                <ListItemText
                                    primary={`${test?.PRODUCTCODE ? (test?.PRODUCTCODE + ' - ') : ''}${test.DISPLAYNAME}`}
                                    secondary={
                                        <>
                                        {details ? <Typography variant="span" style={{ fontSize: "0.8rem", verticalAlign: 'bottom'}} >{details}</Typography> : null}
                                        </>
                                    }
                                />

                                {test?.PRODUCTCODE === 'P2220W' &&
                                <IconButton color="primary" href="/document-viewer/P2220 Pesticide Multi-residue Profile 2024 _WATER.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }
                                
                                {(test?.PRODUCTCODE === 'P2220V' || test?.PRODUCTCODE === 'P2220S') &&
                                <IconButton color="primary" href="/document-viewer/P2220V - Multi-Residue Pesticide Profile (veg).pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }
                                
                                {(test?.PRODUCTCODE === 'P2750V' || test?.PRODUCTCODE === 'P2750S') &&
                                <IconButton color="primary" href="/document-viewer/P2750 Herbicides Screen_VEG and SOIL_02.06.2024.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }
                                
                                {test?.PRODUCTCODE === 'P2750W' &&
                                <IconButton color="primary" href="/document-viewer/P2750 Herbicides Screen_WATER_02.06.2024.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }
                                
                                {test?.PRODUCTCODE === 'P7000' &&
                                <IconButton color="primary" href="/document-viewer/P7000 NOP Pesticide Profile_09.27.22.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }

                                {test?.PRODUCTCODE === 'P3400' &&
                                <IconButton color="primary" href="/document-viewer/P3400 - Tree Fruit and Berries Pestcide Profile.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }

                                {test?.PRODUCTCODE === 'P2320' &&
                                <IconButton color="primary" href="/document-viewer/P2320 Multi-residue pesticide profile-CANNABIS_09.12.22.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }

                                {test?.MDL ?
                                    <Tooltip title="Show MDL">
                                        <IconButton color="primary" onClick={() => setMdlOpen(o)}>
                                            <ArticleIcon />
                                        </IconButton>
                                    </Tooltip>
                                : null}
                                
                            </ListItem>
                        )
                    })}
                </List>
                </Grid>
                <Grid item xs={12} md={12} lg={formMode === 'samples' ? 6 : 12}>
                <List dense={true}>
                    {groupTests.filter(t => !!t.column).filter(t => {
                        const test = data.testing[t.type + 's']?.find(tt => tt.LIMS_ID === t.LIMS_ID);
                        const active = test?.ACTIVE === 'n' || test?.ACTIVE === '0'
                        let display = true
                        const foundMats = data.references[t.type + 'Materials']?.filter(m => m[t.type.toUpperCase() + '_LIMS_ID'] === t.LIMS_ID)
                        const foundMat = foundMats?.find(m => m.MATERIAL_LIMS_ID === refSample.matrix)
                        if (foundMats?.length && !foundMat) {
                            display = false
                        }
                        if (!test || active) {
                            display = false
                        }
                        if (disregardTests().includes(t.LIMS_ID)) {
                            display = false
                        }
                        const filterCheck = test.DETAILS?.toLowerCase().includes(searchParam?.toLowerCase()) || test.DISPLAYNAME?.toLowerCase().includes(searchParam?.toLowerCase()) || test.PRODUCTCODE?.toLowerCase().includes(searchParam?.toLowerCase()) || test.METHODN?.toLowerCase().includes(searchParam?.toLowerCase()) || test.METHODREPORT?.toLowerCase().includes(searchParam?.toLowerCase())
                        if (!filterCheck) {
                            display = false
                        }

                        return display
                    }).map((o, i) => {
                        const test = data.testing[o.type + 's']?.find(t => t.LIMS_ID === o.LIMS_ID);
                        const selectedCheck = activeItem.activeTests?.find(t => t.LIMS_ID === test?.LIMS_ID) || false
                        // may need to split up this spectra list thing if spectra has details to share
                        const details = test.DETAILS || null
                        return (                         
                            <ListItem key={"testListItem"+o.LIMS_ID+i} style={{ height: formMode === 'samples' ? 'auto' : '80px'}}>                                
                                {formMode === 'samples' ? 
                                    <Checkbox 
                                        onClick={()  => toggleTest(o)} 
                                        checked={!!selectedCheck} 
                                        disabled={data.activeSamplingEvent ? data.activeSamplingEvent.disableFieldTest(test.LIMS_ID) : false}
                                    />
                                : null}
                                {formMode !== 'samples' ?
                                    <TestIncrementor handleIncrementTest={handleIncrementTest} test={o} selectedCheck={selectedCheck} />
                                : null}
                                <ListItemText
                                    primary={`${test?.PRODUCTCODE ? (test?.PRODUCTCODE + ' - ') : ''}${test.DISPLAYNAME}`}
                                    secondary={
                                        <>
                                        {details ? <Typography variant="span" style={{ fontSize: "0.8rem", verticalAlign: 'bottom'}} >{details}</Typography> : null}
                                        </>
                                    }
                                />
                                
                                {test?.PRODUCTCODE === 'P2220W' &&
                                <IconButton color="primary" href="/document-viewer/P2220 Pesticide Multi-residue Profile 2024 _WATER.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }
                                
                                {(test?.PRODUCTCODE === 'P2220V' || test?.PRODUCTCODE === 'P2220S') &&
                                <IconButton color="primary" href="/document-viewer/P2220V - Multi-Residue Pesticide Profile (veg).pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }
                                
                                {(test?.PRODUCTCODE === 'P2750V' || test?.PRODUCTCODE === 'P2750S') &&
                                <IconButton color="primary" href="/document-viewer/P2750 Herbicides Screen_VEG and SOIL_02.06.2024.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }
                                
                                {test?.PRODUCTCODE === 'P2750W' &&
                                <IconButton color="primary" href="/document-viewer/P2750 Herbicides Screen_WATER_02.06.2024.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }
                                
                                {test?.PRODUCTCODE === 'P7000' &&
                                <IconButton color="primary" href="/document-viewer/P7000 NOP Pesticide Profile_09.27.22.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }

                                {test?.PRODUCTCODE === 'P3400' &&
                                <IconButton color="primary" href="/document-viewer/P3400 - Tree Fruit and Berries Pestcide Profile.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }
                                
                                {test?.PRODUCTCODE === 'P2320' &&
                                <IconButton color="primary" href="/document-viewer/P2320 Multi-residue pesticide profile-CANNABIS_09.12.22.pdf" target="_blank" rel="noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </IconButton> }
                                
                                {test.MDL ?
                                    <Tooltip title="Show MDL">
                                        <IconButton color="primary" onClick={() => setMdlOpen(o)}>
                                            <ArticleIcon />
                                        </IconButton>
                                    </Tooltip>
                                : null}
                                
                            </ListItem>
                        )
                    })}
                </List>
                </Grid>
            </Grid>
            {invididualAnalyteOptions?.length ? 
                <div style={{margin: '15px'}}>
                    <Autocomplete
                        disablePortal
                        disableCloseOnSelect
                        id="serviceCities"
                        name="serviceCities"
                        value={refSample.activeTests?.filter(t => !!t.individual && t.individualName === (groupRef.individualAnalytesName || (groupRef.group.charAt(0).toUpperCase() + " Additional Choices"))).map(t => t.LIMS_ID) || []}
                        onChange={(e, value) => {
                            toggleTestIndividual(value)
                        }}
                        options={invididualAnalyteOptions}
                        multiple={true}
                        getOptionLabel={a => {
                            const analyte = data.testing.parameterMethods.find(pm => pm.LIMS_ID === a)
                            return analyte.DISPLAYNAME
                        }}
                        sx={{ width: "100%", mt: "4%" }}
                        // isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params}
                            label={groupRef.individualAnalytesName}
                            InputLabelProps={{ shrink: true }}
                            helperText={"Don't see your analytes listed? Find more testing options here."}
                        />}
                        renderTags={(value) =>
                            value.map((option, index) => {        
                            const analyte = data.testing.parameterMethods.find(pm => pm.LIMS_ID === option)
                            return <Chip key={option + '-' + index} variant="outlined" label={analyte.DISPLAYNAME} value={option} onDelete={() => toggleTestIndividual(option)} />
                        })
                        }
                    />
                
                </div>
            : null}
        {/* Use this maybe
            <Snackbar
                open={removedTests.length > 0}
                autoHideDuration={3000}     
            >
                <Alert severity="info" variant="filled" sx={{ width: '100%', fontSize: "10px" }}>                
                    Click here to undo last selection
                </Alert>
            </Snackbar> */}
            
        </Grid>
    );
}

export default TestingForm;
