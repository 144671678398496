import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// mui
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';

// utils
import FormAPI from '../../utils/API/api-form';

const RelinquishmentTools = ({ userData, setUserData, data }) => {
  
  const [loading, setLoading] = React.useState(false)
  const [error, seterror] = React.useState(false)

  const handleChange = async (key) => {
    setLoading(true)
    seterror(false)
    const newUserData = cloneDeep(userData)
    newUserData[key] = !newUserData[key]
    if (key === 'imported' && newUserData[key]) {
      newUserData.archive = true
    }
    try {
      const res = await FormAPI.saveClientSubmission({ data: {userJSON: newUserData} })
      setUserData(res.data)
      setLoading(false)
    } catch (err) {
      // do error
      console.log(err)
      seterror('Something went wrong setting and saving submission. Please try refreshing the page, trying again, and if the issue persists contact the development team.')
    }

    
  }
  
    return (
      <div style={{ backgroundColor: 'lightGrey', padding: '10px', borderRadius: '5px', marginBottom: '15px'}}>


      <Typography align="left" variant="h5" >Submission Tools</Typography> 
      <Divider variant="middle" />

      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
      <Typography align="left" variant="h6" >Submission Status Togglers</Typography> 
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox disabled={loading} checked={userData.imported} onChange={() => handleChange('imported')} name="Imported" />
            }
            label="Submission Imported"
          />
        </FormGroup>
        <FormHelperText>Marking submission as imported communicates that this submission&lsquo;s data has been importoed to LIMS. Warning, this is not an automated feature. This also prevents further commenting on submission.</FormHelperText>
      </FormControl>

      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox disabled={loading} checked={userData.archive} onChange={() => handleChange('archive')} name="Imported" />
            }
            label="Submission Archvied"
          />
        </FormGroup>
        <FormHelperText>Marking submission as archived hides it from the main Admin table. This also prevents further commenting on submission.</FormHelperText>
      </FormControl>

      <Typography align="left" variant="p" >{error}</Typography> 
      {loading ? <LinearProgress /> : false}
    </div>
    )

}

export default RelinquishmentTools;
