import React from 'react';
import { cloneDeep } from 'lodash';

// components
import GenericConfirm from '../dialogues/GenericConfirm';
import Requirements from './GroupTools/Requirements';
import headerIcons from '../Form/headerIcons';
import UseAsAbove from './GroupTools/UseAsAbove';

// mui
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:'#EEEEEE',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const GroupAdvancedSettings = ({ data, setData, currentForm, groupSel, setGroupSel }) => {
  
    const currentSubSection = currentForm.sub_sections.find(ss => ss.sectionKey === groupSel)
    const [deleteItem, setDeleteItem] = React.useState(false)

    const updateIcon = (key)=> {
        
        const newData = cloneDeep(data)
        const newForm = cloneDeep(currentForm)
        const newSubSection = cloneDeep(currentSubSection)

        newSubSection.icon = key
        
        newForm.sub_sections = newForm.sub_sections.map(ss => {
            return ss.sectionKey === newSubSection.sectionKey ? newSubSection : ss
        })

        newData.forms.sections = newData.forms.sections.map(form => {
            if (currentForm.samplingEventKey) {
                return form.samplingEventKey === currentForm.samplingEventKey ? newForm : form
            } else {
                return form.type === newForm.type ? newForm : form
            }
        })
        
        setData(newData)
    }

    const handleDeleteItem = () => {
        setDeleteItem({
            key: groupSel,
            header: 'Are you sure you want to delete this entire form group?',
            body: 'Proceeding you will delete this item and any settings applied here will be lost. Continue?',
            helperText: 'You can undo this by clicking "Undo All Changes" button on this page\'s main header.'
        })
      }

     const handleSaveDeleteItem = () => {        
        const newData = cloneDeep(data)
        const newForm = cloneDeep(currentForm)

        const index = currentSubSection.order - 1

        newForm.sub_sections.splice(index, 1)

        newData.forms.sections = newData.forms.sections.map(form => {
            if (newForm.samplingEventKey) {
                return form.samplingEventKey === newForm.samplingEventKey ? newForm : form
            } else {
                return form.type === newForm.type ? newForm : form
            }
        })
        
        setGroupSel(false) 
        setData(newData)
    }

  return (
      <div style={{padding: "15px"}}>

        <GenericConfirm open={deleteItem} setOpen={setDeleteItem} submitEntry={handleSaveDeleteItem} />

        <Stack spacing={2}>            
            <Item>
            <Typography variant="h5">Advanced Group Settings</Typography>
            <Typography variant="h6">-&gt; {currentSubSection.header}</Typography>
            
            <Button onClick={() => setGroupSel(false)}>Exit</Button>
            
            </Item>
        
            <Item>
                
                <UseAsAbove data={data} setData={setData} selected={groupSel} currentForm={currentForm} />

            </Item>

            <Item>
                <Requirements data={data} setData={setData} selected={groupSel} currentForm={currentForm} />                
            </Item>
                        
            <Item>
                <Typography variant="h6">Usable Icons</Typography>
                <Typography variant="p">More icons can be added, please contact the administrator and check <a href="https://mui.com/material-ui/material-icons/" target="_blank" rel="noreferrer">this website</a> out for all possible options.</Typography>
                    {headerIcons.map((h, i) => {
                        return (
                            <div key={i + '-' + h.key}>
                                <Typography style={{color: currentSubSection.icon === h.key ? 'green' : 'black'}}>{h.component}</Typography>
                                    
                                {currentSubSection.icon === h.key ? 
                                    <Button disabled={true} onClick={() => updateIcon(h.key)}>Current</Button>
                                :
                                    <Button onClick={() => updateIcon(h.key)}>Select</Button>
                                }

                            </div>
                        )
                    })}
                    <div>
                        <Typography style={{color: currentSubSection.icon === 'none' ? 'green' : 'black', float: 'left', fontWeight: 'bold'}}>No Icon</Typography>
                            
                        {currentSubSection.icon === 'none' ? 
                            <Button disabled={true} onClick={() => updateIcon('none')}>Current</Button>
                        :
                            <Button onClick={() => updateIcon('none')}>Select</Button>
                        }

                    </div>
            </Item>
                        
            <Item>
                <Typography variant="h6">Delete Group</Typography>               
                
                    <Button edge="end" aria-label="delete" onClick={handleDeleteItem} endIcon={<DeleteIcon />}>
                        Delete Entire Group
                    </Button>
                <Typography variant="p">Will remove entire group and all form entries contained within. This may be reversed by clicking &quot;Undo All Changes&quot; button above. This menu will also disappear.</Typography>
                
            </Item>
        </Stack>
                  
      
      </div>
  );

}

export default GroupAdvancedSettings;
