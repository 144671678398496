import React from 'react';

// mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

const VisibleOnTable = ({ selected, updateValue, updateSamplingEventVisibility, samplingEvents }) => {
    const options = ['show', 'hide', 'only']
    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Form Mode</TableCell>
                            <TableCell align="center">Visible on Mode (and visible on normal form)</TableCell>
                            <TableCell align="center">Hidden on Mode (but visible on normal form)</TableCell>
                            <TableCell align="center">Only Visible on Mode (hidden on normal form)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                Sample Container Request
                            </TableCell>
                            {options.map(op => {
                                return (
                                    <TableCell align="center" key={op + '-scr'}>
                                        <Checkbox 
                                            style={{margin: '0px', padding: '0px'}}
                                            checked={selected.viewOnMaterials === op} 
                                            disabled={selected.viewOnMaterials === op}
                                            onChange={() => updateValue(false, "viewOnMaterials", op)}
                                        />                                        
                                    </TableCell>
                                )
                            })}
                        </TableRow>

            {samplingEvents.map(se => {
                const visible = selected.visibleOnSamplingEvents?.find(vos => vos.samplingEvent === se.samplingEventKey)?.value
                return (
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        key={se.samplingEventName}
                        >
                        <TableCell component="th" scope="row">
                            {se.samplingEventName}
                        </TableCell>
                        {options.map(op => {
                            return (
                                <TableCell align="center" key={op + '-scr'}>
                                    <Checkbox 
                                        style={{margin: '0px', padding: '0px'}}
                                        checked={visible ? visible === op : op === 'show'} 
                                        disabled={visible ? visible === op : op === 'show'}
                                        onChange={() => updateSamplingEventVisibility(op, se.samplingEventKey)}
                                    />                                        
                                </TableCell>
                            )
                        })}
                    </TableRow>
                )
            })}       
            </TableBody>
            </Table>
            </TableContainer>
        </>
    );

}

export default VisibleOnTable;
