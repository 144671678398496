import React from 'react';

// mui
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import Tooltip from '@mui/material/Tooltip';

// utils
import 'react-quill/dist/quill.snow.css';

const customLetter = {
  width: '18px',
  lineHeight: '18px',
  borderRadius: '50%',
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 'bold',
  border: '2.75px solid #000',
  marginRight: '10px',
  display: 'inline-block'
}


const DetailsCell = ({ row, formData }) => {

    const findSubContract = (ord) => {
      let subFound = false

      ord?.samples?.forEach(s => {
        s.activeTests?.forEach(at => {
          const foundTest = formData?.testing[at.type + 's']?.find(tt => tt.LIMS_ID === at.LIMS_ID);
          if (foundTest?.ASECTION?.toLowerCase().indexOf('sub') > -1) { subFound = true; }
        })
      })

      return subFound
    }
    const findPrep = (ord) => {
      let prepFound = false

      ord?.samples?.forEach(s => {
        // do something here determining if matrix requires prep
        const foundMatrix = formData?.references?.materials?.find(tt => tt.LIMS_ID === s.matrix);
        if (foundMatrix?.PREPARATION) { prepFound = true }
      })

      return prepFound
    }

    const findCooled = (ord) => {
      // this is really specific to CFL. Target this as one of many spots where we need generalization later on.
      const labUseInfo = ord?.signatures?.filter(s => s.type === 'receive' && s.staff === 'admin')?.sort((a,b) =>  new Date(b.dateTime) - new Date(a.dateTime))[0]
      return labUseInfo?.prelogStorage?.indexOf('R44') > -1 || labUseInfo?.prelogStorage?.indexOf('F44') > -1 || labUseInfo?.prelogStorage?.indexOf('R99') > -1 || labUseInfo?.prelogStorage?.indexOf('R19') > -1
    }

    return (
        <div style={{ display: 'flex'}}>
                    
        {row.imported ? 
          <Tooltip title="This submission has been marked as Imported to LIMS" arrow>
            <BeenhereIcon style={{ marginRight: '10px' }} /> 
          </Tooltip>
        : null}

        {row.samplesDelivery?.toLowerCase().indexOf('sampling') > -1 || row.samplesDelivery?.toLowerCase().indexOf('pick-up') > -1 ? 
          <Tooltip title="Sampling Event or Pick-Up Request" arrow>
            <LocalShippingIcon style={{ marginRight: '10px'}} />
          </Tooltip>
        : null}

        {findSubContract(row) ? 
          <Tooltip title="Subcontract Testing Detected" arrow>
            <div style={customLetter}>S</div> 
          </Tooltip>
        : null}

        {findPrep(row) ? 
          <Tooltip title="Preparation Detected" arrow>
            <div style={customLetter}>P</div> 
          </Tooltip>
        : null}

        {findCooled(row) ? 
          <Tooltip title="Sample container(s) are stored in R/F44" arrow>
            <AcUnitIcon />
          </Tooltip>
        : null}

        {row.submissionType === 'samplecontainerrequest' ? 
          <Tooltip title="Sample Container Request (SCR)" arrow>
            <div style={customLetter}>B</div> 
          </Tooltip>
        : null}

      </div>      
    )

}

export default DetailsCell;
