import React from 'react';

// mui
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

// utils
import "../../utils/styles/cocStyle.css"

const COCHeader = ({ userInput, SEOR, samplingMode, data }) => {

  return (
      <Grid container spacing={2}>

      {/* Render "non-COC" preview here */}

        {(userInput.serviceLine === "cannabis-sampling" || samplingMode) && (SEOR || !userInput.status || userInput.status === "submitted" || userInput.status === "samplingBalanceValidated") ? 
        <>

        <Grid item xs={2} >
          <img alt="cl-logo" src="/images/cfl-logo-color.jpg" style={{width: "100%"}} />
        </Grid>
        
        <Grid item xs={8}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}><Typography className="mainHeaderText" variant="p">Sampling Event</Typography></Grid>
            <Grid item xs={12} md={12}><Typography className="mainHeaderText" variant="p">Review of Submission</Typography></Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={2}>


          <Grid item xs={12} md={12}><Typography className="mainHeaderText" variant="p">{userInput.submissionID ? decodeURIComponent(userInput.submissionID) : null}</Typography></Grid>          
        </Grid>
      
      </>
      : 
      <>
        <Grid item xs={2} >
          <img alt="cl-logo" src="/images/cfl-logo-color.jpg" style={{width: "100%"}} />
        </Grid>
        
        <Grid item xs={8}>
          <Grid container spacing={0}>
            
            <Grid item xs={12} md={12}>
              <Typography className="mainHeaderText" variant="p">


                {userInput.serviceLine?.indexOf("cannabis") > -1 ? <span>Hemp & Cannabis</span> : null}
                {userInput.serviceLine?.indexOf("kratom") > -1 ? <span>Kratom</span> : null}
                {userInput.submissionType?.includes("samplingEvent") ? <span>{data.activeSamplingEvent?.modeName} Sampling</span> : null}

              </Typography>
              

            </Grid>



            <Grid item xs={12} md={12}><Typography className="mainHeaderText" variant="p">Chain of Custody</Typography></Grid>

          </Grid>
        </Grid>
        
        <Grid item xs={2}>
          <Grid item xs={12} md={12}><Typography className="mainHeaderText" variant="p">{userInput.submissionID ? decodeURIComponent(userInput.submissionID) : null}</Typography></Grid>          
        </Grid>
        
        </> 
      
      }
         
         
      </Grid>
    
    
  );
}

export default COCHeader;
