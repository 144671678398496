import React, { useEffect } from 'react';
import ReactJson from 'react-json-view'
import { cloneDeep } from 'lodash';

// components
import AdminLogin from './Admin/Standard/AdminLogin';

// mui
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

// utils
import FormAPI from '../utils/API/api-form';
import APIQueries from '../utils/API/api-queries';

const Developer = () => {
    
    const [loading, setLoading] = React.useState(false)
    const [permission, setPermission] = React.useState(false)
    const [jsonData, setJsonData] = React.useState(false)

    useEffect(() => {
      const localPermission = JSON.parse(localStorage.getItem('developer'))
      if (localPermission?.permission) {
        if (localPermission.date === new Date().getDate()) {
          setPermission(true)
        } else {
          localStorage.removeItem('developer');
        }
        
      }
      
    }, [permission])



    return (
    <>   
        {loading ? 
        
        "Loading" 
        
        : 
        
        <>

          {permission ?           
            <>
              <h1>Welcome to the very cool page.</h1>
              <iframe src="https://giphy.com/embed/MFlnQT0rT6Gzajf4FY" className="giphy-embed" allowFullScreen></iframe>
              <iframe src="https://giphy.com/embed/QuxqWk7m9ffxyfoa0a" className="giphy-embed" allowFullScreen></iframe>
              <br />
              <Button
                variant="contained"
                color="success"
                onClick={async () => {
                  const response = await FormAPI.LIMSParamMet({labCode: '1301'})
                  setJsonData(response);
                }}
              >
                Update LIMS Data
              </Button> 
                <br /><br />
            <Button
              variant="contained"
              color="success"
              disabled={false}
              onClick={async () => {
                setJsonData(false)
                const resTesting = await FormAPI.ecocTesting({labCode: '1301'})
                const resRefs = await FormAPI.ecocReferences({labCode: '1301'})
                  const data = {
                    references: resRefs,
                    testing: resTesting
                  }
                  setJsonData(data)
              }}
            >
              Testing and References
            </Button>
            <Button
              variant="contained"
              color="success"
              disabled={false}
              onClick={async () => {
                setJsonData(false)
                const dataArr = [
                  {
                    "serviceLine": "environmental",
                    "type": "samplingEvent",
                    "confirmationIDHeader": "Confirmation ID:",
                    "introText": "Confirmation ID:",
                    "credentials": "cfl_email_environmental",
                    "status": "submitted",
                    "htmlBody": "cfl_template.html",
                    "from": "CustomerService.CFL@tentamus.com",
                    "cc": [""],
                    "bcc": ["erik.portillo@tentamus.com"],
                    "subject": "🏳 - Columbia Laboratories - Sampling Event Request Received",
                    "text": "",
                    "headerColor": "#1D6AAF",
                    "headerText": "Your environmental sampling request has been received!",
                    "bodyColor": "#c5c1c1",
                    "bodyText": "Thank you for submitting your sampling request to Columbia Laboratory, a Tentamus Company. You may review your sampling request summary by clicking the link below. If you would like to make any adjustments prior to your sampling appointment, please contact us by responding directly to this email.<br /><br />Our team will reach out to schedule your sampling appointment if one has not been previously established. You will receive a second email from our team once the sampling has been completed.<br /><br />Please ensure your site is accessible and ready for sampling at the time of your appointment.<br /><br />We look forward to satisfying your sampling needs.<br /><br />"
                  }
                ]
                dataArr.forEach(da => {
                  const action = FormAPI.putEmailTemplate({
                    emailTemplate: da
                  })
                  console.log(action)
                })
              }}
            >
              Do a thing
            </Button>
            <Button
              variant="contained"
              color="success"
              disabled={false}
              onClick={async () => {
                setJsonData(false)
                const resForm = await FormAPI.getForms({labCode: 'cfl', serviceLine: 'pws-environmental'})
                console.log(resForm)
                setJsonData(resForm)
              }}
            >
              Get Service Line
            </Button>
            <Button
              variant="contained"
              color="success"
              disabled={false}
              onClick={async () => {
                setJsonData(false)
                const data = {
                  "formID": "96c68783-5ec9-4d61-9ae4-2fdcfb49f992",
                  "labCode": "cfl",
                  "serviceLine": "emp",
                  "pageHeader": "Environmental Monitoring Sample Submission",
                  "bottlesMenu": true,
                  "createdAt": "2023-12-27T06:28:46.000Z",
                  "updatedAt": "2024-09-06T15:55:37.000Z",
                  "sections": [
                      {
                          "attachTests": [],
                          "disregardTests": [],
                          "triggerInputs": [],
                          "sectionID": "4ad068d9-c372-45d9-9620-67893dc2cef1",
                          "formID": "96c68783-5ec9-4d61-9ae4-2fdcfb49f992",
                          "type": "testing",
                          "labCode": "cfl",
                          "createdAt": "2023-12-27T06:28:47.000Z",
                          "updatedAt": "2024-09-06T15:55:37.000Z",
                          "testing_groups": [
                              {
                                  "individualAnalytes": [
                                      "0tkuH01MR~gA05401EU",
                                      "BpcuH01MR~gA05401EU",
                                      "M~ncx01b_RBK0E401L_",
                                      "j~kuH01MR~gA05401EU"
                                  ],
                                  "testing_groupID": "4786908e-a9d1-44ff-b9be-0d996c3d6d50",
                                  "order": 1,
                                  "sectionID": "4ad068d9-c372-45d9-9620-67893dc2cef1",
                                  "group": "Public Water Systems",
                                  "createdAt": "2024-02-12T21:48:44.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "tests": [
                                      {
                                          "testID": "b209d14e-6386-4aee-9a8a-991f3e9557c4",
                                          "testing_groupID": "4786908e-a9d1-44ff-b9be-0d996c3d6d50",
                                          "type": "parameterScope",
                                          "LIMS_ID": "0pCOF01bdOrE0BV01L_",
                                          "LIMS_NUMBER": "1133",
                                          "order": 0,
                                          "createdAt": "2024-02-13T19:51:35.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "ab3c5a55-d6b6-4460-9e71-372c58baa51d",
                                          "testing_groupID": "4786908e-a9d1-44ff-b9be-0d996c3d6d50",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "M~ncx01b_RBK0E401L_",
                                          "LIMS_NUMBER": "040941-0004822",
                                          "order": 1,
                                          "createdAt": "2024-03-28T21:07:06.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "65b7844f-d6ad-423b-9578-f21afba7a8f2",
                                          "testing_groupID": "4786908e-a9d1-44ff-b9be-0d996c3d6d50",
                                          "type": "parameterScope",
                                          "LIMS_ID": "4HZfh11bB_Yg0Dc01L_",
                                          "LIMS_NUMBER": "1108",
                                          "order": 2,
                                          "createdAt": "2024-02-12T21:59:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "8cbeb7b9-1d20-4ed2-b96c-ab898479ef24",
                                          "testing_groupID": "4786908e-a9d1-44ff-b9be-0d996c3d6d50",
                                          "type": "parameterScope",
                                          "LIMS_ID": "8MA9M01MuWME07701EU",
                                          "LIMS_NUMBER": "0189",
                                          "order": 3,
                                          "createdAt": "2024-02-12T22:24:51.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "4c5070ff-8dc5-447f-a508-25168131d606",
                                          "testing_groupID": "4786908e-a9d1-44ff-b9be-0d996c3d6d50",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "j~kuH01MR~gA05401EU",
                                          "LIMS_NUMBER": "026153-0004843",
                                          "column": true,
                                          "order": 4,
                                          "createdAt": "2024-03-26T23:36:21.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "0ceae22d-441e-4dd3-b1da-3b0672c2ccc1",
                                          "testing_groupID": "4786908e-a9d1-44ff-b9be-0d996c3d6d50",
                                          "type": "parameterScope",
                                          "LIMS_ID": "lquKT01QZQyJ0CK01EU",
                                          "LIMS_NUMBER": "0367",
                                          "order": 5,
                                          "createdAt": "2024-02-12T22:24:51.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "ddff581e-0d4b-41d3-8296-d9431036dbdb",
                                          "testing_groupID": "4786908e-a9d1-44ff-b9be-0d996c3d6d50",
                                          "type": "parameterScope",
                                          "LIMS_ID": "IHtnP01VJdEe09P01L_",
                                          "LIMS_NUMBER": "0731",
                                          "order": 6,
                                          "createdAt": "2024-03-28T20:53:17.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "a336328a-c1f2-42d7-a2e0-35cce97e7cb6",
                                          "testing_groupID": "4786908e-a9d1-44ff-b9be-0d996c3d6d50",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "0tkuH01MR~gA05401EU",
                                          "LIMS_NUMBER": "026402-0004825",
                                          "order": 7,
                                          "createdAt": "2024-05-02T18:12:23.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "bdd69bd5-79a3-4581-961f-e9135f1addf9",
                                          "testing_groupID": "4786908e-a9d1-44ff-b9be-0d996c3d6d50",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "xHduH01MR~gA05401EU",
                                          "LIMS_NUMBER": "022002-0000407",
                                          "column": true,
                                          "order": 8,
                                          "createdAt": "2024-05-02T18:18:41.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      }
                                  ]
                              },
                              {
                                  "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                  "order": 2,
                                  "sectionID": "4ad068d9-c372-45d9-9620-67893dc2cef1",
                                  "group": "clientTesting",
                                  "createdAt": "2024-02-04T18:29:15.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "tests": [
                                      {
                                          "testID": "0a122cbf-0e14-4c8e-a0a8-e5551c38ff47",
                                          "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                          "type": "spectra",
                                          "LIMS_ID": "ZqVhf11aPvyq0Em01L_",
                                          "LIMS_NUMBER": "ZqVhf11aPvyq0Em01L_",
                                          "createdAt": "2024-02-04T18:29:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "04ce4556-723f-4274-b863-22d0fba13f0c",
                                          "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                          "type": "parameterScope",
                                          "LIMS_ID": "5ofkc11X91VL02k01L_",
                                          "LIMS_NUMBER": "0832",
                                          "createdAt": "2024-02-04T18:29:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "c871c91d-6007-4142-ba75-6c78ab567cad",
                                          "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                          "type": "spectra",
                                          "LIMS_ID": "_WAx201bFypl09V01L_",
                                          "LIMS_NUMBER": "_WAx201bFypl09V01L_",
                                          "createdAt": "2024-02-04T18:29:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "bcc7e4ef-e242-47cc-8aa7-1409886bd54c",
                                          "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                          "type": "parameterScope",
                                          "LIMS_ID": "tE3Jf11YthIA09J01L_",
                                          "LIMS_NUMBER": "0929",
                                          "createdAt": "2024-02-04T18:29:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "ab7547e5-5c72-4de8-ba50-9970d52e823f",
                                          "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                          "type": "spectra",
                                          "LIMS_ID": "OIZmd01ZLh3b07v01L_",
                                          "LIMS_NUMBER": "OIZmd01ZLh3b07v01L_",
                                          "createdAt": "2024-02-04T18:29:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "91670641-6230-4cc2-9cb4-5e203094dbfb",
                                          "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                          "type": "spectra",
                                          "LIMS_ID": "jl7Gc11bAOBA0C101L_",
                                          "LIMS_NUMBER": "jl7Gc11bAOBA0C101L_",
                                          "createdAt": "2024-02-04T18:29:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "5ef7d9e1-1a63-452d-9cb0-1c7830f21ee9",
                                          "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                          "type": "parameterScope",
                                          "LIMS_ID": "QF3Jf11YthIA09J01L_",
                                          "LIMS_NUMBER": "0930",
                                          "createdAt": "2024-02-04T18:29:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "592a7415-3ebe-42c4-9c15-c7a3a7b36c31",
                                          "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                          "type": "parameterScope",
                                          "LIMS_ID": "odI6W01aao4r0BQ01L_",
                                          "LIMS_NUMBER": "1056",
                                          "createdAt": "2024-02-04T18:29:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "2c4471d1-17a8-4427-bf41-32ac21e4105a",
                                          "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                          "type": "parameterScope",
                                          "LIMS_ID": "i5y4l11aYTtF01Y01L_",
                                          "LIMS_NUMBER": "1050",
                                          "createdAt": "2024-02-04T18:29:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "1dcb2af6-fa87-4516-a591-40633a65974a",
                                          "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                          "type": "parameterScope",
                                          "LIMS_ID": "TlAbA01SBZYT0Dd01EU",
                                          "LIMS_NUMBER": "0607",
                                          "createdAt": "2024-02-04T18:29:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "ccdfc6b1-90da-4543-b110-2fac91605d60",
                                          "testing_groupID": "46bfc20d-b62a-4f7e-87e6-63c836e98fad",
                                          "type": "parameterScope",
                                          "LIMS_ID": "Mlr7Q11adQPC05E01L_",
                                          "LIMS_NUMBER": "1066",
                                          "createdAt": "2024-02-04T18:29:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      }
                                  ]
                              },
                              {
                                  "testing_groupID": "6c3f5529-72b7-4532-bd7e-9c533e857b57",
                                  "order": 3,
                                  "sectionID": "4ad068d9-c372-45d9-9620-67893dc2cef1",
                                  "group": "Microbiology ",
                                  "createdAt": "2023-12-27T06:28:47.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "tests": [
                                      {
                                          "testID": "6236200a-3a32-488c-b2c9-d0e2712d0ccd",
                                          "testing_groupID": "6c3f5529-72b7-4532-bd7e-9c533e857b57",
                                          "type": "parameterScope",
                                          "LIMS_ID": "0pCOF01bdOrE0BV01L_",
                                          "LIMS_NUMBER": "1133",
                                          "order": 0,
                                          "createdAt": "2024-01-10T17:17:50.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "598e1829-43af-419b-b14c-43b65d0e5462",
                                          "testing_groupID": "6c3f5529-72b7-4532-bd7e-9c533e857b57",
                                          "type": "parameterScope",
                                          "LIMS_ID": "_cbll01QqCsy0DS01EU",
                                          "LIMS_NUMBER": "0476",
                                          "order": 1,
                                          "createdAt": "2024-02-01T01:03:08.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "8f60716b-b55a-41dd-8ff3-8c0fe5776b55",
                                          "testing_groupID": "6c3f5529-72b7-4532-bd7e-9c533e857b57",
                                          "type": "parameterScope",
                                          "LIMS_ID": "D6Zhf11bHH1Y01601L_",
                                          "LIMS_NUMBER": "1117",
                                          "order": 2,
                                          "createdAt": "2024-02-01T01:03:08.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "f7c0b9c5-ec63-4e4c-b45b-7daacaedabb8",
                                          "testing_groupID": "6c3f5529-72b7-4532-bd7e-9c533e857b57",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "eIEVU01N6EgT0D001EU",
                                          "LIMS_NUMBER": "026338-0005554",
                                          "order": 3,
                                          "createdAt": "2024-01-11T23:47:10.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "a80ab7fe-31a8-40b4-bfc3-a6ed373e48f5",
                                          "testing_groupID": "6c3f5529-72b7-4532-bd7e-9c533e857b57",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "TGIUM01X9dlB06C01L_",
                                          "LIMS_NUMBER": "039275-0000889",
                                          "order": 4,
                                          "createdAt": "2024-01-03T22:52:22.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      }
                                  ]
                              },
                              {
                                  "individualAnalytes": [
                                      "qZEv201RppJX08L01EU",
                                      "5y2N001Rro3l0Ch01EU",
                                      "RXfuH01MR~gA05401EU"
                                  ],
                                  "testing_groupID": "7ab5c502-10b2-4a1c-bdcd-5df887e7f01a",
                                  "order": 4,
                                  "sectionID": "4ad068d9-c372-45d9-9620-67893dc2cef1",
                                  "group": "Metals & Minerals",
                                  "individualAnalytesName": "Radioactive Metals",
                                  "createdAt": "2024-01-03T19:43:01.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "tests": [
                                      {
                                          "testID": "0c7df6f5-c396-4908-b7f3-b1761cbd2048",
                                          "testing_groupID": "7ab5c502-10b2-4a1c-bdcd-5df887e7f01a",
                                          "type": "parameterScope",
                                          "LIMS_ID": "Q4MIr01Mu7F609b01EU",
                                          "LIMS_NUMBER": "0168",
                                          "createdAt": "2024-05-02T17:52:31.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "fffe5f76-8ff4-47fb-9ec7-9e8518635dfc",
                                          "testing_groupID": "7ab5c502-10b2-4a1c-bdcd-5df887e7f01a",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "~djEr01N5aWz02E01EU",
                                          "LIMS_NUMBER": "027055-0000495",
                                          "createdAt": "2024-02-01T01:12:00.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "c4439783-a691-46b5-94e5-ad9929b257f2",
                                          "testing_groupID": "7ab5c502-10b2-4a1c-bdcd-5df887e7f01a",
                                          "type": "parameterScope",
                                          "LIMS_ID": "1oLhh11Qy6eB00Q01EU",
                                          "LIMS_NUMBER": "0517",
                                          "createdAt": "2024-05-02T18:29:26.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "ae9e2c86-a688-4f43-9afc-ef8201e02e3d",
                                          "testing_groupID": "7ab5c502-10b2-4a1c-bdcd-5df887e7f01a",
                                          "type": "parameterScope",
                                          "LIMS_ID": "pCMIr01Mu7F609b01EU",
                                          "LIMS_NUMBER": "0177",
                                          "createdAt": "2024-01-31T19:00:29.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "ae4935e6-2a66-438e-91ba-3f23b2efea0a",
                                          "testing_groupID": "7ab5c502-10b2-4a1c-bdcd-5df887e7f01a",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "WJ3Os11Xw4BN02k01L_",
                                          "LIMS_NUMBER": "027259-0001183",
                                          "createdAt": "2024-07-10T22:18:42.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "65e2e212-ca57-46f5-9f2d-ec4dd82136a2",
                                          "testing_groupID": "7ab5c502-10b2-4a1c-bdcd-5df887e7f01a",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "DL3Os11Xw4BN02k01L_",
                                          "LIMS_NUMBER": "019943-0001183",
                                          "createdAt": "2024-07-10T22:18:42.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "558847ac-8a3c-42fb-b953-ff1ec172a0b6",
                                          "testing_groupID": "7ab5c502-10b2-4a1c-bdcd-5df887e7f01a",
                                          "type": "parameterScope",
                                          "LIMS_ID": "cOBh711MsCY800301EU",
                                          "LIMS_NUMBER": "0162",
                                          "createdAt": "2024-01-31T19:00:29.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "467b2f0e-a64b-4b1b-99b3-20fc5f76b5bd",
                                          "testing_groupID": "7ab5c502-10b2-4a1c-bdcd-5df887e7f01a",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "zE3Os11Xw4BN02k01L_",
                                          "LIMS_NUMBER": "027062-0001183",
                                          "createdAt": "2024-02-01T01:12:00.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "1ff1b26d-c36e-4d40-99cc-690c2b047ec7",
                                          "testing_groupID": "7ab5c502-10b2-4a1c-bdcd-5df887e7f01a",
                                          "type": "parameterScope",
                                          "LIMS_ID": "C128O11MuRwR05Y01EU",
                                          "LIMS_NUMBER": "0181",
                                          "createdAt": "2024-01-31T19:00:29.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "76db6d76-6274-48ee-9b97-d93b22d25ccb",
                                          "testing_groupID": "7ab5c502-10b2-4a1c-bdcd-5df887e7f01a",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "IzQbk01OJiYb04J01EU",
                                          "LIMS_NUMBER": "027055-0000444",
                                          "createdAt": "2024-02-01T01:12:00.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      }
                                  ]
                              },
                              {
                                  "testing_groupID": "8210f93d-717d-4d19-80c7-5256f4860b1d",
                                  "order": 5,
                                  "sectionID": "4ad068d9-c372-45d9-9620-67893dc2cef1",
                                  "group": "Analytical Chemistry ",
                                  "createdAt": "2023-12-27T06:28:47.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "tests": [
                                      {
                                          "testID": "fc51e76f-c4cd-4501-a192-b58a87f883df",
                                          "testing_groupID": "8210f93d-717d-4d19-80c7-5256f4860b1d",
                                          "type": "parameterScope",
                                          "LIMS_ID": "BJMIr01Mu7F609b01EU",
                                          "LIMS_NUMBER": "0180",
                                          "column": true,
                                          "createdAt": "2024-05-02T18:28:36.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z"
                                      },
                                      {
                                          "testID": "8950eb89-4989-4b96-b3d5-d540c9d4e562",
                                          "testing_groupID": "8210f93d-717d-4d19-80c7-5256f4860b1d",
                                          "type": "parameterScope",
                                          "LIMS_ID": "IHtnP01VJdEe09P01L_",
                                          "LIMS_NUMBER": "0731",
                                          "column": true,
                                          "createdAt": "2024-09-05T00:11:55.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z"
                                      },
                                      {
                                          "testID": "c0084fe3-c05d-4e48-a231-8182d4a4b2f8",
                                          "testing_groupID": "8210f93d-717d-4d19-80c7-5256f4860b1d",
                                          "type": "parameterScope",
                                          "LIMS_ID": "l628O11MuRwR05Y01EU",
                                          "LIMS_NUMBER": "0182",
                                          "column": true,
                                          "order": 0,
                                          "createdAt": "2024-01-10T17:09:50.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z"
                                      },
                                      {
                                          "testID": "9f0e0777-28dd-463d-8e37-e21f31c1b502",
                                          "testing_groupID": "8210f93d-717d-4d19-80c7-5256f4860b1d",
                                          "type": "parameterScope",
                                          "LIMS_ID": "4HZfh11bB_Yg0Dc01L_",
                                          "LIMS_NUMBER": "1108",
                                          "order": 1,
                                          "createdAt": "2024-01-03T18:59:01.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z"
                                      },
                                      {
                                          "testID": "1f066721-8f1d-460e-8c95-4d9bc8a6f410",
                                          "testing_groupID": "8210f93d-717d-4d19-80c7-5256f4860b1d",
                                          "type": "parameterScope",
                                          "LIMS_ID": "kkbrs01R5igL03C01EU",
                                          "LIMS_NUMBER": "0542",
                                          "order": 4,
                                          "createdAt": "2024-01-03T18:59:01.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z"
                                      },
                                      {
                                          "testID": "24c327ba-bbd6-4ef3-a82b-a025e639d548",
                                          "testing_groupID": "8210f93d-717d-4d19-80c7-5256f4860b1d",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "1T0Cg11PE1Pw0FX01EU",
                                          "LIMS_NUMBER": "026257-0005677",
                                          "order": 6,
                                          "createdAt": "2024-01-03T18:59:01.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z"
                                      },
                                      {
                                          "testID": "78833817-e772-4b3d-8fbe-8abda31e9199",
                                          "testing_groupID": "8210f93d-717d-4d19-80c7-5256f4860b1d",
                                          "type": "parameterScope",
                                          "LIMS_ID": "Nfbtr11NAsAv03Z01EU",
                                          "LIMS_NUMBER": "0211",
                                          "column": true,
                                          "order": 9,
                                          "createdAt": "2024-01-03T18:59:01.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z"
                                      },
                                      {
                                          "testID": "0f61dbb6-78cd-4982-8132-b6013a19bb4a",
                                          "testing_groupID": "8210f93d-717d-4d19-80c7-5256f4860b1d",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "yrkuH01MR~gA05401EU",
                                          "LIMS_NUMBER": "026248-0004822",
                                          "order": 10,
                                          "createdAt": "2024-02-13T23:35:26.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z"
                                      }
                                  ]
                              },
                              {
                                  "testing_groupID": "b86be84a-095d-402b-a922-3f6994ad4d32",
                                  "order": 7,
                                  "sectionID": "4ad068d9-c372-45d9-9620-67893dc2cef1",
                                  "group": "Field ",
                                  "createdAt": "2024-01-10T16:55:28.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "tests": [
                                      {
                                          "testID": "23434fc3-0060-46cb-9235-415b624c59dc",
                                          "testing_groupID": "b86be84a-095d-402b-a922-3f6994ad4d32",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "fWgss11aBOt_0CP01L_",
                                          "LIMS_NUMBER": "040632-0005355",
                                          "createdAt": "2024-01-10T17:09:50.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "24c03d5d-0d4c-4857-a800-21657a398fbd",
                                          "testing_groupID": "b86be84a-095d-402b-a922-3f6994ad4d32",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "Uxy8s11QUVfl0Cx01EU",
                                          "LIMS_NUMBER": "032355-0005355",
                                          "createdAt": "2024-01-10T17:09:50.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "47f1cedc-59c1-45d6-b872-5eda660e020d",
                                          "testing_groupID": "b86be84a-095d-402b-a922-3f6994ad4d32",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "NjkuH01MR~gA05401EU",
                                          "LIMS_NUMBER": "025812-0005355",
                                          "createdAt": "2024-01-10T17:09:50.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "51a92b82-c792-4e52-a966-249072d760e1",
                                          "testing_groupID": "b86be84a-095d-402b-a922-3f6994ad4d32",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "2XTVb01b91yZ0DU01L_",
                                          "LIMS_NUMBER": "040632-0008655",
                                          "createdAt": "2024-01-10T17:09:50.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "6d695e5b-4e9d-4665-ae33-7d97a8bf864c",
                                          "testing_groupID": "b86be84a-095d-402b-a922-3f6994ad4d32",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "Tc37h11Xvkh201j01L_",
                                          "LIMS_NUMBER": "039546-0005355",
                                          "createdAt": "2024-01-10T17:09:50.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "8f393f32-f5aa-4d99-9895-f1943a561f02",
                                          "testing_groupID": "b86be84a-095d-402b-a922-3f6994ad4d32",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "mb37h11Xvkh201j01L_",
                                          "LIMS_NUMBER": "039545-0005355",
                                          "createdAt": "2024-01-10T17:09:50.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "testID": "cb39f1b9-a8cc-4b47-b7b6-798b19e564ff",
                                          "testing_groupID": "b86be84a-095d-402b-a922-3f6994ad4d32",
                                          "type": "parameterMethod",
                                          "LIMS_ID": "~~kuH01MR~gA05401EU",
                                          "LIMS_NUMBER": "026171-0004843",
                                          "createdAt": "2024-01-10T17:09:50.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      }
                                  ]
                              }
                          ],
                          "sub_sections": []
                      },
                      {
                          "attachTests": [],
                          "disregardTests": [],
                          "triggerInputs": [],
                          "sectionID": "90438b79-28cc-4eb8-9784-aca6305dee9d",
                          "formID": "96c68783-5ec9-4d61-9ae4-2fdcfb49f992",
                          "type": "client",
                          "labCode": "cfl",
                          "createdAt": "2023-12-27T06:28:47.000Z",
                          "updatedAt": "2024-09-06T15:55:37.000Z",
                          "testing_groups": [],
                          "sub_sections": [
                              {
                                  "requirements": [],
                                  "sub_sectionID": "c9236826-6b7c-49e3-bb6b-f75957954f82",
                                  "sectionID": "90438b79-28cc-4eb8-9784-aca6305dee9d",
                                  "labCode": "cfl",
                                  "sectionKey": "companyContactInformation",
                                  "order": 1,
                                  "header": "Company Details",
                                  "icon": "AccountCircleIcon",
                                  "useAsAbove": false,
                                  "createdAt": "2023-12-27T06:28:47.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "inputs": [
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "502c222b-d21a-4d4b-beb2-55b68a482fca",
                                          "sub_sectionID": "c9236826-6b7c-49e3-bb6b-f75957954f82",
                                          "labCode": "cfl",
                                          "order": 1,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Public Water System Name",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 8,
                                          "input_key": "companyName",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "3d0f3f59-9b20-4391-b84f-d25e11ce009c",
                                              "inputID": "502c222b-d21a-4d4b-beb2-55b68a482fca",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "576590c3-d50f-4a2a-99d5-8d4563be3562",
                                          "sub_sectionID": "c9236826-6b7c-49e3-bb6b-f75957954f82",
                                          "labCode": "cfl",
                                          "order": 2,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Company Phone",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 4,
                                          "input_key": "companyPhone",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "fd518d47-17c4-429e-a003-e7f71e2fe5be",
                                              "inputID": "576590c3-d50f-4a2a-99d5-8d4563be3562",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "validation": {
                                              "validationID": "f378b298-5354-42fb-8165-248b9c966d23",
                                              "inputID": "576590c3-d50f-4a2a-99d5-8d4563be3562",
                                              "label": "Phone Number",
                                              "type": "isMobilePhone",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "7753f3c8-87fb-49a2-99ab-d5f2168fa553",
                                          "sub_sectionID": "c9236826-6b7c-49e3-bb6b-f75957954f82",
                                          "labCode": "cfl",
                                          "order": 3,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "PWS ID ",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "pwsID",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-14T01:01:56.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "2c02a385-04ad-4503-9cb9-b71d3cef4356",
                                              "inputID": "7753f3c8-87fb-49a2-99ab-d5f2168fa553",
                                              "key": "1",
                                              "createdAt": "2024-04-19T16:46:29.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "56812f44-1672-4bf8-8e3e-ce4a82a8eeb9",
                                          "sub_sectionID": "c9236826-6b7c-49e3-bb6b-f75957954f82",
                                          "labCode": "cfl",
                                          "order": 4,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Address Line 1",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "companyAddress1",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "9dd09a3b-8d4d-4bf8-8f69-b37f227a0ef9",
                                              "inputID": "56812f44-1672-4bf8-8e3e-ce4a82a8eeb9",
                                              "key": "1",
                                              "createdAt": "2024-04-19T16:46:29.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "2bee9808-f459-4be6-903d-e7b87f86f18e",
                                          "sub_sectionID": "c9236826-6b7c-49e3-bb6b-f75957954f82",
                                          "labCode": "cfl",
                                          "order": 5,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Address Line 2",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "companyAddress2",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "a6fa5f27-7169-4b88-9310-b3d3569dd1c3",
                                          "sub_sectionID": "c9236826-6b7c-49e3-bb6b-f75957954f82",
                                          "labCode": "cfl",
                                          "order": 6,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "City",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "companyCity",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "d5cd7e3f-9e90-4bd1-85e8-69c0b001234e",
                                              "inputID": "a6fa5f27-7169-4b88-9310-b3d3569dd1c3",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "2d6a0141-132f-468f-818c-bc58ee0812f4",
                                          "sub_sectionID": "c9236826-6b7c-49e3-bb6b-f75957954f82",
                                          "labCode": "cfl",
                                          "order": 7,
                                          "samplingInput": false,
                                          "type": "select",
                                          "label": "State",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 6,
                                          "md": 3,
                                          "input_key": "companyState",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "selection": {
                                              "value": [
                                                  "pVTQT11M7rCC01R01EU",
                                                  "sVTQT11M7rCC01R01EU",
                                                  "vVTQT11M7rCC01R01EU",
                                                  "yVTQT11M7rCC01R01EU",
                                                  "9IZ~D01NyygG0A501EU",
                                                  "2WTQT11M7rCC01R01EU",
                                                  "5WTQT11M7rCC01R01EU",
                                                  "9WTQT11M7rCC01R01EU",
                                                  "DWTQT11M7rCC01R01EU",
                                                  "GWTQT11M7rCC01R01EU",
                                                  "KWTQT11M7rCC01R01EU",
                                                  "NWTQT11M7rCC01R01EU",
                                                  "QWTQT11M7rCC01R01EU",
                                                  "TWTQT11M7rCC01R01EU",
                                                  "WWTQT11M7rCC01R01EU",
                                                  "ZWTQT11M7rCC01R01EU",
                                                  "cWTQT11M7rCC01R01EU",
                                                  "fWTQT11M7rCC01R01EU",
                                                  "iWTQT11M7rCC01R01EU",
                                                  "lWTQT11M7rCC01R01EU",
                                                  "oWTQT11M7rCC01R01EU",
                                                  "rWTQT11M7rCC01R01EU",
                                                  "uWTQT11M7rCC01R01EU",
                                                  "xWTQT11M7rCC01R01EU",
                                                  "~WTQT11M7rCC01R01EU",
                                                  "1XTQT11M7rCC01R01EU",
                                                  "4XTQT11M7rCC01R01EU",
                                                  "7XTQT11M7rCC01R01EU",
                                                  "BXTQT11M7rCC01R01EU",
                                                  "FXTQT11M7rCC01R01EU",
                                                  "IXTQT11M7rCC01R01EU",
                                                  "MXTQT11M7rCC01R01EU",
                                                  "PXTQT11M7rCC01R01EU",
                                                  "SXTQT11M7rCC01R01EU",
                                                  "VXTQT11M7rCC01R01EU",
                                                  "YXTQT11M7rCC01R01EU",
                                                  "bXTQT11M7rCC01R01EU",
                                                  "fXTQT11M7rCC01R01EU",
                                                  "iXTQT11M7rCC01R01EU",
                                                  "lXTQT11M7rCC01R01EU",
                                                  "pXTQT11M7rCC01R01EU",
                                                  "sXTQT11M7rCC01R01EU",
                                                  "vXTQT11M7rCC01R01EU",
                                                  "yXTQT11M7rCC01R01EU",
                                                  "_XTQT11M7rCC01R01EU",
                                                  "2YTQT11M7rCC01R01EU",
                                                  "6YTQT11M7rCC01R01EU",
                                                  "9YTQT11M7rCC01R01EU",
                                                  "CYTQT11M7rCC01R01EU",
                                                  "FYTQT11M7rCC01R01EU"
                                              ],
                                              "selectionID": "41870b37-7a48-441a-aabb-834f4ac3917a",
                                              "inputID": "2d6a0141-132f-468f-818c-bc58ee0812f4",
                                              "source": "states",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "required": {
                                              "value": [],
                                              "requiredID": "50ef555b-5930-45a0-a5df-5b10b14c0d24",
                                              "inputID": "2d6a0141-132f-468f-818c-bc58ee0812f4",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "525f2035-111f-4e50-8585-32c7e37e516f",
                                          "sub_sectionID": "c9236826-6b7c-49e3-bb6b-f75957954f82",
                                          "labCode": "cfl",
                                          "order": 8,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "ZIP Code",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 6,
                                          "md": 3,
                                          "input_key": "companyZip",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "3d404546-0eaa-4ce1-a386-afc63ed0c34b",
                                              "inputID": "525f2035-111f-4e50-8585-32c7e37e516f",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "f18c104a-3611-4b71-baf1-8822d66c2f82",
                                          "sub_sectionID": "c9236826-6b7c-49e3-bb6b-f75957954f82",
                                          "labCode": "cfl",
                                          "order": 9,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Passkey",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "passKey",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-05T17:58:17.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      }
                                  ]
                              },
                              {
                                  "requirements": [],
                                  "sub_sectionID": "6585a6b1-0406-4f98-8612-a3ffdae74131",
                                  "sectionID": "90438b79-28cc-4eb8-9784-aca6305dee9d",
                                  "labCode": "cfl",
                                  "sectionKey": "contactInformation",
                                  "order": 2,
                                  "header": "Contact Information",
                                  "icon": "AccountCircleIcon",
                                  "useAsAbove": false,
                                  "createdAt": "2023-12-27T06:28:47.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "inputs": [
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "66da84dc-9bed-4c71-a093-595baf0b49c0",
                                          "sub_sectionID": "6585a6b1-0406-4f98-8612-a3ffdae74131",
                                          "labCode": "cfl",
                                          "order": 1,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "First Name",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 6,
                                          "md": 4,
                                          "input_key": "contactFirstName",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "bd949b3c-4936-457b-8e9b-78f743fd24e9",
                                              "inputID": "66da84dc-9bed-4c71-a093-595baf0b49c0",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "1a8c9fd0-8870-45d7-bf1e-3bad4a18f7b5",
                                          "sub_sectionID": "6585a6b1-0406-4f98-8612-a3ffdae74131",
                                          "labCode": "cfl",
                                          "order": 2,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Last Name",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 6,
                                          "md": 4,
                                          "input_key": "contactLastName",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "8f718112-534f-40a9-9f07-fa877906b479",
                                              "inputID": "1a8c9fd0-8870-45d7-bf1e-3bad4a18f7b5",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "74147d9e-67e0-46bf-8202-99e03d095b94",
                                          "sub_sectionID": "6585a6b1-0406-4f98-8612-a3ffdae74131",
                                          "labCode": "cfl",
                                          "order": 3,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Phone",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 4,
                                          "input_key": "clientPhone",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "53b53c95-8ebd-4b95-941e-282471bba7f1",
                                              "inputID": "74147d9e-67e0-46bf-8202-99e03d095b94",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "validation": {
                                              "validationID": "470d22c6-38df-470a-9323-7a07236e78ad",
                                              "inputID": "74147d9e-67e0-46bf-8202-99e03d095b94",
                                              "label": "Phone Number",
                                              "type": "isMobilePhone",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "1589c84f-6696-4b28-9b17-28bafe0dd02f",
                                          "sub_sectionID": "6585a6b1-0406-4f98-8612-a3ffdae74131",
                                          "labCode": "cfl",
                                          "order": 4,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Email",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "clientEmail",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "2f7df041-5f8f-4d89-8f63-f59ff26f456c",
                                              "inputID": "1589c84f-6696-4b28-9b17-28bafe0dd02f",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "validation": {
                                              "validationID": "e23523b5-2428-40c5-ac0e-753038b31ac7",
                                              "inputID": "1589c84f-6696-4b28-9b17-28bafe0dd02f",
                                              "label": "Phone Number",
                                              "type": "isEmail",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "e5b365f3-add4-4414-8a5d-5eb9c78b5a58",
                                          "sub_sectionID": "6585a6b1-0406-4f98-8612-a3ffdae74131",
                                          "labCode": "cfl",
                                          "order": 5,
                                          "samplingInput": false,
                                          "type": "multiInput",
                                          "label": "CC Email(s)",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "clientEmailcc",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "4328d4b8-8455-49fa-94ac-7b669553b1db",
                                              "inputID": "e5b365f3-add4-4414-8a5d-5eb9c78b5a58",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      }
                                  ]
                              },
                              {
                                  "requirements": [],
                                  "sub_sectionID": "ffa7db5d-7d80-41c9-a116-8d2847a9734c",
                                  "sectionID": "90438b79-28cc-4eb8-9784-aca6305dee9d",
                                  "labCode": "cfl",
                                  "sectionKey": "billingLocationInformation",
                                  "order": 3,
                                  "header": "Billing Information",
                                  "icon": "MarkunreadMailboxIcon",
                                  "useAsAbove": true,
                                  "createdAt": "2023-12-27T06:28:47.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "inputs": [
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "834fac83-abd3-4299-8621-1fe4f1448d62",
                                          "sub_sectionID": "ffa7db5d-7d80-41c9-a116-8d2847a9734c",
                                          "labCode": "cfl",
                                          "order": 1,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Email",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "billingEmail",
                                          "mirrorKey": "clientEmail",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "07d75bda-bc2a-4a87-bd7a-556c796048b8",
                                              "inputID": "834fac83-abd3-4299-8621-1fe4f1448d62",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "validation": {
                                              "validationID": "989a1b5f-fb4a-4022-9550-8bba46521ef1",
                                              "inputID": "834fac83-abd3-4299-8621-1fe4f1448d62",
                                              "label": "Email",
                                              "type": "isEmail",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "63c3b9f2-fb65-4692-ab92-f1f28e3d54b9",
                                          "sub_sectionID": "ffa7db5d-7d80-41c9-a116-8d2847a9734c",
                                          "labCode": "cfl",
                                          "order": 2,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Contact Phone",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "billingContactPhone",
                                          "mirrorKey": "companyPhone",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "3e59294d-b4ed-4e1f-ac8e-a4a91ae889aa",
                                              "inputID": "63c3b9f2-fb65-4692-ab92-f1f28e3d54b9",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "validation": {
                                              "validationID": "c6c6451e-6e8c-4059-a580-4bc34e678d12",
                                              "inputID": "63c3b9f2-fb65-4692-ab92-f1f28e3d54b9",
                                              "label": "Phone Number",
                                              "type": "isMobilePhone",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      }
                                  ]
                              },
                              {
                                  "requirements": [],
                                  "sub_sectionID": "8f315608-306c-404d-a592-a420120b48ea",
                                  "sectionID": "90438b79-28cc-4eb8-9784-aca6305dee9d",
                                  "labCode": "cfl",
                                  "sectionKey": "testingParameters",
                                  "order": 4,
                                  "header": "Project Details",
                                  "icon": "FeedIcon",
                                  "useAsAbove": false,
                                  "createdAt": "2023-12-27T06:28:47.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "inputs": [
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "881b6826-8969-495f-afa0-701893306a87",
                                          "sub_sectionID": "8f315608-306c-404d-a592-a420120b48ea",
                                          "labCode": "cfl",
                                          "order": 1,
                                          "samplingInput": false,
                                          "type": "select",
                                          "label": "Turnaround Time",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "priority",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "selection": {
                                              "value": [
                                                  "2 Business Days | Surcharges Apply",
                                                  "3 Business Days | Surcharges Apply",
                                                  "5 Business Days | Standard for Micro Only | Surcharges May Apply",
                                                  "8 Business Days | Standard "
                                              ],
                                              "selectionID": "c4def2f5-bc1e-4a56-bf1f-add2693aa12f",
                                              "inputID": "881b6826-8969-495f-afa0-701893306a87",
                                              "source": "specified",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "required": {
                                              "value": [],
                                              "requiredID": "0a255be6-d864-412d-b6d5-90b9a7fb12cb",
                                              "inputID": "881b6826-8969-495f-afa0-701893306a87",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "41423571-6cfe-4583-9d66-83c49f9e8fe9",
                                          "sub_sectionID": "8f315608-306c-404d-a592-a420120b48ea",
                                          "labCode": "cfl",
                                          "order": 2,
                                          "samplingInput": false,
                                          "type": "select",
                                          "label": "Sample Relinquishment Options",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "samplesDelivery",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "selection": {
                                              "value": [
                                                  "Drop Off at Laboratory",
                                                  "By Shipping Service (USPS, UPS, Fedex)",
                                                  "Schedule Pick-Up",
                                                  "Sampling Event Request"
                                              ],
                                              "selectionID": "4e186ee7-b1ab-4701-b2f9-b020296ab52e",
                                              "inputID": "41423571-6cfe-4583-9d66-83c49f9e8fe9",
                                              "source": "specified",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "required": {
                                              "value": [],
                                              "requiredID": "3708019c-3978-4c3b-8b42-885f387e12b2",
                                              "inputID": "41423571-6cfe-4583-9d66-83c49f9e8fe9",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "6dd48dde-be0a-4d78-a2b6-43b06d810765",
                                          "sub_sectionID": "8f315608-306c-404d-a592-a420120b48ea",
                                          "labCode": "cfl",
                                          "order": 3,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Project Name / ID",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "projectName",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "264fb0d6-12da-4f5c-9926-1cb878f98ad0",
                                              "inputID": "6dd48dde-be0a-4d78-a2b6-43b06d810765",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "7eec8983-8eeb-4102-b9aa-6f1f86704c2f",
                                          "sub_sectionID": "8f315608-306c-404d-a592-a420120b48ea",
                                          "labCode": "cfl",
                                          "order": 4,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "PO Number",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "projectPONumber",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "72141c22-fc09-48b1-b3a8-8c627ed87d8b",
                                              "inputID": "7eec8983-8eeb-4102-b9aa-6f1f86704c2f",
                                              "createdAt": "2023-12-27T06:28:48.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "cf2d2806-44b5-4b48-9443-d7ce331ac5a6",
                                          "sub_sectionID": "8f315608-306c-404d-a592-a420120b48ea",
                                          "labCode": "cfl",
                                          "order": 5,
                                          "samplingInput": false,
                                          "type": "textArea",
                                          "label": "Additional Comments for Project",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "projectComments",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:47.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      }
                                  ]
                              },
                              {
                                  "requirements": [
                                      {
                                          "key": "samplesDelivery",
                                          "type": "specified",
                                          "value": "Sampling Event Request"
                                      }
                                  ],
                                  "sub_sectionID": "e6fec9d8-2075-435c-b937-079eba700d9d",
                                  "sectionID": "90438b79-28cc-4eb8-9784-aca6305dee9d",
                                  "labCode": "cfl",
                                  "sectionKey": "samplingLocationInformation",
                                  "order": 5,
                                  "header": "Sampling Event Details",
                                  "icon": "LocalShippingIcon",
                                  "useAsAbove": true,
                                  "createdAt": "2023-12-27T06:28:47.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "inputs": [
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "9e26e367-a9d5-422c-b3b0-e48b99150b7e",
                                          "sub_sectionID": "e6fec9d8-2075-435c-b937-079eba700d9d",
                                          "labCode": "cfl",
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Sampling Location Name",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 8,
                                          "input_key": "samplingContactName",
                                          "mirrorKey": "companyName",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "5c922b46-a1db-46b8-8b1c-04bfd4434b10",
                                              "inputID": "9e26e367-a9d5-422c-b3b0-e48b99150b7e",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "00591115-e1fc-40fa-b3f3-8bb8c18c0bd6",
                                          "sub_sectionID": "e6fec9d8-2075-435c-b937-079eba700d9d",
                                          "labCode": "cfl",
                                          "order": 1,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Contact Phone",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 4,
                                          "input_key": "samplingContactPhone",
                                          "mirrorKey": "companyPhone",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "d10203c6-2a14-4ad7-b526-b6661dc23c61",
                                              "inputID": "00591115-e1fc-40fa-b3f3-8bb8c18c0bd6",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "validation": {
                                              "validationID": "e324a7ef-4224-407c-a2d8-c028e94b12b0",
                                              "inputID": "00591115-e1fc-40fa-b3f3-8bb8c18c0bd6",
                                              "label": "Phone Number",
                                              "type": "isMobilePhone",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "019ced51-f9b6-484a-8f4a-f9057f7f1cf5",
                                          "sub_sectionID": "e6fec9d8-2075-435c-b937-079eba700d9d",
                                          "labCode": "cfl",
                                          "order": 2,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Address Line 1",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "samplingContactAddress1",
                                          "mirrorKey": "companyAddress1",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "312743db-ad9d-4774-88b0-da2664e35c96",
                                              "inputID": "019ced51-f9b6-484a-8f4a-f9057f7f1cf5",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "8bc911ed-17db-43da-b50d-5c950f6700ce",
                                          "sub_sectionID": "e6fec9d8-2075-435c-b937-079eba700d9d",
                                          "labCode": "cfl",
                                          "order": 3,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Address Line 2",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "samplingContactAddress2",
                                          "mirrorKey": "companyAddress2",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "11a7d9d7-f459-4aac-b0aa-8b78b3feccca",
                                              "inputID": "8bc911ed-17db-43da-b50d-5c950f6700ce",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "19ed46a8-e10c-44fe-b6a7-60c508af1bf3",
                                          "sub_sectionID": "e6fec9d8-2075-435c-b937-079eba700d9d",
                                          "labCode": "cfl",
                                          "order": 4,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "City",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "samplingContactCity",
                                          "mirrorKey": "companyCity",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "b8f5e1fc-73bf-4b3c-84e7-ec8faf1008ff",
                                              "inputID": "19ed46a8-e10c-44fe-b6a7-60c508af1bf3",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "bb7d1730-6bf8-4530-987a-cd7e9fbcd4e0",
                                          "sub_sectionID": "e6fec9d8-2075-435c-b937-079eba700d9d",
                                          "labCode": "cfl",
                                          "order": 5,
                                          "samplingInput": false,
                                          "type": "select",
                                          "label": "State",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 6,
                                          "md": 3,
                                          "input_key": "samplingContactState",
                                          "mirrorKey": "companyState",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "selection": {
                                              "value": [
                                                  "pVTQT11M7rCC01R01EU",
                                                  "sVTQT11M7rCC01R01EU",
                                                  "vVTQT11M7rCC01R01EU",
                                                  "yVTQT11M7rCC01R01EU",
                                                  "9IZ~D01NyygG0A501EU",
                                                  "2WTQT11M7rCC01R01EU",
                                                  "5WTQT11M7rCC01R01EU",
                                                  "9WTQT11M7rCC01R01EU",
                                                  "DWTQT11M7rCC01R01EU",
                                                  "GWTQT11M7rCC01R01EU",
                                                  "KWTQT11M7rCC01R01EU",
                                                  "NWTQT11M7rCC01R01EU",
                                                  "QWTQT11M7rCC01R01EU",
                                                  "TWTQT11M7rCC01R01EU",
                                                  "WWTQT11M7rCC01R01EU",
                                                  "ZWTQT11M7rCC01R01EU",
                                                  "cWTQT11M7rCC01R01EU",
                                                  "fWTQT11M7rCC01R01EU",
                                                  "iWTQT11M7rCC01R01EU",
                                                  "lWTQT11M7rCC01R01EU",
                                                  "oWTQT11M7rCC01R01EU",
                                                  "rWTQT11M7rCC01R01EU",
                                                  "uWTQT11M7rCC01R01EU",
                                                  "xWTQT11M7rCC01R01EU",
                                                  "~WTQT11M7rCC01R01EU",
                                                  "1XTQT11M7rCC01R01EU",
                                                  "4XTQT11M7rCC01R01EU",
                                                  "7XTQT11M7rCC01R01EU",
                                                  "BXTQT11M7rCC01R01EU",
                                                  "FXTQT11M7rCC01R01EU",
                                                  "IXTQT11M7rCC01R01EU",
                                                  "MXTQT11M7rCC01R01EU",
                                                  "PXTQT11M7rCC01R01EU",
                                                  "SXTQT11M7rCC01R01EU",
                                                  "VXTQT11M7rCC01R01EU",
                                                  "YXTQT11M7rCC01R01EU",
                                                  "bXTQT11M7rCC01R01EU",
                                                  "fXTQT11M7rCC01R01EU",
                                                  "iXTQT11M7rCC01R01EU",
                                                  "lXTQT11M7rCC01R01EU",
                                                  "pXTQT11M7rCC01R01EU",
                                                  "sXTQT11M7rCC01R01EU",
                                                  "vXTQT11M7rCC01R01EU",
                                                  "yXTQT11M7rCC01R01EU",
                                                  "_XTQT11M7rCC01R01EU",
                                                  "2YTQT11M7rCC01R01EU",
                                                  "6YTQT11M7rCC01R01EU",
                                                  "9YTQT11M7rCC01R01EU",
                                                  "CYTQT11M7rCC01R01EU",
                                                  "FYTQT11M7rCC01R01EU"
                                              ],
                                              "selectionID": "2664cc1d-d6fa-4aef-b658-79dbf99d6aee",
                                              "inputID": "bb7d1730-6bf8-4530-987a-cd7e9fbcd4e0",
                                              "source": "states",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "required": {
                                              "value": [],
                                              "requiredID": "2c57f601-f076-4b98-95cb-76e18a7f6a39",
                                              "inputID": "bb7d1730-6bf8-4530-987a-cd7e9fbcd4e0",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "5a561f09-ea04-46eb-96ce-2eed0b2125fd",
                                          "sub_sectionID": "e6fec9d8-2075-435c-b937-079eba700d9d",
                                          "labCode": "cfl",
                                          "order": 6,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "ZIP Code",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 6,
                                          "md": 3,
                                          "input_key": "samplingContactZip",
                                          "mirrorKey": "companyZip",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "418e86d7-3b77-4847-a5fc-e7d5e1d1fd78",
                                              "inputID": "5a561f09-ea04-46eb-96ce-2eed0b2125fd",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      }
                                  ]
                              },
                              {
                                  "requirements": [
                                      {
                                          "key": "samplesDelivery",
                                          "type": "specified",
                                          "value": "Schedule Pick-Up"
                                      }
                                  ],
                                  "sub_sectionID": "88319d47-d526-4b36-96a0-e2342383da51",
                                  "sectionID": "90438b79-28cc-4eb8-9784-aca6305dee9d",
                                  "labCode": "cfl",
                                  "sectionKey": "pickUpLocationInformation",
                                  "order": 6,
                                  "header": "Pick-Up Details",
                                  "icon": "LocalShippingIcon",
                                  "useAsAbove": true,
                                  "createdAt": "2023-12-27T06:28:47.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "inputs": [
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "9d9b5454-4799-4eea-9969-e37916bb93fc",
                                          "sub_sectionID": "88319d47-d526-4b36-96a0-e2342383da51",
                                          "labCode": "cfl",
                                          "order": 1,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Pick-Up Location Name",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 8,
                                          "input_key": "pickUpContactName",
                                          "mirrorKey": "companyName",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "ed27be8d-e6fe-415f-a352-1f21f225e793",
                                              "inputID": "9d9b5454-4799-4eea-9969-e37916bb93fc",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "b8ece822-5efd-432a-8fe2-8cec746adbfa",
                                          "sub_sectionID": "88319d47-d526-4b36-96a0-e2342383da51",
                                          "labCode": "cfl",
                                          "order": 2,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Contact Phone",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 4,
                                          "input_key": "pickUpContactPhone",
                                          "mirrorKey": "companyPhone",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "b4a8dafb-2cca-49f5-891b-842952eca89e",
                                              "inputID": "b8ece822-5efd-432a-8fe2-8cec746adbfa",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "validation": {
                                              "validationID": "8cbe317b-0942-4d3a-843e-f26ebe5ef741",
                                              "inputID": "b8ece822-5efd-432a-8fe2-8cec746adbfa",
                                              "label": "Phone Number",
                                              "type": "isMobilePhone",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "b2be0802-2bd1-4d11-b901-a5112d504f6d",
                                          "sub_sectionID": "88319d47-d526-4b36-96a0-e2342383da51",
                                          "labCode": "cfl",
                                          "order": 3,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Address Line 1",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "pickUpContactAddress1",
                                          "mirrorKey": "companyAddress1",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "b4b4b732-57e1-4974-95f2-934ae6dfca6c",
                                              "inputID": "b2be0802-2bd1-4d11-b901-a5112d504f6d",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "ead9e055-e603-421e-85fb-92d55adaa397",
                                          "sub_sectionID": "88319d47-d526-4b36-96a0-e2342383da51",
                                          "labCode": "cfl",
                                          "order": 4,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Address Line 2",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "pickUpContactAddress2",
                                          "mirrorKey": "companyAddress2",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "38256dda-4cb6-4266-a609-46bb131b1373",
                                              "inputID": "ead9e055-e603-421e-85fb-92d55adaa397",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "e24e42e9-a62c-474a-9e85-d0f871539502",
                                          "sub_sectionID": "88319d47-d526-4b36-96a0-e2342383da51",
                                          "labCode": "cfl",
                                          "order": 5,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "City",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "pickUpContactCity",
                                          "mirrorKey": "companyCity",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "29a847c6-a1e0-49fd-a0e0-cf159db71948",
                                              "inputID": "e24e42e9-a62c-474a-9e85-d0f871539502",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "6e694c4f-b2a5-4132-a109-fbb7caee56cc",
                                          "sub_sectionID": "88319d47-d526-4b36-96a0-e2342383da51",
                                          "labCode": "cfl",
                                          "order": 6,
                                          "samplingInput": false,
                                          "type": "select",
                                          "label": "State",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 6,
                                          "md": 3,
                                          "input_key": "pickUpContactState",
                                          "mirrorKey": "companyState",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "selection": {
                                              "value": [
                                                  "pVTQT11M7rCC01R01EU",
                                                  "sVTQT11M7rCC01R01EU",
                                                  "vVTQT11M7rCC01R01EU",
                                                  "yVTQT11M7rCC01R01EU",
                                                  "9IZ~D01NyygG0A501EU",
                                                  "2WTQT11M7rCC01R01EU",
                                                  "5WTQT11M7rCC01R01EU",
                                                  "9WTQT11M7rCC01R01EU",
                                                  "DWTQT11M7rCC01R01EU",
                                                  "GWTQT11M7rCC01R01EU",
                                                  "KWTQT11M7rCC01R01EU",
                                                  "NWTQT11M7rCC01R01EU",
                                                  "QWTQT11M7rCC01R01EU",
                                                  "TWTQT11M7rCC01R01EU",
                                                  "WWTQT11M7rCC01R01EU",
                                                  "ZWTQT11M7rCC01R01EU",
                                                  "cWTQT11M7rCC01R01EU",
                                                  "fWTQT11M7rCC01R01EU",
                                                  "iWTQT11M7rCC01R01EU",
                                                  "lWTQT11M7rCC01R01EU",
                                                  "oWTQT11M7rCC01R01EU",
                                                  "rWTQT11M7rCC01R01EU",
                                                  "uWTQT11M7rCC01R01EU",
                                                  "xWTQT11M7rCC01R01EU",
                                                  "~WTQT11M7rCC01R01EU",
                                                  "1XTQT11M7rCC01R01EU",
                                                  "4XTQT11M7rCC01R01EU",
                                                  "7XTQT11M7rCC01R01EU",
                                                  "BXTQT11M7rCC01R01EU",
                                                  "FXTQT11M7rCC01R01EU",
                                                  "IXTQT11M7rCC01R01EU",
                                                  "MXTQT11M7rCC01R01EU",
                                                  "PXTQT11M7rCC01R01EU",
                                                  "SXTQT11M7rCC01R01EU",
                                                  "VXTQT11M7rCC01R01EU",
                                                  "YXTQT11M7rCC01R01EU",
                                                  "bXTQT11M7rCC01R01EU",
                                                  "fXTQT11M7rCC01R01EU",
                                                  "iXTQT11M7rCC01R01EU",
                                                  "lXTQT11M7rCC01R01EU",
                                                  "pXTQT11M7rCC01R01EU",
                                                  "sXTQT11M7rCC01R01EU",
                                                  "vXTQT11M7rCC01R01EU",
                                                  "yXTQT11M7rCC01R01EU",
                                                  "_XTQT11M7rCC01R01EU",
                                                  "2YTQT11M7rCC01R01EU",
                                                  "6YTQT11M7rCC01R01EU",
                                                  "9YTQT11M7rCC01R01EU",
                                                  "CYTQT11M7rCC01R01EU",
                                                  "FYTQT11M7rCC01R01EU"
                                              ],
                                              "selectionID": "24156d5b-ab10-463f-b362-0b7c3702b2bc",
                                              "inputID": "6e694c4f-b2a5-4132-a109-fbb7caee56cc",
                                              "source": "states",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "required": {
                                              "value": [],
                                              "requiredID": "77058d91-00cd-4c33-b9b3-4a73f6999a9c",
                                              "inputID": "6e694c4f-b2a5-4132-a109-fbb7caee56cc",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "7ed66ff8-ae59-4056-b90c-5da3808e2118",
                                          "sub_sectionID": "88319d47-d526-4b36-96a0-e2342383da51",
                                          "labCode": "cfl",
                                          "order": 7,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "ZIP Code",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 6,
                                          "md": 3,
                                          "input_key": "pickUpContactZip",
                                          "mirrorKey": "companyZip",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "64676a08-657a-451a-b662-0e41467e973a",
                                              "inputID": "7ed66ff8-ae59-4056-b90c-5da3808e2118",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "attachTests": [],
                          "disregardTests": [],
                          "triggerInputs": [],
                          "sectionID": "ab16caed-3730-4279-9386-cfc793a14054",
                          "formID": "96c68783-5ec9-4d61-9ae4-2fdcfb49f992",
                          "type": "sample",
                          "labCode": "cfl",
                          "createdAt": "2023-12-27T06:28:47.000Z",
                          "updatedAt": "2024-09-06T15:55:37.000Z",
                          "testing_groups": [],
                          "sub_sections": [
                              {
                                  "requirements": [],
                                  "sub_sectionID": "063591b9-b069-452c-81d8-a5cd8eb461d0",
                                  "sectionID": "ab16caed-3730-4279-9386-cfc793a14054",
                                  "labCode": "cfl",
                                  "sectionKey": "general",
                                  "order": 1,
                                  "header": "Sample Information",
                                  "icon": "",
                                  "useAsAbove": false,
                                  "createdAt": "2023-12-27T06:28:47.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "inputs": [
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "5f751892-701d-42b0-b7ea-233a2074f1da",
                                          "sub_sectionID": "063591b9-b069-452c-81d8-a5cd8eb461d0",
                                          "order": 1,
                                          "samplingInput": false,
                                          "type": "selectAutoComplete",
                                          "label": "Water Type",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "matrix",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "selection": {
                                              "value": [
                                                  "gHnWr11SEBpB09~01EU"
                                              ],
                                              "selectionID": "bcdce75a-6669-4e56-ad3e-f1dc61cecd72",
                                              "inputID": "5f751892-701d-42b0-b7ea-233a2074f1da",
                                              "source": "materials",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "required": {
                                              "value": [],
                                              "requiredID": "7d856099-d81b-4849-950b-4a5bc04dea12",
                                              "inputID": "5f751892-701d-42b0-b7ea-233a2074f1da",
                                              "key": "1",
                                              "createdAt": "2023-12-27T06:28:49.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "0f8935a7-c97d-4938-82bf-9b487555ca7a",
                                          "sub_sectionID": "063591b9-b069-452c-81d8-a5cd8eb461d0",
                                          "labCode": "cfl",
                                          "order": 2,
                                          "samplingInput": false,
                                          "type": "select",
                                          "label": "Distribution | Source",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "distributionsource",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-12T22:43:01.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "selection": {
                                              "value": [
                                                  "Routine Distribution",
                                                  "Repeat Distribution",
                                                  "Temp. Routine Distribution",
                                                  "Special Distribution",
                                                  "Assessment Source",
                                                  "Triggered Source",
                                                  "Special Source",
                                                  "Confirmation Source"
                                              ],
                                              "selectionID": "2f5ea08e-6a00-489f-9c43-c00e7e473a27",
                                              "inputID": "0f8935a7-c97d-4938-82bf-9b487555ca7a",
                                              "source": "specified",
                                              "createdAt": "2024-02-12T22:43:01.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "required": {
                                              "value": [],
                                              "requiredID": "16b2a7bd-e565-43a1-bb45-9f715579fb51",
                                              "inputID": "0f8935a7-c97d-4938-82bf-9b487555ca7a",
                                              "key": "1",
                                              "createdAt": "2024-05-21T22:07:19.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [
                                              {
                                                  "samplingEvent": "pwssampling",
                                                  "value": "hide"
                                              }
                                          ],
                                          "inputID": "0ca7c805-a224-4c18-8570-9b5d989eaff2",
                                          "sub_sectionID": "063591b9-b069-452c-81d8-a5cd8eb461d0",
                                          "labCode": "cfl",
                                          "order": 3,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Sample Name | Location ",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "Samplename",
                                          "adminStandard": false,
                                          "createdAt": "2024-01-03T19:22:25.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "f70ed333-07d9-4d49-83f4-184eba4808cf",
                                              "inputID": "0ca7c805-a224-4c18-8570-9b5d989eaff2",
                                              "key": "1",
                                              "createdAt": "2024-01-03T19:22:25.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [
                                              {
                                                  "samplingEvent": "pwssampling",
                                                  "value": "only"
                                              }
                                          ],
                                          "inputID": "6699f1d7-dd2f-4d8b-acc1-66237f300da1",
                                          "sub_sectionID": "063591b9-b069-452c-81d8-a5cd8eb461d0",
                                          "labCode": "cfl",
                                          "order": 4,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Sampling Point ",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "SamplingPoint",
                                          "adminStandard": false,
                                          "createdAt": "2024-09-05T22:36:16.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "8b10f880-200d-4328-9dd1-3b80944f4b73",
                                              "inputID": "6699f1d7-dd2f-4d8b-acc1-66237f300da1",
                                              "key": "1",
                                              "createdAt": "2024-09-05T22:36:17.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [
                                              {
                                                  "samplingEvent": "pwssampling",
                                                  "value": "hide"
                                              }
                                          ],
                                          "inputID": "f7460252-3bc1-4483-8954-58305e038b0d",
                                          "sub_sectionID": "063591b9-b069-452c-81d8-a5cd8eb461d0",
                                          "labCode": "cfl",
                                          "order": 5,
                                          "samplingInput": false,
                                          "type": "datePicker",
                                          "label": "Collection Date",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "collectionDate",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-04T18:35:52.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "1ea55c09-829c-4cd5-8b14-bab130242a11",
                                              "inputID": "f7460252-3bc1-4483-8954-58305e038b0d",
                                              "key": "1",
                                              "createdAt": "2024-03-21T17:14:10.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [
                                              {
                                                  "samplingEvent": "pwssampling",
                                                  "value": "hide"
                                              }
                                          ],
                                          "inputID": "0ba37d46-92e8-46cc-b802-4efeeb0456f0",
                                          "sub_sectionID": "063591b9-b069-452c-81d8-a5cd8eb461d0",
                                          "labCode": "cfl",
                                          "order": 6,
                                          "samplingInput": false,
                                          "type": "timePicker",
                                          "label": "Collection Time ",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "collectionTime",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-04T18:35:11.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "a6147014-4221-4daf-9079-7565ce13666e",
                                              "inputID": "0ba37d46-92e8-46cc-b802-4efeeb0456f0",
                                              "key": "1",
                                              "createdAt": "2024-03-21T17:14:20.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [
                                              {
                                                  "samplingEvent": "pwssampling",
                                                  "value": "hide"
                                              }
                                          ],
                                          "inputID": "ff87f7ab-6f74-4149-a412-befbab9d0fd4",
                                          "sub_sectionID": "063591b9-b069-452c-81d8-a5cd8eb461d0",
                                          "labCode": "cfl",
                                          "order": 7,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Collected By",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "Collectedby",
                                          "adminStandard": false,
                                          "createdAt": "2024-01-03T22:55:04.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "e412cd5c-bc27-46aa-9f91-e835312a35fb",
                                              "inputID": "ff87f7ab-6f74-4149-a412-befbab9d0fd4",
                                              "key": "1",
                                              "createdAt": "2024-02-04T18:39:20.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "0a6cbc9e-564d-4d1b-8fc1-abf8807ff62b",
                                          "sub_sectionID": "063591b9-b069-452c-81d8-a5cd8eb461d0",
                                          "labCode": "cfl",
                                          "order": 8,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Distribution Address",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "addressline1",
                                          "adminStandard": false,
                                          "createdAt": "2024-01-23T21:57:12.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "82374aaa-7276-4843-be27-46113616472b",
                                              "inputID": "0a6cbc9e-564d-4d1b-8fc1-abf8807ff62b",
                                              "key": "1",
                                              "createdAt": "2024-03-21T17:14:33.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "a270b6d2-cfa6-4a0f-8d73-165f9f5d8129",
                                          "sub_sectionID": "063591b9-b069-452c-81d8-a5cd8eb461d0",
                                          "labCode": "cfl",
                                          "order": 9,
                                          "samplingInput": false,
                                          "type": "select",
                                          "label": "Chlorinated System",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "chlorinatedSystem",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-12T22:47:52.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "selection": {
                                              "value": [
                                                  "Chlorinated System",
                                                  "Non-Chlorinated System"
                                              ],
                                              "selectionID": "784acc66-1767-47df-82bc-5c0b26a1e79d",
                                              "inputID": "a270b6d2-cfa6-4a0f-8d73-165f9f5d8129",
                                              "source": "specified",
                                              "createdAt": "2024-02-12T22:47:52.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "required": {
                                              "value": [],
                                              "requiredID": "61f14cfb-f563-48d5-81ba-cfcbca1fbd51",
                                              "inputID": "a270b6d2-cfa6-4a0f-8d73-165f9f5d8129",
                                              "key": "1",
                                              "createdAt": "2024-02-12T22:47:52.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [
                                              {
                                                  "samplingEvent": "pwssampling",
                                                  "value": "hide"
                                              }
                                          ],
                                          "inputID": "3fc76451-7d84-478f-be62-3f2b26df19f2",
                                          "sub_sectionID": "063591b9-b069-452c-81d8-a5cd8eb461d0",
                                          "labCode": "cfl",
                                          "order": 10,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Chlorine Value",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "chlorineValue",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-12T22:50:31.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "dependency": {
                                              "value": [
                                                  "Chlorinated System"
                                              ],
                                              "dependencyID": "c741a7d3-0d61-408c-a11a-ede81cf9f1be",
                                              "inputID": "3fc76451-7d84-478f-be62-3f2b26df19f2",
                                              "key": "chlorinatedSystem",
                                              "createdAt": "2024-02-13T23:11:38.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      }
                                  ]
                              },
                              {
                                  "requirements": [],
                                  "sub_sectionID": "9958a153-d21c-41c7-af47-b78e3d16fab4",
                                  "sectionID": "ab16caed-3730-4279-9386-cfc793a14054",
                                  "labCode": "cfl",
                                  "sectionKey": "DistributionAddress",
                                  "order": 2,
                                  "header": "Distribution Address",
                                  "icon": "AccountCircleIcon",
                                  "useAsAbove": false,
                                  "createdAt": "2024-01-23T21:57:12.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "inputs": [
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "ca59e7ec-39c1-4ca1-95bf-654369dcfcfa",
                                          "sub_sectionID": "9958a153-d21c-41c7-af47-b78e3d16fab4",
                                          "labCode": "cfl",
                                          "order": 1,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "City",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 6,
                                          "input_key": "city",
                                          "adminStandard": false,
                                          "createdAt": "2024-01-23T21:57:12.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z"
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "46f1cf48-ebb4-47d1-92f0-f4f2b8f39c56",
                                          "sub_sectionID": "9958a153-d21c-41c7-af47-b78e3d16fab4",
                                          "labCode": "cfl",
                                          "order": 2,
                                          "samplingInput": false,
                                          "type": "select",
                                          "label": "State",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 6,
                                          "md": 3,
                                          "input_key": "state",
                                          "adminStandard": false,
                                          "createdAt": "2024-01-23T21:57:12.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z",
                                          "selection": {
                                              "value": [
                                                  "bXTQT11M7rCC01R01EU",
                                                  "6YTQT11M7rCC01R01EU"
                                              ],
                                              "selectionID": "d081d8bf-bc9e-4f61-a98c-4b55d884582f",
                                              "inputID": "46f1cf48-ebb4-47d1-92f0-f4f2b8f39c56",
                                              "source": "states",
                                              "createdAt": "2024-01-23T21:57:13.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "dd3612e7-c9c0-4a88-9781-dfb9c76fff23",
                                          "sub_sectionID": "9958a153-d21c-41c7-af47-b78e3d16fab4",
                                          "labCode": "cfl",
                                          "order": 3,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Zip Code",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 6,
                                          "md": 3,
                                          "input_key": "ZipCode",
                                          "adminStandard": false,
                                          "createdAt": "2024-01-23T21:57:12.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z"
                                      }
                                  ]
                              },
                              {
                                  "requirements": [],
                                  "sub_sectionID": "135f2765-ba5d-49dc-a711-5d731ab5162c",
                                  "sectionID": "ab16caed-3730-4279-9386-cfc793a14054",
                                  "labCode": "cfl",
                                  "sectionKey": "additional",
                                  "order": 3,
                                  "header": "Additional Sample Information",
                                  "icon": "",
                                  "useAsAbove": false,
                                  "createdAt": "2023-12-27T06:28:47.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "inputs": [
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "85e10432-ffb2-4add-b702-22c7d65f9e97",
                                          "sub_sectionID": "135f2765-ba5d-49dc-a711-5d731ab5162c",
                                          "order": 1,
                                          "samplingInput": false,
                                          "type": "textArea",
                                          "label": "Additional Test Requests and Sample Comments",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "testingText",
                                          "adminStandard": false,
                                          "createdAt": "2023-12-27T06:28:48.000Z",
                                          "updatedAt": "2024-09-06T15:55:38.000Z"
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "attachTests": [],
                          "disregardTests": [],
                          "triggerInputs": [],
                          "sectionID": "ec39ad71-b414-45f2-a8c1-090b9c28a973",
                          "formID": "96c68783-5ec9-4d61-9ae4-2fdcfb49f992",
                          "type": "admin-receive",
                          "labCode": "cfl",
                          "createdAt": "2023-12-27T06:28:47.000Z",
                          "updatedAt": "2024-09-06T15:55:37.000Z",
                          "testing_groups": [],
                          "sub_sections": [
                              {
                                  "requirements": [],
                                  "sub_sectionID": "dd0731b2-b192-4c8d-a210-de434045a3eb",
                                  "sectionID": "ec39ad71-b414-45f2-a8c1-090b9c28a973",
                                  "labCode": "cfl",
                                  "sectionKey": "adminReceive",
                                  "order": 1,
                                  "header": "Receipt Information",
                                  "icon": "BorderColorIcon",
                                  "useAsAbove": false,
                                  "createdAt": "2024-02-26T18:58:20.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "inputs": [
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "9230fe25-24ca-48e4-892d-2b88c533aad7",
                                          "sub_sectionID": "dd0731b2-b192-4c8d-a210-de434045a3eb",
                                          "labCode": "cfl",
                                          "order": 1,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Admin Initials",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "name",
                                          "adminStandard": true,
                                          "createdAt": "2024-03-04T22:58:37.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "369b809e-beeb-4268-aa41-df1379563faa",
                                          "sub_sectionID": "dd0731b2-b192-4c8d-a210-de434045a3eb",
                                          "labCode": "cfl",
                                          "order": 2,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Sample Tempurature, °C",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "tempurature",
                                          "adminStandard": true,
                                          "createdAt": "2024-02-26T19:00:17.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "32cbdca2-d86c-4f1f-884e-0b77cb977b9b",
                                          "sub_sectionID": "dd0731b2-b192-4c8d-a210-de434045a3eb",
                                          "labCode": "cfl",
                                          "order": 3,
                                          "samplingInput": false,
                                          "type": "radioGroup",
                                          "label": "Evidence of Cooling?",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "cooling",
                                          "adminStandard": true,
                                          "createdAt": "2024-02-26T20:16:15.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "selection": {
                                              "value": [
                                                  "No",
                                                  "Yes"
                                              ],
                                              "selectionID": "191bf8f1-f6ca-4364-a360-ba063bee0c23",
                                              "inputID": "32cbdca2-d86c-4f1f-884e-0b77cb977b9b",
                                              "source": "specified",
                                              "createdAt": "2024-02-26T20:18:50.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "7a74199e-63c5-4e1f-b986-d412118b3b3b",
                                          "sub_sectionID": "dd0731b2-b192-4c8d-a210-de434045a3eb",
                                          "labCode": "cfl",
                                          "order": 4,
                                          "samplingInput": false,
                                          "type": "radioGroup",
                                          "label": "Sample Condition",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "sampleCondition",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-26T19:03:14.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "selection": {
                                              "value": [
                                                  "Satisfactory",
                                                  "Damage Observed"
                                              ],
                                              "selectionID": "0545206f-b02e-4678-b4af-3e0140ec8908",
                                              "inputID": "7a74199e-63c5-4e1f-b986-d412118b3b3b",
                                              "source": "specified",
                                              "createdAt": "2024-02-26T20:19:34.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "9d528d37-90c9-4479-9ff7-fcb529aa629d",
                                          "sub_sectionID": "dd0731b2-b192-4c8d-a210-de434045a3eb",
                                          "labCode": "cfl",
                                          "order": 5,
                                          "samplingInput": false,
                                          "type": "select",
                                          "label": "Prelog Storage",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "prelogStorage",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-26T19:00:17.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "selection": {
                                              "value": [
                                                  "Food Shelves",
                                                  "R44",
                                                  "F44",
                                                  "R99",
                                                  "R19",
                                                  "Metals Cart"
                                              ],
                                              "selectionID": "6c0b7897-1abf-42c4-bf4c-074a08cd2022",
                                              "inputID": "9d528d37-90c9-4479-9ff7-fcb529aa629d",
                                              "source": "specified",
                                              "createdAt": "2024-02-26T23:25:51.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      }
                                  ]
                              },
                              {
                                  "requirements": [],
                                  "sub_sectionID": "a45062f4-aabc-4095-8102-791c31b50603",
                                  "sectionID": "ec39ad71-b414-45f2-a8c1-090b9c28a973",
                                  "labCode": "cfl",
                                  "sectionKey": "adminAdditional",
                                  "order": 2,
                                  "header": "Additional Receipt Information",
                                  "icon": "PlaylistAddIcon",
                                  "useAsAbove": false,
                                  "createdAt": "2024-02-26T20:17:51.000Z",
                                  "updatedAt": "2024-09-06T15:55:37.000Z",
                                  "inputs": [
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "672bb2a8-4f0a-4a65-a35d-5573cd0f9b7c",
                                          "sub_sectionID": "a45062f4-aabc-4095-8102-791c31b50603",
                                          "labCode": "cfl",
                                          "order": 1,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Sample Condition",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "sampleConditionDescription",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-26T20:17:52.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z"
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "4ae26e4d-1dfc-47b6-ab85-249819224ffb",
                                          "sub_sectionID": "a45062f4-aabc-4095-8102-791c31b50603",
                                          "labCode": "cfl",
                                          "order": 2,
                                          "samplingInput": false,
                                          "type": "radioGroup",
                                          "label": "Check Included?",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "checkIncluded",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-26T20:17:52.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "selection": {
                                              "value": [
                                                  "No",
                                                  "Yes"
                                              ],
                                              "selectionID": "aab79faf-11ad-44c3-b686-f20afb341eef",
                                              "inputID": "4ae26e4d-1dfc-47b6-ab85-249819224ffb",
                                              "source": "specified",
                                              "createdAt": "2024-02-26T20:19:34.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          },
                                          "required": {
                                              "value": [],
                                              "requiredID": "3bc733f9-1c7a-4a12-81bc-e3625f185b79",
                                              "inputID": "4ae26e4d-1dfc-47b6-ab85-249819224ffb",
                                              "key": "1",
                                              "createdAt": "2024-02-26T20:17:52.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      },
                                      {
                                          "visibleOnSamplingEvents": [],
                                          "inputID": "8a9c0432-7a6c-4da2-9470-75ef5a7d77a8",
                                          "sub_sectionID": "a45062f4-aabc-4095-8102-791c31b50603",
                                          "labCode": "cfl",
                                          "order": 3,
                                          "samplingInput": false,
                                          "type": "input",
                                          "label": "Check Number",
                                          "disableFuture": false,
                                          "viewOnMaterials": "show",
                                          "xs": 12,
                                          "md": 12,
                                          "input_key": "checkNumber",
                                          "adminStandard": false,
                                          "createdAt": "2024-02-26T20:18:18.000Z",
                                          "updatedAt": "2024-09-06T15:55:37.000Z",
                                          "required": {
                                              "value": [],
                                              "requiredID": "9fa4adda-48ad-423e-a626-566aa321aa48",
                                              "inputID": "8a9c0432-7a6c-4da2-9470-75ef5a7d77a8",
                                              "key": "1",
                                              "createdAt": "2024-02-26T20:18:19.000Z",
                                              "updatedAt": "2024-09-06T15:55:38.000Z"
                                          }
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              }
              const resForm = await FormAPI.putFormUpdates({
                labCode: "cfl",
                serviceLine: 'emp',
                data: data
            })
                setJsonData(resForm)
              }}
            >
              Set Service Line
            </Button>
            <br /><br />
                <br /><br />
            <Button
              variant="contained"
              color="success"
              disabled={false}
              onClick={async () => {
                setJsonData(false)
                const resForm = await FormAPI.getLIMSLogs({labCode: 'cfl'})
                setJsonData(resForm)
              }}
            >
              LIMS Logs
            </Button>


            {/* <Button
            variant="contained"
            color="success"
            disabled={true}
            onClick={async () => {
              await FormAPI.createServiceLine({labCode: 'cfl'})
            }}
            >

            createServiceLine
            </Button> */}
            </>
          : 
            // get access here
            <AdminLogin setPermission={setPermission} setLoading={setLoading} target={'developer'} loading={loading} />
          }

        </>
        
        }

        
        {jsonData ? 
          <ReactJson style={{textAlign: 'left'}} src={jsonData} />
        : null}

        
    </>
        
    )

}

export default Developer;
