import React from "react";

// components
import QueryControls from "./QueryControls.js";

// mui
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Container from "@mui/material/Container";
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { Grid, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MainToolbar = ({ resetFilter, setFilter, serviceLines, setFilters, filters, setArchiveData, getData, archiveData, loading, statuses, submissionTypes }) => {

  const [input, setInput] = React.useState('');

  const handleSubmit = () => {
      setFilter('submissionID', input)
  }

  const handleClear = () => {
      setInput('')
      resetFilter('submissionID')
  }
  
  const enterListen = (e) => {
      if (e.key === "Enter") {
          handleSubmit()
      }
  }

  return (
    <Container
      sx={{
        width: {xs: '100vw', lg: "80vw"},
        backgroundColor: {xs: 'RGBA(255,255,255,0.7)', md: "#fff"},
        margin: "auto",
        padding: "15px",
        marginTop: "15px",
        marginBottom: "15px",
        maxWidth: '2000px !important'
      }}
    >
      
      <Grid container spacing={1}>
        
        <Grid item xs={12} md={12}>
          <Typography variant="h5">Admin Summary & Tools</Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={1}>

            <Grid item xs={12} md={4}>

              <FormControl sx={{ width: '100%' }} size="small">
                <Autocomplete
                  onChange={(event, newValue) => {
                    setFilter("serviceLine", newValue);
                  }}
                  value={filters.serviceLine || []}
                  multiple
                  options={serviceLines.map(sl => sl.serviceLine)}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps} style={{ padding: '0px'}}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Service Lines" placeholder="Service Lines" size="small"/>
                  )}
                />
              </FormControl>

            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }} size="small">
                <Autocomplete
                  onChange={(event, newValue) => {
                    setFilter("submissionType", newValue);
                  }}
                  value={filters.submissionType || []}
                  multiple
                  options={submissionTypes}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Submission Types" placeholder="Submission Types"  size="small"/>
                  )}
                />
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }} size="small">
                <Autocomplete
                  onChange={(event, newValue) => {
                    setFilter("status", newValue);
                  }}
                  value={filters.status || []}
                  multiple
                  options={statuses}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Statuses" placeholder="Statuses"  size="small"/>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4}>   
              <ButtonGroup variant="outlined" orientation="horizontal" fullWidth >
                <Button
                    style={{fontSize: '0.6rem'}}
                    variant={
                      filters["receive"]?.includes("received")
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setFilter("receive", "received", 'single');
                    }}
                    disabled={loading}
                  >
                    Received
                </Button>
                <Button
                    style={{fontSize: '0.6rem'}}
                    variant={
                      filters["receive"]?.includes("not received")
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setFilter("receive", "not received", 'single');
                    }}
                    disabled={loading}
                  >
                    Not Yet Received
                </Button>
                <Button
                  style={{fontSize: '0.6rem'}}
                  onClick={() => {
                    resetFilter("receive");
                  }}
                  disabled={loading}
                  variant={
                    !filters["receive"]
                      ? "contained"
                      : "outlined"
                  }
                >
                  All Received
                </Button>
              </ButtonGroup>
            </Grid>

            <Grid item xs={12} lg={4}>
              <OutlinedInput 
                disabled={loading} 
                style={{fontSize: '0.7rem', height: '40px'}}
                fullWidth
                placeholder="Submission ID Filter..."
                variant="standard" 
                value={input} 
                onKeyDown={enterListen} 
                onChange={e => setInput(e.target.value)} 
                endAdornment={
                  <ButtonGroup>
                    <Button style={{fontSize: '0.6rem'}} disabled={loading} onClick={handleSubmit} >Submit</Button>
                    <Button style={{fontSize: '0.6rem'}} disabled={loading} onClick={handleClear} >Clear</Button>
                  </ButtonGroup>
                }
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <QueryControls archiveData={archiveData} setArchiveData={setArchiveData} getData={getData} loading={loading} filters={filters} setFilters={setFilters} />
            </Grid>

          </Grid>
        </Grid>

      </Grid>
    </Container>
  );
};

export default MainToolbar;
