import cloneDeep from 'lodash/cloneDeep';

const samplingEventTools = {
    filteredInputs: (inputs, samplingMode, entryMode) => {
        return inputs.map(input => {
            // console.log(samplingMode)
            if (samplingMode) {
                // when in sampling mode, show on these two params, but also if it lacks a visibility value at all (default is show)
                // also don't show stuff that's materials-only
                const visible = input.visibleOnSamplingEvents?.find(vos => vos.samplingEvent === samplingMode.urlParam)
                return visible?.value !== 'hide' && input.viewOnMaterials !== 'only' ? input : false
            }
            if (!samplingMode) {
                // look for ANY value that says "only" for this one, don't show them
                const onlyCheck = input.visibleOnSamplingEvents?.find(vos => vos.value === 'only')

                if (entryMode !== 'samples') {
                    // This is for Materials Ordering, show as long asi its not a sampling event only and not hidden
                    // for materials, show the input if it is designated as show or only, 
                    // and make sure NOT to show sampling event inputs that are designated as only
                    return (input.viewOnMaterials === 'show' || input.viewOnMaterials === 'only') && !onlyCheck ? input : false
                }
                if (entryMode === 'samples') {
                    // FOR NORMAL SUBS, basically both of these must be false
                    return input.viewOnMaterials !== 'only' && !onlyCheck ? input : false
                }
            }
            // default
            return input
        
        }).filter(input => input)

    },
    updateInputs: (userInput, data, entryMode) => {
        const newUserInput = cloneDeep(userInput)
        const clientDataMaster = data.forms.sections.find(f => f.type === 'client');
        const clientDataUpdate = cloneDeep(clientDataMaster)

        clientDataUpdate.sub_sections.forEach(ss => {
            ss.inputs = samplingEventTools.filteredInputs(ss.inputs, data.activeSamplingEvent || false, entryMode)
        })

        const allPossibleClientInputs = clientDataMaster.sub_sections
        .map(ss => {
            return ss.inputs
        }).flat()

        const updatedClientInputs = clientDataUpdate.sub_sections
        .map(ss => {
            return ss.inputs
        }).flat()

        Object.keys(newUserInput).forEach(key => {
            const isAnEntry = allPossibleClientInputs.find(input => input.input_key === key)
            const isFound = updatedClientInputs.find(input => input.input_key === key)
            if (isAnEntry && !isFound) {
                delete newUserInput[key]
            }            
        })

        const sampleDataMaster = data.forms.sections.find(f => f.type === 'sample');
        const sampleDataUpdate = cloneDeep(sampleDataMaster)

        sampleDataUpdate.sub_sections.forEach(ss => {
            ss.inputs = samplingEventTools.filteredInputs(ss.inputs, data.activeSamplingEvent || false, entryMode)
        })

        const allPossibleSampleInputs = sampleDataMaster.sub_sections
        .map(ss => {
            return ss.inputs
        }).flat()

        const updatedSampleInputs = sampleDataUpdate.sub_sections
        .map(ss => {
            return ss.inputs
        }).flat()

        newUserInput.samples = newUserInput.samples.map(sample => {
            Object.keys(sample).forEach(key => {
                const isAnEntry = allPossibleSampleInputs.find(input => input.input_key === key)
                const isFound = updatedSampleInputs.find(input => input.input_key === key)
                if (isAnEntry && !isFound) {
                    delete sample[key]
                }
            })
            
            return sample
        })

        return newUserInput
    }
}

export default samplingEventTools;
