const selectInputsData = {
    getFullSelectOptions: (data) => {
        const clientForm = data.forms?.sections?.find(f => f.type === 'client')
        const sampleForm = data.forms?.sections?.find(f => f.type === 'sample')
        
        const clientSubSections = clientForm.sub_sections.map(f => {
            f.type = 'client'
            return f
        })

        const sampleSubSections = sampleForm.sub_sections.map(f => {
            f.type = 'sample'
            return f
        })
        
        let options = []
        const concated = [...clientSubSections, ...sampleSubSections]
        concated.forEach(ss => {
            const selects = ss.inputs.filter(input => input.type === 'select' || input.type === 'selectAutoComplete')
            selects.forEach(input => {
                options.push({
                    key: input.input_key,
                    text: ss.header + " - " + input.label,
                    type: ss.type
                })
            })
        })
        
        return options.filter(item => item.text.indexOf('undefined') < 0)
    },
    findObject: (data, checkKey) => {
        let returnVal = false

        const clientForm = data.forms?.sections?.find(f => f.type === 'client')
        const sampleForm = data.forms?.sections?.find(f => f.type === 'sample')
        const concated = [...clientForm.sub_sections, ...sampleForm.sub_sections]
        concated?.forEach(ss => {
            const foundInput = ss.inputs?.find(input => {
                return input.input_key === checkKey
            });
            if (foundInput) {
                returnVal = foundInput
            }
        })

        return returnVal
    }
}

export default selectInputsData;

