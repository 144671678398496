import React from 'react';

// mui
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const TestingDetails = ({ data, userData }) => {
  const [value, setValue] = React.useState(false);

  const uniqueTests = () => {
    const testList = {}

    userData.samples.forEach(sample => {
      sample.activeTests.forEach(test => {
        const findTest = testList[test.type]?.find(t => t.LIMS_ID === test.LIMS_ID)
        if (!findTest) {
          testList[test.type] = testList[test.type] ? [...testList[test.type], test] : [test]
        }
      })
    })
    return testList
  }

  const renderTests = uniqueTests();
  const fullTest = value ? data.testing[value.type + 's'].filter(pss => pss.LIMS_ID === value.LIMS_ID) : false;
  
    return (
      <div style={{ backgroundColor: 'lightGrey', padding: '10px', borderRadius: '5px', marginBottom: '15px'}}>

        <ButtonGroup
          orientation="vertical"
          aria-label="Vertical button group"
          variant="text"
        >          
          {renderTests.spectra?.map(ps => {
            const fullTest = data.testing.spectras.find(pss => pss.LIMS_ID === ps.LIMS_ID)
            return <Button variant={value.LIMS_ID === ps.LIMS_ID ? 'contained' : 'outlined'} key={ps.LIMS_ID} onClick={() => setValue(ps)}>{fullTest.DISPLAYNAME}</Button>
          })}
          {renderTests.parameterScope?.map(ps => {
            const fullTest = data.testing.parameterScopes.find(pss => pss.LIMS_ID === ps.LIMS_ID)
            return <Button variant={value.LIMS_ID === ps.LIMS_ID ? 'contained' : 'outlined'} key={ps.LIMS_ID} onClick={() => setValue(ps)}>{fullTest.DISPLAYNAME}</Button>
          })}
          {renderTests.parameterMethod?.map(ps => {
            const fullTest = data.testing.parameterMethods.find(pss => pss.LIMS_ID === ps.LIMS_ID)
            return <Button variant={value.LIMS_ID === ps.LIMS_ID ? 'contained' : 'outlined'} key={ps.LIMS_ID} onClick={() => setValue(ps)}>{fullTest.DISPLAYNAME}</Button>
          })}
        </ButtonGroup>

        {fullTest ? 
          <TableContainer sx={{ height: '600px', overflowy: 'scroll', marginTop: '15px' }}> 
            <Table size="small" aria-label="a dense table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: '12px', backgroundColor: 'lightgray', fontWeight: 'bold'}}>Parameter</TableCell>
                  <TableCell sx={{ fontSize: '12px', backgroundColor: 'lightgray', fontWeight: 'bold'}} align="right">Method</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fullTest?.map((row, i) => (
                  <TableRow
                    key={row.LIMS_ID + '-render-list-' + i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.ANALYTE}
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align="right">{row.METHODN}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        : null }



      </div>
    )

}

export default TestingDetails;
