import * as React from 'react';

// components
import SamplingEventInstructions from './Relinquishment/SamplingEventInstructions';
import ProjectNotes from './ProjectNotes';
import AddendumSubmissionComments from './AddendumSubmissionComments';
import GeneralSamplingEventWorksheets from './GeneralSamplingEvent/GeneralSamplingEventWorksheets';
import RelinquishmentTools from './RelinquishmentTools';
import TestingDetails from './TestingDetails';

// mui
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import '../../utils/styles/customTabs.css'

const RelinquishmentToolNavigator = ({ userData, data, setUserData }) => {

    const [mode, setMode] = React.useState(0)
    const options = [
        {
            title: 'COC Status',
            render: true,
            component: <RelinquishmentTools data={data} userData={userData} setUserData={setUserData} />
        },
        {
            title: 'Cannabis Sampling Instructions',
            render: userData.serviceLine === 'cannabis-sampling' || false,
            component: <SamplingEventInstructions data={data} userData={userData} setUserData={setUserData} />
        },
        {
            title: 'Sampling Event',
            render: userData?.submissionType?.includes('samplingEvent,') || false,
            component: <GeneralSamplingEventWorksheets data={data} userData={userData} setUserData={setUserData} />
        },
        {
            title: 'Admin Notes',
            render: userData.submissionID || false,
            component: <ProjectNotes userInput={userData} />
        },
        {
            title: 'COC Comments',
            render: userData.submissionID || false,
            component: <AddendumSubmissionComments userData={userData} setUserData={setUserData} />
        },
        {
            title: 'Testing Summary',
            render: true,
            component: <TestingDetails data={data} userData={userData} />
        },
    ]

    const renderList = options.filter(op => op.render)

  return (
    <div style={{ padding: '10px', borderRadius: '5px', alignContent: 'left'}}>

        <div className='header'>

        <Stack direction="row" spacing={1}>
            {renderList.map((op, index) => <Chip key={'rel-head-'+op.title} label={op.title} color={ mode === index ? 'primary' : 'success'} onClick={() => setMode(index)} />)}
        </Stack>

        </div>

        <div className='render-body'>
            {mode > -1 ? <>{renderList[mode]?.component}</> : null}
        </div>

    </div>
  );
}

export default RelinquishmentToolNavigator;
