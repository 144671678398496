import React from 'react';

// mui
import Grid from '@mui/material/Grid';

// utils

const BodyAlerts = ({ userInput, samplingMode }) => {

  const signatures = userInput.signatures?.filter(s => s.type === 'relinquish').sort(function(a,b){return new Date(a.dateTime) - new Date(b.dateTime)});

  const initialSignature = signatures ? signatures[0] : false

  return (
    <Grid style={{ marginTop: "25px", width: "100%" }} container spacing={0}>
        <Grid xs={12}>
          {!initialSignature && userInput.status !== 'sampling-complete' && userInput.status !== 'saved' && userInput.status !== 'saved-form' && userInput.status ?
            <div
              className="blob red"
              style={{
                backgroundColor: 'RGBA(255,255,255,0.5)',
                border: '1px black solid',
                borderRadius: '15px',
                padding: '10px 5px 15px 15px',
                fontSize: '0.8vw'
              }}
            >          
              <h4>Sampling Event Submitted</h4>
            </div>
        : null }
        </Grid>
    </Grid>
    
  );
}

export default BodyAlerts;
