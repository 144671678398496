import React, { useEffect } from 'react';

// components
import DocumentControl from './DocumentControl';
import COCBody from './COCBody';
import COCHeader from './COCHeader';
import COCFooter from './COCFooter';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// mui
import Stack from '@mui/material/Stack';

// utils
import "../../utils/styles/cocStyle.css"

const Item = styled(Paper)(({ theme }) => ({    
  padding: theme.spacing(0),
  textAlign: 'center',
}));

const COCRender = ({ userInput, setUserData, data, labUseOpen, setLabUseOpen, createSamplingDuplicates, setMode, samplingMode, showBottles }) => {
  
  const [page, setPage] = React.useState(0)  
  const [samplingEventMode, setSamplingEventMode] = React.useState(false)
  const [samplingEventInputs, setSamplingEventInputs] = React.useState([])

  // THIS IS HERE FOR NOW
  const [globalLoading, setGlobalLoading] = React.useState(false)

  // this overrides the sampling event "is finished" state so that the ECOC can look like it does before it's complete
  const [SEOR, setSEOR] = React.useState(false)

  const maxPages = Math.ceil(userInput.samples.length / 10)

  // check for sampling event pre-final status and not in admin view
  const samplingCheck = 
                      window.location.href.indexOf('admin') < 0 && 
                      (userInput.serviceLine === "cannabis-sampling" || samplingMode) && 
                      userInput.status !== "client-signed" && 
                      userInput.status !== "samplingComplete"

  const useSamples = !samplingCheck && !SEOR ? userInput.samples.sort((a, b) => { return a.id - b.id; }) : userInput.samples.sort((a, b) => { return a.id - b.id; }).filter(s => !s.samplingDuplicate || s.samplingDuplicate === "Primary")

  const handleNewPage = (index) => {    
    const samplesSub = useSamples.slice(0 + (index * 10), 10 + (index * 10))
    return samplesSub
  }

  const renderSamples = handleNewPage(page)

  useEffect(() => {
    handleNewPage(0)
  }, [])

  return (
    <>
       
        

        <div className="documentControl">

          <DocumentControl
            samplingEventMode={samplingEventMode} setSamplingEventMode={setSamplingEventMode} 
            samplingEventInputs={samplingEventInputs} setSamplingEventInputs={setSamplingEventInputs}           
            labUseOpen={labUseOpen} setLabUseOpen={setLabUseOpen}
            createSamplingDuplicates={createSamplingDuplicates}
            handleNewPage={handleNewPage} page={page}
            maxPages={maxPages}
            setPage={setPage}
            userInput={userInput} setUserData={setUserData}
            data={data}            
            SEOR={SEOR} setSEOR={setSEOR}
            samplingMode={samplingMode}
          />
         
          
          <div className="COCScrollContainer">
            <div 
              id="COC-Rendered"
              className="COCBody"
            >
              <Stack>
              {!userInput.submissionID ?               
                <Typography
                  style={{
                    transform: 'rotate(-33deg) translate(8%, 12%)',
                    fontSize: '10vw',
                    fontWeight: 'bolder',
                    color: 'RGBA(255,100,100,0.50)',
                    position: 'absolute',
                  }}
                
                >
                  DRAFT
                  <p style={{fontSize: '5vw'}}>SUBMISSION OUTSTANDING</p>
                </Typography>  
              : null}

              {userInput.submissionID && !userInput.signatures?.length && userInput.status !== 'submitted' && userInput.status !== 'samplingDuplicatesCreated' && userInput.status !== 'samplingComplete' ?               
                  <Typography
                    style={{
                      transform: 'rotate(-33deg) translate(10%, 50%)',
                      fontSize: '8vw',
                      fontWeight: 'bolder',
                      color: 'RGBA(255,100,100,0.50)',
                      position: 'absolute',
                    }}
                  
                  >
                    SAVED DRAFT
                    <p style={{fontSize: '4vw'}}>{(userInput.serviceLine === "cannabis-sampling" || samplingMode) ? 'UNSUBMITTED' : 'UNRELINQUISHED'}</p>
                  </Typography> 
                : null}

                <Item elevation={0}><COCHeader userInput={userInput} SEOR={SEOR} samplingMode={samplingMode} data={data}/></Item>
                          
                <Item elevation={0}>
                  <COCBody 
                    userInput={userInput} setUserData={setUserData}
                    renderSamples={renderSamples} 
                    data={data} SEOR={SEOR}
                    labUseOpen={labUseOpen} 
                    samplingEventMode={samplingEventMode} 
                    samplingEventInputs={samplingEventInputs} setSamplingEventInputs={setSamplingEventInputs}
                    setMode={setMode} page={page || 0}
                    setGlobalLoading={setGlobalLoading}                    
                    samplingMode={samplingMode} showBottles={showBottles}
                  />
                </Item>
              
                <Item elevation={0}><COCFooter maxPages={maxPages} page={page} userInput={userInput} SEOR={SEOR}/></Item>
    
              </Stack>
              
            </div>


          </div>
        
        </div>      
        
    </>
  );
}

export default COCRender;
