import React from 'react';

// mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import HomeIcon from '@mui/icons-material/Home';

const RelinquishmentHeader = ({ userData, setMode, formData, setError }) => {

    const handleBackToMain = () => {
      setMode('admin')
      setError(false)
      window.history.pushState('page2', 'Title', '/admin/')
    }
  
    const samplingForm = formData?.forms?.sections?.filter(s => s.triggerInputs?.length > 0).find(s => s.samplingEventName === userData.submissionType.split(',')?.[1] || s.samplingEventKey === userData.submissionType.split(',')?.[1])

    return (
    <Paper elevation={3} style={{marginBottom: '15px', padding: '15px', backgroundColor: 'rgba(240,240,240,0.8)'}}>
        <Grid container spacing={1} >

          <Grid item xs={12} md={8}>
            <Typography variant='h5'>{samplingForm?.samplingEventName} Order Summary on {userData.submissionID}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
              <Button variant="contained" component={Link} href={`${window.location.origin}/${userData.serviceLine}/${encodeURIComponent(userData.submissionID)}`} target="_blank" rel="noopener noreferrer" endIcon={<FolderSharedIcon />}>Client Review Page</Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button variant="contained" onClick={handleBackToMain} endIcon={<HomeIcon />}>Back to Admin Home</Button>
          </Grid>
          
        </Grid>
        
    </Paper>
    )

}

export default RelinquishmentHeader;
