import * as React from 'react';
import ReactQuill from 'react-quill';
import cloneDeep from 'lodash/cloneDeep';

// mui
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

// utils
import FormAPI from '../../utils/API/api-form';
import dateHandlers from '../../utils/functions/dateHandlers';

import 'react-quill/dist/quill.snow.css';
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
}));

const ProjectNotes = ({ userInput }) => {

    const [notes, setNotes] = React.useState([])
    const [value, setValue] = React.useState('');
    const [name, setName] = React.useState('');
    const [loading, setLoading] = React.useState(false)
    const [saving, setSaving] = React.useState(false)
    const [error, setError] = React.useState(false)

    const scrollBottom = () => {
      
      const element = document.getElementById('notes-container');
      if (element) {
        element.scroll({ top: element.scrollHeight * 100, behavior: 'smooth' });
      }

    }
    const getData = async () => {
      setLoading(true)
      setError(false)
      try {
        const response = await FormAPI.getAdminNotes({ submissionID: userInput.submissionID })
        setNotes(response.data)
      } catch (err) {
        console.log(err)
        setError('Could not load notes. Try again by refreshing page. Contact IT if issue persists!')
      }
    
      setLoading(false)
    }

    React.useEffect(() => {
      getData()      
    }, [])

    const handleSave = async () => {
      
      setSaving(true)
      setError(false)

      try {
        const submission = {
          clientID: userInput.submissionID,
          text: value,
          dateTime: new Date(),
          user: name
        }
        
        await FormAPI.putAdminNotes(submission)
        let newNotes = [...cloneDeep(notes), submission]
      
        setNotes(newNotes)        
        setValue('')
        
      } catch (err) {
        console.log(err)
        setError('Could not save note. Try again. Contact IT if issue persists!')
      }

      
      setSaving(false)
      
    }

    React.useEffect(() => {
      scrollBottom()
    }, [notes])

  return (
    <div style={{ backgroundColor: 'lightGrey', padding: '10px', borderRadius: '5px'}}>
      <div style={{ width: '100%', position: 'relative' }}>        
        <Typography align="center" variant='h5'>Admin Notes</Typography>
        <Typography align="center" variant='p'>These notes are only visible by admin staff!</Typography>
      </div>
      <Divider variant="middle" style={{ margin: '15px' }} />
      <Button variant='contained' style={{ width: '100%' }} onClick={getData}>Refresh</Button>

      {error ? <span style={{color: 'red'}}>Something went wrong saving, try again, else contact devs!</span> : null}

      {loading ?
      "loading notes..."
      : 
      <>
      <div id='notes-container' style={{ maxHeight: '500px', overflowY: 'scroll', marginBottom: '10px' }}>
        <Stack spacing={1}>
          
          {notes.map(n => {
            return (
              <Item key={n.user + n.dateTime}>
                <div dangerouslySetInnerHTML={{__html: n.text}} />
                <p style={{ fontStyle: 'italic'}}><span style={{ fontWeight: 'bold' }}>{n.user}</span> {dateHandlers.standardDate(n.dateTime)} {dateHandlers.standardTime(n.dateTime)}</p>
              </Item>
            )
          })}

        </Stack>
      </div>
      
      <div>
        <Stack spacing={1}>
          <Item>
            <ReactQuill modules={
              {
                toolbar: [
                  ['bold', 'italic', 'underline'],
                  ['link'],
                ]
              }
            } className='ql-editor' theme="snow" value={value} onChange={setValue} disabled={true} />
          </Item>
          <Item>
            <OutlinedInput
              fullWidth
              InputLabel="Name"
              value={name}  
              onChange={(e) => setName(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleSave}
                    color='success'
                    edge="end"
                    disabled={name === '' || value === '' || saving}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Item>
        </Stack>
        

          
      </div>
      </>
      }
      
      <div>
        
      </div>
    </div>
  );
}

export default ProjectNotes;
