import React from 'react';

// components
import TestingBody from '../testing/TestingBody';

// mui
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import BiotechIcon from "@mui/icons-material/Biotech";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import { ClearIcon } from "@mui/x-date-pickers";

// styles
import '../../../utils/styles/styleSheet.css';

const Materials = ({ userInput, data, setUserInput, checkRequirements, mode, handleRemoveSample, handleNewSample, maxPages, pageSamples, setPageSamples }) => {
    
  const [searchParam, setSearchParam] = React.useState('');
  
  const handleSearch = (e) => {
    setSearchParam(e.target.value)
  }
    const testing = data.forms?.sections?.find(f => f.type === "testing") || false

    return (
        <>
        <Grid item xs={0} md={2} lg={3} >                
          </Grid>
            <Grid item xs={12} md={8} lg={6}>
            <Paper elevation={2} className="formCanvas">
          <Grid container spacing={1}>

          <Grid item xs={12} md={6} className="formHeaderContainer">
            <BiotechIcon className="formHeaderIcon" />
            <Typography component="h5" variant="h5">
              Material & Testing Requests
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} className="formHeaderContainer">
            <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="input-with-icon-adornment">
              Search Testing...
            </InputLabel>
            <Input
              id="input-with-icon-adornment"
              onChange={handleSearch}
              value={searchParam}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={searchParam ? 
                <InputAdornment position="start" style={{ cursor: 'pointer' }} onClick={() => setSearchParam('')}>
                  <ClearIcon />
                </InputAdornment>
              : null}
            />
          </FormControl>
          </Grid>
          <TestingBody
            searchParam={searchParam}
            checkRequirements={checkRequirements}
            renderData={testing}
            userInput={userInput}
            setUserInput={setUserInput}
            refSample={false}
            data={data}
            formMode={mode}
          />

      </Grid>
    </Paper>


            </Grid>
        <Grid item xs={0} md={2} lg={3}>       
        </Grid>
        
        </>

    
        
    )

}

export default Materials;
