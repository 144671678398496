import uniqBy from 'lodash/uniqBy';

export default class ModeModel {
    constructor(data) {
        if (!data) {
            data = {}
        }

        this.mode = 'samplingEvent';
        this.modeName = data.samplingEventName || '';
        this.urlParam = data.samplingEventKey || '';
        this.submissionPrefix = data.submissionPrefix || '';
        this.userInputs = data.triggerInputs || [];
        this.testing = data.attachTests || [];
        this.testingHide = data.disregardTests || [];
    }

    setPresetInputs(userData) {
        userData.submissionType = `samplingEvent,${this.urlParam}`
        this.userInputs.forEach(up => {
            if (up.type === 'client') {
                userData[up.key] = up.value
            } else {
                // samples or matOrders (later on)
                userData.samples[0][up.key] = up.value
            }
        })
        return userData
    }

    setTesting(userData, data) {
        if (!userData?.samples) {
            return userData
        }
        
        userData.samples.forEach(s => {
            s.activeTests = s.activeTests?.filter(t => !this.testing.includes(t.LIMS_ID)) || []
            s.activeTests = s.activeTests?.filter(t => !this.testingHide.includes(t.LIMS_ID)) || []
        })

        this.testing.forEach(at => {
            const fullTargetTest = data.forms?.sections?.find(s => s.type === 'testing')?.testing_groups.map(tg => tg.tests)?.flat().find(pm => pm.LIMS_ID === at)
            if (fullTargetTest) {
                userData.samples[0].activeTests = uniqBy([ ...userData.samples[0].activeTests || [], fullTargetTest ], 'LIMS_ID')
            }
        })

        return userData
    }

    removeTesting(currentTesting) {
        return currentTesting.filter(ct => !this.testing.includes(ct.LIMS_ID))
    }

    disableFieldTest(testLimsId) {
        return this.testing.includes(testLimsId)
    }
}