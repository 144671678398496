import React from 'react';

// components
import COCRender from '../../COCRender/COCRender.js';
import LabUse from '../../dialogues/LabUse.js';
import RelinquishmentToolNavigator from '../RelinquishmentToolNavigator.js';
import RelinquishmentHeader from '../RelinquishmentHeader.js';

// mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// utils
import FormAPI from '../../../utils/API/api-form.js';

const GeneralSamplingEvent = ({ loading, userData, setUserData, setMode, formData, createSamplingDuplicates, error, setError }) => {

    const [labUseOpen, setLabUseOpen] = React.useState(false)

    const handleBackToMain = () => {
      setMode('admin')
      setError(false)
      window.history.pushState('page2', 'Title', '/admin/')
    }
  
    const samplingForm = formData?.forms?.sections?.filter(s => s.triggerInputs?.length > 0).find(s => s.samplingEventName === userData.submissionType.split(',')?.[1] || s.samplingEventKey === userData.submissionType.split(',')?.[1])

    return (
      <Box
        component="form"
        autoComplete="off"        
        style={{backgroundColor: 'rgb(100,200,100,0.10)', minHeight: '100vh'}}
      >
        <LabUse data={formData} userInput={userData} open={labUseOpen} setOpen={setLabUseOpen} setUserInput={setUserData} samplingMode={true} /> 

        {loading ? "Loading..." : 
        <>

        {!userData.submissionID ? "Almost done loading..." : null}

        {error === "noFIDData" ? 
          <>The Form ID provied could not be found. Please check URL.</>
        : null}

        {error && error !== "noFIDData" ? <h4 style={{color: 'red'}}>{error}</h4> : null}
        
        <Grid container spacing={2} style={{ padding: '30px'}}>

          {/* Render Tools Below */}        
          {userData.submissionID ? 
          <>  
              <Grid item xs={12} md={9}>
                
                <RelinquishmentHeader userData={userData} setMode={setMode} formData={formData} setError={setError} />

                <COCRender setMode={setMode} userInput={userData} setUserData={setUserData} data={formData} labUseOpen={labUseOpen} setLabUseOpen={setLabUseOpen} createSamplingDuplicates={createSamplingDuplicates} samplingMode={true} showBottles={true} />
              
              </Grid>


              <Grid item xs={12} md={3}>              

                <RelinquishmentToolNavigator data={formData} userData={userData} setUserData={setUserData} />                

              </Grid>
          </>
          : null}

        </Grid>
        </>
        }

        
      </Box>
    )

}

export default GeneralSamplingEvent;
