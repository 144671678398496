import React from 'react';

// mui
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import RefreshIcon from "@mui/icons-material/Refresh";
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';

const QueryControls = ({ getData, archiveData, setArchiveData, loading, filters, setFilters }) => {

    const handleSaveFilters = () => {
      localStorage.setItem('admin-filters', JSON.stringify(filters))
    }

    return (
      <ButtonGroup variant="contained" orientation="horizontal" style={{width: '100%'}}>  
        <Button style={{width: '51%', fontSize: '0.6rem'}} variant={archiveData ? 'outlined' : 'contained'} startIcon={<CloudDownloadIcon />} disabled={loading} onClick={() => setArchiveData(false)}>Active</Button>
        <Button style={{width: '51%', fontSize: '0.6rem'}} variant={archiveData ? 'contained' : 'outlined'} startIcon={<RestoreFromTrashIcon />} disabled={loading} onClick={() => setArchiveData(true)}>Archived</Button>
        
        <Tooltip title="Clear All Filters." arrow>
        <Button onClick={() => setFilters({})} disabled={loading}><ClearIcon /></Button>
        </Tooltip>
        <Tooltip title="Save these filters for next time, the page will load with these selections." arrow>
          <Button onClick={handleSaveFilters} disabled={loading} color='success' ><SaveIcon /></Button>
        </Tooltip>
        <Tooltip title="Refresh Page Data." arrow>
          <Button onClick={getData} disabled={loading} color='success'><RefreshIcon /></Button>
        </Tooltip>
      </ButtonGroup> 
    )
}

export default QueryControls;
