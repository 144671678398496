import * as React from 'react';

// mui
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';

// utils
import selectInputsData from '../../utils/functions/selectInputsData';

const ModeTriggerInput = ({ data, currentForm, updateForm }) => {

    // this is the interactable to set the inputs that will trigger the mode. Client and Sample level.
    const [newMode, setNewMode] = React.useState(false)
    const [selected, setSelected] = React.useState(false)
    const [subSelected, setSubSelected] = React.useState(false)

    const handleSave = (test) => {
        // if (currentForm.triggerInputs?.find(t => t === test.LIMS_ID)) {
        //     updateForm('triggerInputs', [...currentForm.triggerInputs.filter(t => t !== test.LIMS_ID)])
        // } else {
        //     updateForm('triggerInputs', [...currentForm.triggerInputs || [], test.LIMS_ID])
        // }
    }
    
    const selectOptions = selectInputsData.getFullSelectOptions(data)

    const requiredTarget = selectInputsData.findObject(data, selected)

    const confirmSelection = () => {
      const foundItem = selectOptions.find(so => so.key === selected)

      const newItem = {
        key: foundItem.key,
        type: foundItem.type, // if its client or if its sample/etc                
        value: subSelected, // value can be an array, if its an array its an OR
      }

      updateForm('triggerInputs', [...currentForm.triggerInputs || [], newItem])
      setSelected(false)
      setSubSelected(false)
      setNewMode(false)
    }

    const handleRemove = (key) => {
      updateForm('triggerInputs', currentForm.triggerInputs.filter(cti => cti.key !== key))
    }

  return (
    <div style={{ marginTop: '15px', margin: '15px', backgroundColor: '#EEEEEE', padding: '10px', borderRadius: '5px', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' }}>
        <Typography>Triggers - Use this tool to determine what the client will input to trigger this sampling event, they must match all of the selections below.</Typography>
        
        <Typography>Current Triggers</Typography>


        <ul>
        {currentForm.triggerInputs?.map((cti, i) => {
          return <li key={i + 'triggerOption'}>{cti.key} - {cti.value} <button onClick={() => handleRemove(cti.key)}>x</button></li>
        })}
        </ul>
        
        <Divider variant="middle" style={{ margin: '15px' }} />    
        
        {newMode ? 
        
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Available User Inputs</InputLabel>
          <Select
              labelId="demo-simple-select-label"
              style={{width: "100%"}}
              id="demo-simple-select"
              value={selected || false}
              label="Available Keys"
              onChange={(e) => {
                setSelected(e.target.value);
                setSubSelected(false)
              }}
          >   
              {selectOptions.map((option, i) => {
                  return <MenuItem key={option.key + i + 'selectOpt'} value={option.key}>{option.text}</MenuItem>
              })}
          </Select>
          <FormHelperText id="component-helper-text">
              Choose which form entry.
          </FormHelperText>
      </FormControl>
      : null}

      {selected && newMode ? 
          <FormControl fullWidth>                    
          <InputLabel id="demo-simple-select-label">Available Options</InputLabel>
              <Select
                  labelId="demo-simple-select-label"
                  style={{width: "100%"}}
                  id="demo-simple-select"
                  defaultValue={""}
                  value={subSelected || ""}
                  label="Available Options"
                  onChange={(e) => setSubSelected(e.target.value)}
              >   
                  {requiredTarget?.selection?.value?.map((row, i) => {
                      const displayItem = requiredTarget.selection.source === "specified" ? row : data.references[requiredTarget.selection.source]?.find(item => item.LIMS_ID === row)?.DISPLAYNAME
                      return <MenuItem key={displayItem + i + 'selectOpt'} value={row}>{displayItem}</MenuItem>
                  })}
              </Select>
              <FormHelperText id="component-helper-text">
                  Choose which option on the form entry.
              </FormHelperText>
              <Alert severity="error">BE AWARE: if you change the text or options of the item selected above it will not automatically update the choices below. If you change the text of these options then you must come back here and re-select the desired choices.</Alert>
          </FormControl>
      : null}

      {!newMode ? <Button onClick={() => setNewMode(true)}>New Item</Button> : null}
      {newMode ? <Button disabled={!subSelected} onClick={confirmSelection}>Save</Button> : null}
      {newMode ? <Button onClick={() => {
        setNewMode(false)
        setSelected(false)
        setSubSelected(false)
      }}>Cancel</Button> : null}

    </div>
  )
}

export default ModeTriggerInput;
