import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,  
  Route,
  Routes
} from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// components
import Canvas from './components/Canvas';
import NoPage from './components/NoPage'
import Admin from './components/Admin';
import Developer from './components/Developer';
import FormEditor from './components/FormEditor';
import DocumentViewer from './components/DocumentViewer';

const theme = createTheme({
  typography: {
    fontFamily: '"Barlow", sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Barlow';
          font-style: normal;
          font-display: swap;
          font-weight: 200;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});


function App() {
  return (
    <ThemeProvider theme={theme} >
    <LocalizationProvider dateAdapter={AdapterDayjs}>
     
      <div className="App" >
      <Router>
      <Routes>
          <Route exact path="/cannabis/*" element={<Canvas/>}/>
          <Route exact path="/cannabis-sampling/*" element={<Canvas/>}/>
          <Route exact path="/kratom/*" element={<Canvas/>}/>
          <Route exact path="/agriculture/*" element={<Canvas/>}/>
          <Route exact path="/food/*" element={<Canvas/>}/>
          <Route exact path="/environmental/*" element={<Canvas/>}/>
          <Route exact path="/pws-environmental/*" element={<Canvas/>}/>
          <Route exact path="/emp/*" element={<Canvas/>}/>
          <Route exact path="/:admin/*" element={<Admin/>}/>
          <Route exact path="/developer/*" element={<Developer/>}/>
          <Route exact path="/formeditor/" element={<FormEditor/>}/>
          <Route exact path="/document-viewer/:id" element={<DocumentViewer/>}/>
          <Route path="*" element={<NoPage/>}/>
        </Routes>
      
     
    </Router>


    </div>
      

    </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
