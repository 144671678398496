import React, { useEffect } from 'react';

// components
import TestingForm from './TestingForm';
import TestingSelected from './TestingSelected';
import BottlesForm from './BottlesForm';

// mui
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";

// utils
import "./scrollBar.css"

const TestingBody = ({ userInput, setUserInput, refSample, data, renderData, specialModes, checkRequirements, formMode, searchParam = '' }) => {

    const [mode, setMode] = React.useState(renderData.testing_groups.filter(g => g.group !== 'clientTesting')[0].group);
    
    const handleClick = (val) => {
      setMode(val)
    }
    
    const renderClientData = renderData.testing_groups.find(g => g.group === 'clientTesting') && [...data.references.parameterScopeClients, ...data.references.spectraClients].filter(psc => psc.CLIENT_PASSKEY === userInput.passKey)

    useEffect(() => {
      if (renderClientData?.length) {
        setMode('clientTesting')
      } else {
        // set the mode back to default
        setMode(renderData.testing_groups.filter(g => g.group !== 'clientTesting')[0].group)
      }
    }, [renderClientData?.length])
    
    
    const disregardTests = () => {
      if (data.activeSamplingEvent) {
        return data.activeSamplingEvent.testingHide || []
      } else if (formMode !== 'samples') {
        // materials ordering
        const materialOrderForm = data.forms.sections.find(s => s.type === "materialOrder")
        return materialOrderForm?.disregardTests || []
      } else {
        return []
      }
    }

    const testingGroups = renderData?.testing_groups?.filter(g => g.group !== 'clientTesting')?.filter(g => {
      let tests = g.tests.filter(t => !disregardTests().includes(t.LIMS_ID))
      let analyteListRender = g.individualAnalytes?.length > 0
      let analyteListCheck = analyteListRender

      if (searchParam?.length) {

        // deal with tests here
        tests = tests.filter(t => {
          const fullTest = data.testing[t.type + 's'].find(dt => dt.LIMS_ID === t.LIMS_ID)
          const check = fullTest.DETAILS?.toLowerCase().includes(searchParam?.toLowerCase()) || fullTest.DISPLAYNAME?.toLowerCase().includes(searchParam?.toLowerCase()) || fullTest.PRODUCTCODE?.toLowerCase().includes(searchParam?.toLowerCase()) || fullTest.METHODN?.toLowerCase().includes(searchParam?.toLowerCase()) || fullTest.METHODREPORT?.toLowerCase().includes(searchParam?.toLowerCase())
          return check
        })

        // deal with individual analytes here
        if (analyteListRender) {
          let analyteList = g.individualAnalytes.filter(a => {
            const fullTest = data.testing['parameterMethods'].find(dt => dt.LIMS_ID === a)
            const check = fullTest?.DISPLAYNAME?.toLowerCase().includes(searchParam.toLowerCase()) 
            
            return check
          })
          analyteListCheck = analyteList.length > 0
        }

      }
      return tests?.length > 0 || analyteListCheck
    })

    useEffect(() => {
      const modePresent = testingGroups?.find(g => g.group === mode)
      if (mode !== 'clientTesting' && !modePresent && testingGroups?.length > 0) {
        setMode(testingGroups[0].group)
      }
    }, [testingGroups])

    useEffect(() => {

      if (!testingGroups.find(tg => tg.group === mode)) {
        setMode(testingGroups?.[0]?.group)
      }
    }, [data.activeSamplingEvent])
    
    return (
    <div role="presentation" style={{ width: "100%"}}>
      
      <Paper elevation={3} sx={{m: '15px', textAlign: 'left', minHeight: "300px"}}>

      <div style={{overflow: "auto", backgroundColor: "rgba(245, 245, 245, 0.95)"}}>
          
        <ButtonGroup variant="text" aria-label="text button group" >
            
            {renderClientData?.length ? 
              <Button
                key={"chip-clientTesting"}
                style={{borderRadius: 0, padding: "5px", fontColor: "black", minWidth: "100px", fontSize: "0.7em"}}
                color="success"
                variant={mode === 'clientTesting' ? "contained" : "text"}
                onClick={() => handleClick('clientTesting')}
              >
                {userInput.passKey}
              </Button>            
            : null}

            {testingGroups.map((g, i) => {
              return (
                <Button
                  key={"chip-"+i}
                  style={{borderRadius: 0, padding: "5px", fontColor: "black", minWidth: "100px", fontSize: "0.7em"}}
                  color="success"
                  variant={mode === g.group ? "contained" : "text"}
                  onClick={() => handleClick(g.group)}
                >
                  {g.group}
                </Button>
              )
            })}


        </ButtonGroup>

        {!testingGroups.length > 0 ? 
          <div className="formHeaderContainer" style={{ fontSize: '1.5em'}}>
            <ErrorIcon className="formHeaderIcon" color="error" />
            <Typography color="red" component="body" variant="body">
              Search did not find any matches
            </Typography>
          </div>
       : null}
      </div>
      
      <TestingForm 
        userInput={userInput} 
        setUserInput={setUserInput} 
        refSample={refSample} 
        data={data}      
        group={mode}              
        renderData={renderData}
        specialModes={specialModes}
        renderClientData={renderClientData}
        formMode={formMode}
        searchParam={searchParam}
      />
        
      </Paper>

      {(refSample || userInput?.materialOrders[0])?.activeTests?.length > 0 ?           
        <TestingSelected refItem={refSample || userInput?.materialOrders[0]} data={data} userInput={userInput} setUserInput={setUserInput} formMode={formMode}/>
      : null}

      {refSample?.activeTests?.length > 0 && data.forms?.bottlesMenu && !data.activeSamplingEvent ? 
        <BottlesForm checkRequirements={checkRequirements} refSample={refSample || userInput?.materialOrders[0]} data={data} userInput={userInput} setUserInput={setUserInput} />
      : null}

    </div>
    );
}

export default TestingBody;
