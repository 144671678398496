import * as React from 'react';
import dayjs from 'dayjs';

// mui
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// utils
import checkComplexRequired from '../../../utils/functions/checkComplexRequired';

const UserDateTime = ({ value, refItem, handleInput, checkRequirements, sampleIndex, disabled }) => {

    let useInput = refItem && refItem[value.input_key] ? refItem[value.input_key] : ''

    const requiredCheck = checkComplexRequired(value.required, useInput, sampleIndex)

    const renderError = checkRequirements && requiredCheck && useInput === ''

    const middleInput = (key, val) => {
      if (!val) {
        handleInput(false, key, false, 'delete')
      } else {
        const convertedToNormal = val.toDate()
        handleInput(false, key, convertedToNormal)
      }
    }

    return (
    <Grid item xs={value.xs} md={value.md} id={value.input_key+"DateTimeTextField"}   >
      
      <TimePicker
        ampm={true}
        disabled={disabled}
        minutesStep={1}
        timeSteps={{hours: 1, minutes: 1}}
        label={<span>{value.label}<span className='required-asterisk'>{requiredCheck ? " *" : ""}</span></span>}
        onChange={(e) => middleInput(value?.input_key, e)}
        sx={{ width: '100%', padding: '0px' }}
        value={useInput ? dayjs(useInput) : null}
        slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true } } }}
      />
      {renderError}
      <FormHelperText>will be reflected in military time on chain of custody</FormHelperText>
      {renderError ? <FormHelperText style={{color: "red"}}>Required Entry</FormHelperText> : null}

      </Grid>
  );
}

export default UserDateTime
