import React from "react";

// components
import COCRender from "../../COCRender/COCRender.js";
import TemplateSubmission from "../SubmissionButtons/TemplateSubmission.js";
import FormSignatures from "../FormSignatures.js";

// mui
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Tooltip from '@mui/material/Tooltip';

// styles
import "../../../utils/styles/styleSheet.css";

const PreliminaryOrSaved = ({ userInput, data, labUseOpen, setLabUseOpen, setUserInput, setMode, setGlobalLoading, setLoading, relinquishMode, setRelinquishMode, setError, samplingMode }) => {

  return (
    <>
    <div style={{ marginBottom: "20px", margin: "auto" }}>
      <img src="/images/cfl-logo-hr.png" alt="cfl-logo" style={{width: "33%", marginTop: "15px"}} />
      <Typography
        component="p"
        variant="p"
        style={{ margin: "auto", fontSize: "1.5em", width: "100%", color: 'rgba(6, 88, 64, 1)', fontWeight: 'bold' }}
      >
        Review Submission, Initiate Relinquishment
      </Typography>

      <Typography
        component="p"
        variant="p"
        style={{ margin: "auto", fontSize: "1.25em", width: "80%" }}
      >
        This is a preliminary chain of custody. Please review your submissions below.
      </Typography>
      
      {userInput.submissionID ? 
        <Typography
          component="p"
          variant="p"
          style={{ color: 'rgba(6, 88, 64, 1)', fontWeight: 'bold', margin: "auto", marginTop: '10px', fontSize: "1.25em", width: "80%" }}
        >
          This submission has been saved but not submitted. Return Link: <Tooltip title="Use this link to return to the page!">
            <a
              style={{ wordBreak: "break-all", color: 'rgba(6, 88, 64, 1)' }}
              href={`${window.location.protocol}//${window.location.host}/${userInput.serviceLine}/${encodeURIComponent(userInput.submissionID)}`}
            >
              {decodeURIComponent(userInput.submissionID)}
            </a>
          </Tooltip>
        </Typography>
      : null}

      <br />

    </div>
    
    <br />
    {userInput.submissionID ? 
    <>
      <Divider color="black" style={{ width: "50%", margin: 'auto', marginBottom: '10px', height: '1px'}} />
      <Typography
        component="p"
        variant="p"
        style={{ margin: "auto", fontSize: "1.25em", width: "100%", marginBottom: '15px' }}
      >
        <TemplateSubmission userInput={userInput} setUserInput={setUserInput} setMode={setMode} data={data} setGlobalLoading={setGlobalLoading} />
      </Typography>
    </>
    : null}

    <FormSignatures data={data} userInput={userInput} setUserData={setUserInput} setMode={setMode}  style={{width: '100%', marginBottom : '10px'}} setGlobalLoading={setGlobalLoading} samplingMode={samplingMode} />

    <COCRender
      userInput={userInput}
      setMode={setMode}
      setUserData={setUserInput}
      data={data}
      labUseOpen={labUseOpen}
      setLabUseOpen={setLabUseOpen}      
      samplingMode={samplingMode}
    />

    {userInput.submissionID ? 
    <Grid item xs={12} md={12}>
      <Alert severity="warning" style={{ fontSize: "1.00em" }}>
        If you did not receive an email within 15 minutes of your submission
        or a phone call regarding this order please contact us immediately at{" "}
        <span style={{ fontWeight: "bold" }}>(503) 254-1794</span> or email us
        at{" "}
        <a href="mailto:info@columbialaboratories.com">
          info@columbialaboratories.com
        </a>
        .
      </Alert>
    </Grid>
    : null}
    
  </>
  );
};

export default PreliminaryOrSaved;
